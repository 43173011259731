import { useState } from "react";
import { ChevronDown, DraftingCompass, User, Wallet } from "lucide-react";
import { cn } from "@/lib/utils";
import { useToast } from "@/components/ui/use-toast";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { CopyButton } from "@/components/ui/copy-button";
import { EBidStatus, TBid, TUpdateBid } from "src/shared/types/bids";
import { colorByCurrencyType } from "src/shared/types/admin";
import { AUTO_CITY_CODE } from "src/shared/types/websites-settings";
import { defineBidStatus } from "src/shared/utils/bid-status";
import { formatHash } from "src/shared/utils/charfix";
import { formatDate } from "src/shared/utils/date";
import { useUpdateBid } from "src/shared/api/query/use-bids/use-bids";
import { useBidsStore } from "src/shared/store/bids-store";
import { Button } from "@/components/ui/button";
import { ModalButton } from "@/components/ui/modal-button";
import { DialogClose } from "@/components/ui/dialog";

export const BidCard = ({ item, website_slug }: { item: TBid; website_slug: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { toast } = useToast();
  const { bids, setBids } = useBidsStore();
  const lang_attr = "RU";
  const city_name =
    item.city?.city_code !== AUTO_CITY_CODE
      ? item.city.localizations.find((el) => el.locale_code === lang_attr)?.name
      : null;

  const color_from = colorByCurrencyType[item.currency_from.currency_type];
  const color_to = colorByCurrencyType[item.currency_to.currency_type];

  const created = formatDate(new Date(item.created_at));
  const updated = formatDate(new Date(item.updated_at));

  const status = defineBidStatus(item.bid_status);

  const CurFrom = () => (
    <small className="font-bold" style={{ color: color_from }}>
      {item.currency_from.currency_code}
    </small>
  );

  const CurTo = () => (
    <small className="font-bold" style={{ color: color_to }}>
      {item.currency_to.currency_code}
    </small>
  );

  const { mutateAsync: updateBid, isPending: isPending } = useUpdateBid(website_slug);

  const changeStatus = async (status: EBidStatus) => {
    if (!bids || item.bid_status === EBidStatus.DONE || item.bid_status === EBidStatus.DELETED) {
      return;
    }
    const payload: TUpdateBid = {
      bid_id: item.bid_id,
      bid_uuid: item.bid_uuid,
      bid_status: status,
      website_slug,
    };
    const data = await updateBid(payload);
    if (data) {
      toast({
        title: "Обновление заявки",
        description: "Заявка успешно обновлена",
      });
      setBids(
        bids.map((bid) =>
          bid.bid_uuid === item.bid_uuid
            ? {
                ...bid,
                ...data,
              }
            : bid
        )
      );
    }
  };

  return (
    <Card
      className={cn(
        `flex flex-col gap-2 p-2 pl-4 durarion-300 hover:opacity-90 hover:scale-[101%]`
      )}>
      <div className={cn(`flex flex-col gap-3 min-h-10`)}>
        <div>
          <div className="flex flex-col">
            <div className="flex w-full gap-3 items-start pb-1">
              <div className="flex w-full gap-3 items-center">
                <a
                  href={item.bid_link}
                  target="_blank"
                  className={cn(
                    "rounded-md px-2 text-lg whitespace-pre",
                    "bg-foreground text-background"
                  )}>
                  # {item.bid_id}
                </a>
                <CopyButton
                  label={formatHash(item.bid_uuid)}
                  value={item.bid_uuid}
                  variant="outline"
                />
              </div>
              <div className="text-[10px] flex flex-col items-end gap-1 w-full">
                {item.bid_status !== EBidStatus.DONE && item.bid_status !== EBidStatus.DELETED && (
                  <ModalButton
                    open={isModalOpen}
                    setOpen={setIsModalOpen}
                    triggerButton={
                      <Badge className="cursor-pointer bg-sky-500 hover:bg-sky-400 rounded-md text-white pr-1 flex items-center gap-2">
                        {status.title}
                        <ChevronDown className="w-5 h-5" />
                      </Badge>
                    }>
                    <p>
                      Вы уверены, что хотите сменить статус заявки <b># {item.bid_id}</b>?
                    </p>
                    <div className="flex gap-2 justify-end">
                      <DialogClose asChild>
                        <Button
                          type="button"
                          className="bg-green-500"
                          disabled={isPending}
                          onClick={async () => {
                            await changeStatus(EBidStatus.DONE);
                            setIsModalOpen(false);
                          }}>
                          Исполнена
                        </Button>
                      </DialogClose>
                      <DialogClose asChild>
                        <Button
                          type="button"
                          variant="destructive"
                          disabled={isPending}
                          onClick={async () => {
                            await changeStatus(EBidStatus.DELETED);
                            setIsModalOpen(false);
                          }}>
                          Удалена
                        </Button>
                      </DialogClose>
                    </div>
                  </ModalButton>
                )}
                {!!item.updated_at && created !== updated && (
                  <p className="pr-1">Обновлена: {updated}</p>
                )}
                <p className="pr-1">Создана: {created}</p>
              </div>
            </div>
            <div className="text-sm">
              {!!city_name && <p>{city_name}</p>}
              <div className="text-lg grid grid-cols-[80px_1fr] gap-2 items-baseline">
                <span className="text-sm">Сумма:</span>
                <div>
                  {item.amount_from} <CurFrom /> - {item.amount_to} <CurTo />
                </div>
              </div>
              {!!item.fact_amount_from && (
                <div className="text-lg grid grid-cols-[80px_1fr] gap-2 items-baseline">
                  <span className="text-sm">Факт Сумма:</span>
                  <div>
                    {item.fact_amount_from} <CurFrom /> - {item.fact_amount_to} <CurTo />
                  </div>
                </div>
              )}
              {!!item.commission && (
                <div className="grid grid-cols-[80px_1fr] gap-2 items-baseline">
                  Комиссия: <div>{item.commission}</div>
                </div>
              )}
              <div className="grid grid-cols-[80px_1fr] gap-2 items-baseline">
                Курс:
                <div className="text-sm">
                  {item.amount_from} <CurFrom /> - {item.amount_to} <CurTo />
                </div>
              </div>
            </div>
          </div>
        </div>
        {!!(item.rid || item.roistat_visit || item.utm_source || item.utm_medium) && (
          <div className="relative p-2 border border-sky-500/30 rounded-md bg-sky-500/10">
            <DraftingCompass className="absolute right-2 text-muted-foreground h-5 w-5" />
            <Row label="ID Реферала" value={item.rid} />
            <Row label="roistat_visit" value={item.roistat_visit} />
            <Row label="utm_source" value={item.utm_source} />
            <Row label="utm_medium" value={item.utm_medium} />
          </div>
        )}
        <div className="relative p-2 border border-green-500/30 rounded-md bg-green-500/10">
          <User className="absolute right-2 text-muted-foreground h-5 w-5" />
          <RowCopy label="Uuid клиента" value={item.user_uuid} />
          <RowCopy label="fingerprint" value={item.fingerprint} />
          <Row label="IP клиента" value={item.user_ip} />
          <Row label="Имя клиента" value={item.username} />
          <Row label="Telegram" value={item.telegram} />
          <Row label="Email" value={item.email} />
          <Row label="Телефон" value={item.phone_number} />
        </div>
        {!!(item.address_site || item.address_user || item.transaction_hash) && (
          <div className="relative p-2 border rounded-md bg-secondary">
            <Wallet className="absolute right-2 text-muted-foreground h-5 w-5" />
            <RowCopy label="Наш кошелек" value={item.address_site} />
            <RowCopy label="Кошелек клиента" value={item.address_user} />
            <RowCopy label="Хэш транзакции" value={item.transaction_hash} />
          </div>
        )}
      </div>
    </Card>
  );
};

const Row = ({ label, value }: { label: string; value: string | number | null }) => {
  if (!value) {
    return null;
  }
  return (
    <p className="grid grid-cols-[110px_1fr] items-baseline gap-3 text-sm lg:text-base">
      <span className="font-extralight text-sm">{label}:</span>
      {value}
    </p>
  );
};

const RowCopy = ({ label, value }: { label: string; value: string }) => {
  if (!value) {
    return null;
  }
  return (
    <p className="grid grid-cols-[110px_1fr] items-baseline gap-3 text-sm lg:text-base">
      <span className="font-extralight text-sm">{label}:</span>
      <CopyButton
        className="w-fit p-0 h-fit"
        variant="link"
        label={formatHash(value)}
        value={value}
      />
    </p>
  );
};
