import { v4 } from "uuid";
import { TCurrency } from "src/shared/types/admin";
import { TCity, TSelectedPair } from "src/shared/types/websites-settings";
import { baseUnavailableDirections } from "src/shared/utils/unavailable-directions";

export interface CreateAllCombinationsProps {
  cities: TCity[];
  currencies_from: TCurrency[];
  currencies_to: TCurrency[];
}

type CreateVectorsPayload = {
  city_names: string[];
  currency_from_codes: string[];
  currency_to_codes: string[];
};

export const convertToPayload = (vectors: Partial<TSelectedPair>[]): CreateVectorsPayload => {
  const city_names = new Set<string>();
  const currency_from_codes = new Set<string>();
  const currency_to_codes = new Set<string>();

  for (const vector of vectors) {
    vector.city && city_names.add(vector.city.city_code);
    vector.from && currency_from_codes.add(vector.from.currency_code);
    vector.to && currency_to_codes.add(vector.to.currency_code);
  }

  return {
    city_names: Array.from(city_names),
    currency_from_codes: Array.from(currency_from_codes),
    currency_to_codes: Array.from(currency_to_codes),
  };
};

export const createAllCombinations = ({
  cities,
  currencies_from,
  currencies_to,
}: CreateAllCombinationsProps): {
  combinations: Partial<TSelectedPair>[];
  noValidVectors: boolean;
} => {
  if (cities.length > 0 && currencies_to.length === 0 && currencies_from.length === 0) {
    return {
      combinations: cities.map((city) => ({
        city: { ...city, currency_pairs: [] },
        id: v4(),
      })),
      noValidVectors: false,
    };
  }

  const maxLength = Math.max(cities.length, currencies_from.length, currencies_to.length);

  const createKey = (...args: string[]) => `${args[0]}_${args[1]}_${args[2]}_${args[3]}`;

  const combinations: TSelectedPair[] = [];
  const allCombinations: TSelectedPair[] = [];
  let noValidVectors = true;
  const included = new Set();

  for (let a = 0; a < (cities?.length || maxLength); a++) {
    for (let c = 0; c < (currencies_from?.length || maxLength); c++) {
      for (let d = 0; d < (currencies_to?.length || maxLength); d++) {
        const city = cities[a];
        const currency_from = currencies_from[c];
        const currency_to = currencies_to[d];

        const key = createKey(
          city?.city_code || "",
          currency_from?.currency_code || "",
          currency_to?.currency_code || ""
        );

        const combination = {
          id: v4(),
          city: cities[a] as unknown as TCity,
          from: currencies_from[c],
          to: currencies_to[d],
        };

        allCombinations.push(combination);

        const isUnavailable = baseUnavailableDirections?.find(
          (dir) =>
            (dir.from_type === currency_from?.currency_type &&
              dir.to_type === currency_to?.currency_type) ||
            currency_from?.currency_code === currency_to?.currency_code
        );

        if (
          (!city && cities.length > 0) ||
          (!currency_from && currencies_from.length > 0) ||
          (!currency_to && currencies_to.length > 0) ||
          included.has(key) ||
          !!isUnavailable
        ) {
          continue;
        }

        noValidVectors = false;
        combinations.push(combination);
        included.add(key);
      }
    }
  }

  return {
    combinations,
    noValidVectors,
  };
};
