import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { CloseButton } from "@/components/ui/close-button";
import { Card, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { TextareaWithLabel } from "@/components/ui/textarea-with-label";
import { string_schema } from "src/shared/validation/field-schemas";
import { TReferralTask } from "src/shared/types/referral";
import { useReferralStore } from "src/shared/store/referral-store";
import {
  useCreateReferralTask,
  useUpdateReferralTask,
} from "src/shared/api/query/use-referral/use-task-manager";

const FormSchema = z.object({
  task_ref_id: string_schema,
  task_comment: string_schema,
  task_min_bid_amount: string_schema,
  task_max_bid_amount: string_schema,
  task_min_amount_day: string_schema,
  task_max_amount_day: string_schema,
});

export const TaskForm = ({
  website_slug,
  selectedTask,
  setSelectedTask,
}: {
  website_slug: string | undefined | null;
  selectedTask: TReferralTask | null;
  setSelectedTask: Dispatch<SetStateAction<TReferralTask | null>>;
}) => {
  const { toast } = useToast();
  const defaultValues = {
    task_ref_id: "0",
    task_comment: "",
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const { tasks, setTasks } = useReferralStore();
  const { mutateAsync: createTask, isPending: isCreatePending } = useCreateReferralTask();
  const { mutateAsync: updateTask, isPending: isUpdatePending } = useUpdateReferralTask(
    selectedTask?.task_id || ""
  );

  const disabled =
    !website_slug?.length ||
    !Object.values(form.formState.dirtyFields)?.length ||
    !!Object.values(form.formState.errors)?.length ||
    isCreatePending ||
    isUpdatePending;

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const payload = {
      task_ref_id: +e.task_ref_id,
      task_comment: e.task_comment,
      task_min_bid_amount: "task_min_bid_amount" in e ? +e.task_min_bid_amount : 0,
      task_max_bid_amount: "task_max_bid_amount" in e ? +e.task_max_bid_amount : 0,
      task_min_amount_day: "task_min_amount_day" in e ? +e.task_min_amount_day : 0,
      task_max_amount_day: "task_max_amount_day" in e ? +e.task_max_amount_day : 0,
      task_website_slug: website_slug || "",
    };
    if (!!selectedTask) {
      const data = await updateTask({
        ...payload,
        task_id: selectedTask.task_id,
      });
      if (data) {
        toast({
          title: "TASK менеджер",
          description: "Задача успешно обновлена",
        });
        !!tasks && setTasks(tasks.map((el) => (el.task_id === selectedTask.task_id ? data : el)));
      }
    } else {
      const data = await createTask(payload);
      if (data) {
        toast({
          title: "TASK менеджер",
          description: "Задача успешно создана",
        });
        if (!!tasks) {
          setTasks([...tasks, data]);
        } else {
          setTasks([data]);
        }
        form.reset(defaultValues);
      }
    }
  };

  useEffect(() => {
    if (selectedTask) {
      form.reset({
        task_ref_id: selectedTask.task_ref_id.toString(),
        task_comment: selectedTask.task_comment,
        task_min_bid_amount: selectedTask.task_min_bid_amount.toString(),
        task_max_bid_amount: selectedTask.task_max_bid_amount.toString(),
        task_min_amount_day: selectedTask.task_min_amount_day.toString(),
        task_max_amount_day: selectedTask.task_max_amount_day.toString(),
      });
    } else {
      form.reset(defaultValues);
    }
  }, [selectedTask]);

  return (
    <Card className="min-w-72 min-h-64 p-4 h-fit">
      {!!website_slug ? (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
            <CardTitle className="text-lg flex items-center justify-between h-10">
              {!!selectedTask ? selectedTask.task_ref_id : "Новая задача"}
              {!!selectedTask && <CloseButton onClick={() => setSelectedTask(null)} />}
            </CardTitle>
            <div className="w-full flex flex-col gap-2">
              <InputWithLabel
                label="REF ID"
                name="task_ref_id"
                type="number"
                disabled={!!selectedTask}
              />
              <TextareaWithLabel
                label="Комментарий"
                name="task_comment"
                placeholder="Описание реферала"
              />
              <p className="mt-1 -mb-1 text-neutral-500">Сумма 1 заявки</p>
              <div className="grid grid-cols-2 gap-2">
                <InputWithLabel label="min" name="task_min_bid_amount" type="number" />
                <InputWithLabel label="max" name="task_max_bid_amount" type="number" />
              </div>
              <p className="mt-1 -mb-1 text-neutral-500">Cумма накрутки за день</p>
              <div className="grid grid-cols-2 gap-2">
                <InputWithLabel label="min" name="task_min_amount_day" type="number" />
                <InputWithLabel label="max" name="task_max_amount_day" type="number" />
              </div>
            </div>
            <Button type="submit" disabled={disabled}>
              {!!selectedTask ? "Обновить" : "Добавить задачу"}
            </Button>
          </form>
        </Form>
      ) : (
        <div className="w-full h-full min-h-64 flex items-center justify-center text-neutral-500">
          Выберите сайт
        </div>
      )}
    </Card>
  );
};
