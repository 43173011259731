import dayjs from "dayjs";
import { Card } from "@/components/ui/card";
import { TReferralTaskHistoryItem } from "src/shared/types/referral";

export const TaskHistoryCard = ({ item }: { item: TReferralTaskHistoryItem }) => {
  return (
    <Card className="p-4">
      <div className="grid grid-cols-[150px_1fr] gap-x-2 items-baseline pb-2">
        <div>{item?.task_website_name}</div>
        <div className="flex items-baseline gap-2">
          <small className="leading-3 text-neutral-500">Сумма накрутки</small>
          <div>{item?.task_amount_day}</div>
        </div>
        <p className="flex items-baseline gap-2">
          <span className="pixel">REF ID:</span>
          <i className="text-lg">{item?.task_ref_id}</i>
        </p>
        <div className="flex items-baseline gap-2">
          <small className="leading-3 text-neutral-500">Количество заявок</small>
          <div>{item?.task_bid_count}</div>
        </div>
      </div>
      <div className="flex gap-2 text-xs text-neutral-500 items-end justify-end opacity-70">
        <p>Завершено:</p>
        <p>{dayjs(item?.task_finished_at)?.format("DD.MM.YYYY HH:mm")}</p>
      </div>
    </Card>
  );
};
