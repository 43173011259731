import { TCreateCurrency, TCurrency } from "src/shared/types/admin";
import adminService from "../../services/admin-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";

export const useCurrencies = () =>
  useBaseQuery<null, TCurrency[]>(["get-currencies"], () => adminService.getCurrencies());

export const useCreateCurrency = () =>
  useBaseMutation<TCreateCurrency, TCurrency>(
    ["create-currency"],
    (payload: TCreateCurrency) => adminService.addCurrency(payload),
    {
      403: "Недостаточно прав",
      404: "Валюта не найдена",
      409: "Валюта с таким названием или ID уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useUpdateCurrency = () =>
  useBaseMutation<TCurrency[], TCurrency[]>(
    ["update-currency"],
    (payload: TCurrency[]) => adminService.updateCurrency(payload),
    {
      403: "Недостаточно прав",
      404: "Валюта не найдена",
      409: "Валюта с таким названием или ID уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );
