import { TCurrency } from "./admin";
import { TCity } from "./websites-settings";

export enum EBidStatus {
  CREATED = "created",
  COMPLETED = "completed",
  CANCELED = "canceled",
  DELETED = "deleted",
  DONE = "done",
  CREATED_CARD = "Создана",
  HOLDING_TILL_PAYMENT = "Заявка в ожидании оплаты",
  INCOMING_PAYMENT_SUCCESS = "Получили деньги",
  ERROR = "Ошибка в заявке",
  DONE_AUTO = "Успешно выплачена",
  AUTO_UNKNOWN_STATUS = "В работе",
}

export type TBidStatusDict = {
  bid_status: EBidStatus;
  title: string;
  color: string;
};

export type TBid = {
  bid_id: number;
  bid_uuid: string;
  user_uuid: string;
  direction_id: number;
  bid_link: string;
  city: TCity;
  currency_from: TCurrency;
  currency_to: TCurrency;
  course_from: number;
  course_to: number;
  amount_from: number;
  amount_to: number;
  address_site: string;
  address_user: string;
  commission: number;
  created_at: string;
  updated_at: string;
  bid_status: EBidStatus;
  fingerprint: string;
  phone_number: string;
  rid: number | null;
  user_ip: string;
  user_agent: string;
  email: string;
  telegram: string;
  type_id: number;
  internal_id: string;
  fact_amount_from: number;
  fact_amount_to: number;
  transaction_hash: string;
  language_id: number;
  username: string;
  utm_medium: string;
  utm_source: string;
  roistat_visit: number | null;
};

export type TUpdateBid = {
  bid_id: number;
  bid_uuid: string;
  bid_status: EBidStatus;
  website_slug: string;
};

export type TBidsPagination = {
  total: number;
  page: number;
  data: TBid[];
};

export type TBidFilterFields = {
  bid_search_field?: boolean;
  bid_id?: boolean;
  bid_status?: boolean;
  user_username?: boolean;
  user_email?: boolean;
  currency_from_code?: boolean;
  currency_to_code?: boolean;
  bid_created_at_start?: boolean;
  bid_created_at_end?: boolean;
  bid_rid?: boolean;
  city_code?: boolean;
  utm_medium?: boolean;
  utm_source?: boolean;
  roistat_visit?: boolean;
  user_telegram?: boolean;
  address_site?: boolean;
  address_user?: boolean;
  ip_address?: boolean;
};
