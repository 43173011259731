import { useEffect, useState } from "react";
import { TCurrency } from "src/shared/types/admin";
import { CurrencyList } from "src/entities/currency-list";
import { useSearchParams } from "react-router-dom";
import { Minus, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { CurrenciesAdder } from "src/entities/adder/currencies-adder";
import { useWebsiteStore } from "src/shared/store/website-store";
import { useWebsiteCurrencies } from "src/shared/api/query/use-website-settings/use-currencies";
import { useCurrencies } from "src/shared/api/query/use-admin/use-currencies";
import { useAdminStore } from "src/shared/store/admin-store";
import { CurrencyForm } from "src/pages/admin/currencies/components/currency-form";

export const WebsiteCurrencies = () => {
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");
  const [isAddMode, setIsAddMode] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<TCurrency | null>(null);

  const { data: currenciesData, isPending: isCurrenciesPending } = useCurrencies();

  const { data: websiteCurrenciesData, isPending: isWebsiteCurrenciesPending } =
    useWebsiteCurrencies(website_slug || "");

  const { currencies, setCurrencies } = useAdminStore();
  const { websiteCurrencies, setWebsiteCurrencies } = useWebsiteStore();

  useEffect(() => {
    if (!!currenciesData) {
      setCurrencies(currenciesData);
    } else {
      setCurrencies(null);
    }
  }, [currenciesData]);

  useEffect(() => {
    if (!!websiteCurrenciesData) {
      setWebsiteCurrencies(websiteCurrenciesData);
    } else {
      setWebsiteCurrencies(null);
    }
  }, [websiteCurrenciesData]);

  return (
    <div className="flex flex-col gap-3 min-w-[230px] w-full">
      {website_slug && (
        <>
          <Button
            type="button"
            variant="link"
            className="flex items-center justify-start gap-2 px-2 rounded-md hover:bg-[#73737311]"
            onClick={() => setIsAddMode(!isAddMode)}>
            {isAddMode ? <Minus className="pb-px w-5 h-5" /> : <Plus className="pb-px w-5 h-5" />}
            {isAddMode ? "Выйти" : "Добавить валюты на сайт"}
          </Button>
          {isAddMode ? (
            <CurrenciesAdder
              items={currencies}
              savedItems={websiteCurrencies}
              setSavedItems={setWebsiteCurrencies}
              website_slug={website_slug}
              isPending={isCurrenciesPending}
            />
          ) : (
            <div className="relative w-full flex flex-col md:flex-row gap-4">
              <CurrencyForm
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                isWebsite
              />
              <CurrencyList
                currencies={websiteCurrencies}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                isPending={isWebsiteCurrenciesPending}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
