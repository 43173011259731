import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { REFERRAL_ROUTES } from "src/app/routing/routes";
import { NavTab } from "src/widgets/navigation/nav-tab";

export const Referral = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(REFERRAL_ROUTES[0]);
  const Component = useMemo(() => currentPage.component, [currentPage.path]);

  useEffect(() => {
    const currentTab = REFERRAL_ROUTES.find((el) => el.path === searchParams.get("tab"));
    if (!!currentTab) {
      setCurrentPage(currentTab);
    }
  }, [searchParams]);

  return (
    <div className="text-sm">
      <div className={`bg-secondary grid grid-cols-2 sm:flex gap-4 flex-wrap p-4`}>
        {REFERRAL_ROUTES.map((el) => {
          const isActive = el.path === currentPage.path;
          return (
            <NavTab
              key={el.title}
              title={el.title}
              isActive={isActive}
              onClick={() => {
                searchParams.set("tab", el.path);
                setSearchParams(searchParams);
              }}
            />
          );
        })}
      </div>
      <div className="p-4">
        <Component />
      </div>
    </div>
  );
};
