export default function Close() {
  return (
    <svg
      className="w-full h-full"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      width={40}
      height={40}
      viewBox="0 0 78 78"
      fill="currentColor">
      <g>
        <rect x="54" y="54" width="6" height="6" />
        <rect x="36" y="36" width="6" height="6" />
        <rect x="30" y="42" width="6" height="6" />
        <rect x="24" y="48" width="6" height="6" />
        <rect x="18" y="54" width="6" height="6" />
        <rect x="42" y="30" width="6" height="6" />
        <rect x="48" y="24" width="6" height="6" />
        <rect x="54" y="18" width="6" height="6" />
        <rect x="42" y="42" width="6" height="6" />
        <rect x="48" y="48" width="6" height="6" />
        <rect x="30" y="30" width="6" height="6" />
        <rect x="18" y="18" width="6" height="6" />
        <rect x="24" y="24" width="6" height="6" />
      </g>
    </svg>
  );
}
