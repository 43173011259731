import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { useBaseMutationWithoutPayload } from "../base-mutation-without-payload";
import adminService from "../../services/admin-service";
import authService from "../../services/auth-service";
import { TUser } from "src/shared/types/admin";

export const useAuth = () => useBaseQuery<null, TUser>(["me"], () => adminService.me());

export const useLogin = () =>
  useBaseMutation<{ user_username: string; user_password: string }, TUser>(
    ["login"],
    async (payload: { user_username: string; user_password: string }) => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      const fingerprint = result.visitorId;

      return await authService.login({
        ...payload,
        fingerprint,
      });
    },
    {
      404: "Пользователь не найден",
      406: "Неверные данные",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useLogout = () =>
  useBaseMutationWithoutPayload<TUser>(["logout"], () => authService.logout());
