import { TLocaleName } from "src/shared/types/admin";
import { LocaleCode } from "./locale-code";

export const LocaleCodesList = ({ baseKey, items }: { baseKey: string; items: TLocaleName[] }) => {
  const moreItemsCount = items?.length > 3 ? items?.length - 3 : 0;
  return (
    <div
      className={`mt-1 ${
        !!moreItemsCount ? "grid grid-cols-[18px_18px_18px_18px]" : "flex flex-wrap gap-1"
      }`}>
      {items.slice(0, 3).map((el) => (
        <LocaleCode key={`${baseKey}_${el.locale_code}`} code={el.locale_code} />
      ))}
      {!!moreItemsCount && (
        <div
          className={`text-[10px] bg-background text-neutral-500 rounded-full p-1 border h-6 flex items-center justify-center ${
            moreItemsCount > 9 ? "w-fit" : "w-6"
          }`}>
          +{moreItemsCount}
        </div>
      )}
    </div>
  );
};
