import { useFormContext } from "react-hook-form";
import { InputProps } from "./input";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "./form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select";
import { TSelect } from "src/shared/types/common";
import { cn } from "@/lib/utils";

export const SelectWithLabel = ({
  label,
  name,
  options,
  placeholder,
  optional,
  variant = "default",
  ...props
}: {
  label: string;
  name: string;
  placeholder: string;
  options: TSelect[];
  optional?: boolean;
  variant?: "default" | "small";
} & InputProps) => {
  const form = useFormContext();

  return (
    <FormField
      control={form.control}
      {...props}
      {...form.register(name)}
      render={({ field }) => (
        <FormItem
          className={cn(
            variant === "small" && "grid grid-cols-[100px_1fr] gap-2 items-baseline text-xs"
          )}>
          <FormLabel
            className={cn(
              "flex justify-between items-end min-h-[18px]",
              variant === "small" && "text-xs"
            )}>
            {label}
            {optional && <small className="text-neutral-400">Опционально *</small>}
          </FormLabel>
          <Select onValueChange={field.onChange} value={field.value}>
            <FormControl>
              <SelectTrigger
                className={cn(optional && "bg-secondary", variant === "small" && "text-xs h-7")}>
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {options?.map((el) => (
                <SelectItem key={el.value} value={el.value}>
                  {el.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
