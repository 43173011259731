import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Input, InputProps } from "./input";
import { Label } from "./label";
import { LocalizationNames } from "src/entities/localization-names";
import { TLocaleName } from "src/shared/types/admin";
import { LanguageBar } from "src/entities/language-bar";
import { useCommonStore } from "src/shared/store/common-store";
import { Button } from "./button";
import Tick from "src/shared/image/tick";

export function LocalizationInputWithLabel({
  label,
  name,
  locales,
  setLocales,
  ...props
}: {
  label: string;
  name: string;
  locales: TLocaleName[];
  setLocales: Dispatch<SetStateAction<TLocaleName[]>>;
} & InputProps) {
  const [value, setValue] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const { languages } = useCommonStore();

  const localeHandler = (locale: TLocaleName) => {
    setSelectedLanguage(locale.locale_code);
    setValue(locale.name);
  };

  useEffect(() => {
    if (!!languages) {
      setSelectedLanguage(languages[0]);
    }
  }, [languages]);

  return (
    <div className="w-full space-y-1.5">
      <Label htmlFor={name}>{label}</Label>
      <div className="w-full">
        <LanguageBar
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
        />
        <div className="w-full flex gap-1.5">
          <Input id={name} value={value} onChange={(e) => setValue(e.target.value)} {...props} />
          <Button
            type="button"
            variant={!!value?.length ? "default" : "outline"}
            size="icon"
            disabled={!value?.length}
            className="p-1 min-w-10 bg-green-400 disabled:bg-secondary"
            onClick={() => {
              const newLocale = {
                locale_code: selectedLanguage,
                name: value,
              };
              if (!!locales?.find((el) => el.locale_code === selectedLanguage)) {
                setLocales(
                  locales?.map((el) => (el.locale_code === selectedLanguage ? newLocale : el))
                );
              } else {
                setLocales([...locales, newLocale]);
              }
            }}>
            <Tick />
          </Button>
        </div>
      </div>
      <LocalizationNames locales={locales} localeHandler={localeHandler} />
    </div>
  );
}
