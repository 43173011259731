import {
  TCreateDirection,
  TDirection,
  TDirectionsPagination,
} from "src/shared/types/websites-settings";
import websiteSettingsService from "../../services/website-settings-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";

export const useDirections = (website_slug: string, params: string) =>
  useBaseQuery<null, TDirectionsPagination>(
    ["get-directions", website_slug, params],
    () => websiteSettingsService.getDirectionsByFilter(website_slug, params),
    !!website_slug?.length
  );

export const useCreateDirection = (website_slug: string) =>
  useBaseMutation<TCreateDirection[], TDirection[]>(
    ["create-direction"],
    (payload: TCreateDirection[]) => websiteSettingsService.addDirection(website_slug, payload),
    {
      403: "Недостаточно прав",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useUpdateDirection = (website_slug: string) =>
  useBaseMutation<TCreateDirection[], TDirection[]>(
    ["update-direction"],
    (payload: TCreateDirection[]) => websiteSettingsService.updateDirection(website_slug, payload),
    {
      403: "Недостаточно прав",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );
