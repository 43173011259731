import { useFormContext } from "react-hook-form";
import { cn } from "@/lib/utils";
import { FormControl, FormDescription, FormField, FormItem, FormLabel } from "./form";
import { Switch } from "./switch";

export const Switcher = ({
  label,
  name,
  disabled = false,
  description = null,
  labelClassName = "",
}: {
  label: string;
  name: string;
  disabled?: boolean;
  description?: string | null;
  labelClassName?: string;
}) => {
  const form = useFormContext();

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <div className="flex flex-row items-center justify-between">
            <FormLabel
              className={cn(
                `pt-1 ${disabled ? "opacity-30" : field.value ? "opacity-100" : "opacity-50"}`,
                labelClassName
              )}>
              {label}
            </FormLabel>
            <FormControl>
              <Switch checked={field.value} onCheckedChange={field.onChange} disabled={disabled} />
            </FormControl>
          </div>
          {!!description && (
            <FormDescription className="text-[10px] opacity-50 leading-[12px]">
              {description}
            </FormDescription>
          )}
        </FormItem>
      )}
    />
  );
};
