import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "@/components/ui/loader";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Card, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { NestedObjects } from "src/shared/types/common";
import { useAddWebsiteCredentials } from "src/shared/api/query/use-admin/use-websites";
import { useAdminStore } from "src/shared/store/admin-store";
import { ECoreService, TCreateSiteCredentials } from "src/shared/types/admin";
import { ServiceCard } from "./service-card";

export const WebsiteCredoForm = ({
  website_slug,
  isFetchPending,
}: {
  website_slug: string;
  isFetchPending: boolean;
}) => {
  const { toast } = useToast();
  const { websiteCredo, setWebsiteCredo } = useAdminStore();

  const defaultServiseValues: Partial<TCreateSiteCredentials> = {
    webcred_api_url: "",
    webcred_api_token: "",
  };

  const services = Object.values(ECoreService);
  const defaultValues = services.reduce(
    (acc, val) => ({ ...acc, [val]: defaultServiseValues }),
    {}
  );

  const form = useForm<typeof defaultValues>({
    defaultValues,
  });

  const { mutateAsync: updateCredentials, isPending } = useAddWebsiteCredentials();

  const disabled =
    !website_slug?.length ||
    !Object.values(form.formState.dirtyFields)?.length ||
    !!Object.values(form.formState.errors)?.length ||
    isPending;

  const onSubmit = async (e: typeof defaultValues) => {
    const updatedServices = Object.keys(form.formState.dirtyFields) as ECoreService[];

    if (!!updatedServices?.length) {
      const payload: TCreateSiteCredentials[] = updatedServices.map((el) => ({
        website_slug,
        webcred_api_url: e[el]?.webcred_api_url || "",
        webcred_api_token:
          !!e[el]?.webcred_api_token && !!e[el]?.webcred_api_token.length
            ? e[el]?.webcred_api_token
            : null,
        webcred_service: el,
      }));
      const data = await updateCredentials(payload);
      if (data && !!websiteCredo) {
        toast({
          title: "Сервисы",
          description: "Данные успешно обновлены",
        });
        setWebsiteCredo(
          websiteCredo.map((el) => {
            const currentService = data?.find(
              (service) => service.webcred_service === el.webcred_service
            );
            if (!!currentService) {
              return currentService;
            } else {
              return el;
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!!websiteCredo) {
      let formValues: NestedObjects<Partial<TCreateSiteCredentials>> = {};

      if (!websiteCredo?.length) {
        form.reset(defaultValues);
        return;
      }

      websiteCredo?.forEach((item) => {
        formValues = {
          ...formValues,
          [item.webcred_service]: {
            webcred_api_url: item.webcred_api_url,
            webcred_api_token: "",
          },
        };
        return formValues;
      });
      form.reset(formValues);
    } else {
      form.reset(defaultValues);
    }
  }, [websiteCredo]);

  return (
    <Card
      className={`lg:sticky top-4 py-4 overflow-hidden duration-500 text-xs whitespace-pre h-fit ${
        !!websiteCredo || isFetchPending
          ? "md:min-w-72 md:max-w-72 md:w-72 lg:max-w-[50vw] lg:min-w-[26vw] lg:max-w-[50vw] px-4 opacity-100 -mx-0"
          : "min-w-0 max-w-0 w-0 px-0 opacity-0 -mx-2"
      }`}>
      {isFetchPending ? (
        <Loader />
      ) : (
        !!websiteCredo && (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-2">
              <CardTitle className="text-lg flex items-center h-10">Сервисы</CardTitle>
              <div className="divide flex flex-col sm:grid grid-cols-2 md:flex lg:grid gap-2 -mx-2">
                {services.map((el) => (
                  <ServiceCard key={el} service_name={el} />
                ))}
              </div>
              <Button type="submit" disabled={disabled}>
                {isPending ? <Loader /> : "Обновить credo"}
              </Button>
            </form>
          </Form>
        )
      )}
    </Card>
  );
};
