import { useSearchParams } from "react-router-dom";
import { WebsitesList } from "src/entities/websites-list";
import { ManualCheatingForm } from "./components/manual-cheating-form";

export const ManualCheating = () => {
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");

  return (
    <div className="w-full flex flex-col gap-4 md:flex-row">
      <WebsitesList />
      <ManualCheatingForm website_slug={website_slug} />
    </div>
  );
};
