import { Dispatch, SetStateAction } from "react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { TCurrency, colorByCurrencyType } from "src/shared/types/admin";
import { LocaleCodesList } from "./locale-codes-list";

export const CurrencyCard = ({
  item,
  selectedCurrency,
  setSelectedCurrency,
}: {
  item: TCurrency;
  selectedCurrency: TCurrency | null;
  setSelectedCurrency: Dispatch<SetStateAction<TCurrency | null>>;
}) => {
  const color = colorByCurrencyType[item.currency_type];

  return (
    <Card
      className={cn(
        `flex flex-col gap-2 p-2 pl-4 cursor-pointer durarion-300 hover:opacity-90 hover:scale-[101%]`,
        selectedCurrency?.currency_code === item.currency_code && "bg-foreground text-background"
      )}
      onClick={() => {
        if (selectedCurrency?.currency_code === item.currency_code) {
          setSelectedCurrency(null);
        } else {
          setSelectedCurrency(item);
        }
      }}>
      <div className={cn(`grid gap-3 min-h-10`, "grid-cols-[12px_1fr_1fr]")}>
        <div className="w-3 h-3 rounded-full mt-1" style={{ backgroundColor: color }} />
        <div>
          <div className="flex flex-col">
            <p className="break-all">{item.currency_code}</p>
            <p className="opacity-50 brightness-75" style={{ color: color }}>
              {item.currency_type}
            </p>
          </div>
          {!!item.localizations?.length && (
            <LocaleCodesList baseKey={item.currency_code} items={item.localizations} />
          )}
        </div>
        <div className="text-[10px]">
          <p>BESTCHANGE ID: {item.best_id}</p>
          <p>EXNODE ID: {item.exnode_id}</p>
          <p>Округление: {item.decimal}</p>
        </div>
      </div>
    </Card>
  );
};
