export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={28}
      width={28}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 5 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      fill="currentColor">
      <g>
        <rect x="49.999" y="25" width="5" height="5" />
        <polyline points="60.001,25 65,25 65,20 69.998,20 70.001,20 75,20 75,15 75,9.999 74.999,9.999 74.999,4.999 70.001,4.999    70,4.999 65,4.999 64.999,4.999 59.999,4.999 59.999,9.999 59.999,15 54.999,15 54.999,20 54.999,25 59.999,25  " />
        <path d="M80.001,40.001v-5H75v-5h-5h-5h-5h-5.001v5H50h-5v-5h-5h-5H30h-5v5h-5v5h-5v5v5v5v4.999V65v5.002v4.999v5h5v5.001h5v5h5   v4.999H35h5h5h5h4.999H60h5h5v-4.999h5v-5h5.001v-5.001H85v-5v-4.999V65v-4.999v-4.999v-5v-5v-5H80.001z M75,45.001v5v5h-5v4.999   h-5h-0.001v-4.999H65h5v-5v-5v-5h5V45.001z" />
      </g>
    </svg>
  );
};
