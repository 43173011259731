import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TWebsite, TWebsiteCredentials, TUser, TCurrency } from "../types/admin";
import { TCity, TCountry } from "../types/websites-settings";

interface AdminStoreState {
  users: TUser[] | null;
  setUsers: (users: TUser[] | null) => void;
  deletedWebsites: TWebsite[] | null;
  setDeletedWebsites: (deletedWebsites: TWebsite[] | null) => void;
  websiteCredo: TWebsiteCredentials[] | null;
  setWebsiteCredo: (siteCredo: TWebsiteCredentials[] | null) => void;
  currencies: TCurrency[] | null;
  setCurrencies: (currencies: TCurrency[] | null) => void;
  countries: TCountry[] | null;
  setCountries: (countries: TCountry[] | null) => void;
  cities: TCity[] | null;
  setCities: (cities: TCity[] | null) => void;
}

const initialState = {
  users: null,
  deletedWebsites: null,
  websiteCredo: null,
  currencies: null,
  countries: null,
  cities: null,
};

export const useAdminStore = create<AdminStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setUsers: (users) => set({ users }),
      setDeletedWebsites: (deletedWebsites) => set({ deletedWebsites }),
      setWebsiteCredo: (websiteCredo) => set({ websiteCredo }),
      setCurrencies: (currencies) => set({ currencies }),
      setCountries: (countries) => set({ countries }),
      setCities: (cities) => set({ cities }),
    }),
    {
      name: "adminStore",
    }
  )
);
