import { ECurrencyType } from "../types/admin";
import { TAvailableDirectionsTypes } from "../types/websites-settings";

export const baseUnavailableDirections: TAvailableDirectionsTypes[] = [
  {
    from_type: ECurrencyType.CRYPTO,
    to_type: ECurrencyType.CRYPTO,
    available: false,
  },
  {
    from_type: ECurrencyType.CASH,
    to_type: ECurrencyType.CASH,
    available: false,
  },
  {
    from_type: ECurrencyType.CARD,
    to_type: ECurrencyType.CARD,
    available: false,
  },
  {
    from_type: ECurrencyType.CARD,
    to_type: ECurrencyType.CASH,
    available: false,
  },
  {
    from_type: ECurrencyType.CASH,
    to_type: ECurrencyType.CARD,
    available: false,
  },
];
