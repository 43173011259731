import { Dispatch, SetStateAction, useState } from "react";
import { Emptiness } from "@/components/ui/emptiness";
import { Loader } from "@/components/ui/loader";
import { PaginationButtons } from "@/components/ui/pagination-buttons";
import { Button } from "@/components/ui/button";
import { TCreateDirection, TDirection } from "src/shared/types/websites-settings";
import { useCommonStore } from "src/shared/store/common-store";
import { useWebsiteStore } from "src/shared/store/website-store";
import { DirectionCard } from "./direction-card";
import { DirectionForm } from "./direction-form";
import { DirectionFilters } from "./direction-filters";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { Minus, Plus } from "lucide-react";

const filterFields = {
  city_code: true,
  currency_from_code: true,
  currency_to_code: true,
  xml_value: true,
  enabled: true,
  bestchange_enabled: true,
  // direction_course_is_manual: true,
};

export const DirectionsList = ({
  website_slug,
  selectedDirection,
  setSelectedDirection,
  isPending,
  setParams,
}: {
  website_slug: string;
  selectedDirection: TDirection | null;
  setSelectedDirection: Dispatch<SetStateAction<TDirection | null>>;
  isPending: boolean;
  setParams: Dispatch<SetStateAction<string>>;
}) => {
  const { directions, directionsTotal } = useWebsiteStore();
  const [isMassUpdate, setMassUpdate] = useState(false);
  const { limit } = useCommonStore();
  const totalPages = Math.ceil((directionsTotal || 1) / limit);
  const [massSelectedDirections, setMassSelectedDirections] = useState<TCreateDirection[]>([]);

  const massUpdateCheckHandler = (isSelected: boolean, direction: TDirection) => {
    if (isSelected) {
      setMassSelectedDirections(
        massSelectedDirections?.filter(
          (el) =>
            `${direction.city.city_code}_${direction.currency_from.currency_code}_${direction.currency_to.currency_code}` !==
            `${el.city_code}_${el.currency_from_code}_${el.currency_to_code}`
        )
      );
    } else {
      setMassSelectedDirections(
        massSelectedDirections.concat([
          {
            city_code: direction.city.city_code,
            currency_from_code: direction.currency_from.currency_code,
            currency_to_code: direction.currency_to.currency_code,
            min_from: direction.min_from,
            max_from: direction.max_from,
            reserve: direction.reserve,
            xml_value: direction.xml_value,
            // direction_course_is_manual: direction.direction_course_is_manual,
          },
        ])
      );
    }
  };

  return (
    <div className={`flex flex-col gap-1 min-w-[220px]`}>
      {website_slug && (
        <div className="relative flex flex-col gap-2 md:grid grid-cols-[320px_1fr]">
          <DirectionForm
            isMassUpdate={isMassUpdate}
            website_slug={website_slug}
            selectedDirection={selectedDirection}
            massSelectedDirections={massSelectedDirections}
            setSelectedDirection={setSelectedDirection}
          />
          <div className="flex flex-col gap-1">
            <DirectionFilters
              website_slug={website_slug}
              setParams={setParams}
              fields={filterFields}
            />
            <Card
              className={cn("flex flex-col gap-2 px-4 py-2", isMassUpdate && "bg-green-400/50")}>
              <div
                onClick={() => {
                  setSelectedDirection(null);
                  setMassSelectedDirections([]);
                  setMassUpdate(!isMassUpdate);
                }}
                className="flex gap-3 cursor-pointer">
                {isMassUpdate ? (
                  <Minus className="pb-px w-5 h-5" />
                ) : (
                  <Plus className="pb-px w-5 h-5" />
                )}
                Массовое редактирование
              </div>
              {isMassUpdate && (
                <div className="flex gap-4">
                  <Button
                    type="button"
                    variant="link"
                    className="p-0 text-xs h-fit"
                    onClick={() => {
                      !!directions &&
                        setMassSelectedDirections(
                          directions.map((direction) => ({
                            city_code: direction.city.city_code,
                            currency_from_code: direction.currency_from.currency_code,
                            currency_to_code: direction.currency_to.currency_code,
                            min_from: direction.min_from,
                            max_from: direction.max_from,
                            reserve: direction.reserve,
                            xml_value: direction.xml_value,
                          }))
                        );
                    }}>
                    Выбрать все
                  </Button>
                  <Button
                    type="button"
                    variant="link"
                    className="p-0 text-xs h-fit"
                    onClick={() => setMassSelectedDirections([])}>
                    Сбросить все
                  </Button>
                </div>
              )}
            </Card>
            {isPending ? (
              <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
                <Loader />
              </div>
            ) : !!directions?.length ? (
              directions?.map((direction) => {
                const isSelected = isMassUpdate
                  ? !!massSelectedDirections?.find(
                      (el) =>
                        `${direction.city.city_code}_${direction.currency_from.currency_code}_${direction.currency_to.currency_code}` ===
                        `${el.city_code}_${el.currency_from_code}_${el.currency_to_code}`
                    )
                  : false;
                return (
                  <div
                    key={direction.direction_id}
                    className={cn(
                      "flex gap-2 items-center",
                      isMassUpdate && (isSelected ? "" : "opacity-30")
                    )}>
                    {isMassUpdate && (
                      <Checkbox
                        checked={isSelected}
                        onCheckedChange={() => massUpdateCheckHandler(isSelected, direction)}
                      />
                    )}
                    <DirectionCard
                      item={direction}
                      selectedDirection={selectedDirection}
                      setSelectedDirection={() => {
                        if (isMassUpdate) {
                          massUpdateCheckHandler(isSelected, direction);
                        } else {
                          setSelectedDirection(isSelected ? null : direction);
                        }
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
                <Emptiness message="Направления не найдены" />
              </div>
            )}
            {totalPages > 1 && (
              <div className="sticky bottom-0">
                <PaginationButtons totalPages={totalPages} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
