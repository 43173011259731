import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { DeleteButton } from "@/components/ui/delete-button";
import { ModalButton } from "@/components/ui/modal-button";
import { Card } from "@/components/ui/card";
import { DialogClose } from "@/components/ui/dialog";
import { EUserRole, TUser } from "src/shared/types/admin";
import { WebsitesBadges } from "./websites-badges";
import { useDeleteUser } from "src/shared/api/query/use-admin/use-users";
import { useAdminStore } from "src/shared/store/admin-store";
import { useAuthStore } from "src/shared/store/auth-store";
import { checkStatusCode } from "src/shared/utils/common";

export const UserCard = ({
  item,
  color,
  selectedUser,
  setSelectedUser,
}: {
  item: TUser;
  color: string;
  selectedUser: TUser | null;
  setSelectedUser: Dispatch<SetStateAction<TUser | null>>;
}) => {
  const { toast } = useToast();
  const { me } = useAuthStore();
  const { users, setUsers } = useAdminStore();
  const { mutateAsync: deleteUser, isPending, error } = useDeleteUser(item.user_username);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onDeleteUser = async () => {
    const data = await deleteUser();
    if (data && !!users) {
      toast({
        title: "Удаление пользователя",
        description: "Пользователь успешно удален",
      });
      setUsers(users.filter((el) => el.user_username !== item.user_username));
    }
  };

  useEffect(() => {
    if (!!error) {
      const baseToast = {
        variant: "destructive" as const,
        title: "Удаление пользователя",
      };
      if (checkStatusCode([error], 403)) {
        toast({ ...baseToast, description: "Недостаточно прав" });
      } else if (checkStatusCode([error], 500)) {
        toast({ ...baseToast, description: "Не удалось удалить пользователя" });
      } else {
        toast({ ...baseToast, description: "Неизвестная ошибка" });
      }
    }
  }, [error]);

  return (
    <Card
      className={`flex flex-col gap-2 py-2 pr-3 pl-4 ${
        selectedUser?.user_id === item.user_id ? "bg-foreground text-background" : ""
      }`}>
      <div className="grid grid-cols-[12px_1fr_40px] items-center gap-3 min-h-10">
        <div className="w-3 h-3 rounded-full" style={{ backgroundColor: color }} />
        <div
          className="flex flex-col 2xl:grid grid-cols-[1fr_88px] cursor-pointer durarion-300 hover:opacity-90 hover:scale-[102%]"
          onClick={() => {
            if (selectedUser?.user_id === item.user_id) {
              setSelectedUser(null);
            } else {
              setSelectedUser(item);
            }
          }}>
          <p className="break-all">{item.user_username}</p>
          <p className="2xl:text-center text-neutral-500">{item.user_role_name}</p>
        </div>
        {me?.user_username !== item.user_username && (
          <ModalButton open={isModalOpen} setOpen={setIsModalOpen} triggerButton={<DeleteButton />}>
            <p>
              Вы уверены, что хотите удалить пользователя <b>{item.user_username}</b>?
            </p>
            <DialogClose asChild>
              <Button
                type="button"
                variant="destructive"
                className="justify-self-end"
                disabled={isPending}
                onClick={onDeleteUser}>
                Удалить
              </Button>
            </DialogClose>
          </ModalButton>
        )}
      </div>
      {!!item.websites?.length && item.user_role_name !== EUserRole.SUPER_ADMIN && (
        <WebsitesBadges websites={item.websites} />
      )}
    </Card>
  );
};
