import { AxiosResponse } from "axios";
import { BASE_URL } from "src/shared/constants";
import api from "../api-instance";
import {
  TCreateDirection,
  TDirection,
  TDirectionsPagination,
} from "src/shared/types/websites-settings";
import { TCurrency } from "src/shared/types/admin";

export default class websiteSettingsService {
  // currencies
  static async getWebsiteCurrencies(website_slug: string): Promise<AxiosResponse<TCurrency[]>> {
    return api.get(`${BASE_URL}/v1/currency/website/${website_slug}`);
  }
  static async addWebsiteCurrency(
    website_slug: string,
    payload: TCurrency[]
  ): Promise<AxiosResponse<TCurrency[]>> {
    return api.post(`${BASE_URL}/v1/currency/website/${website_slug}`, payload);
  }
  static async updateWebsiteCurrency(
    website_slug: string,
    payload: TCurrency[]
  ): Promise<AxiosResponse<TCurrency[]>> {
    return api.patch(`${BASE_URL}/v1/currency/website/${website_slug}`, payload);
  }
  // directions
  static async getDirections(website_slug: string): Promise<AxiosResponse<TDirection[]>> {
    return api.get(`${BASE_URL}/v1/directions/website/${website_slug}`);
  }
  static async getDirectionsByFilter(
    website_slug: string,
    params: string
  ): Promise<AxiosResponse<TDirectionsPagination>> {
    return api.get(`${BASE_URL}/v1/directions/website/${website_slug}/filter?${params}`);
  }
  static async addDirection(
    website_slug: string,
    payload: TCreateDirection[]
  ): Promise<AxiosResponse<TDirection[]>> {
    return api.post(`${BASE_URL}/v1/directions/website/${website_slug}`, payload);
  }
  static async updateDirection(
    website_slug: string,
    payload: TCreateDirection[]
  ): Promise<AxiosResponse<TDirection[]>> {
    return api.patch(`${BASE_URL}/v1/directions/website/${website_slug}`, payload);
  }
}
