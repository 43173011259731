import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useAuthStore } from "src/shared/store/auth-store";
import { TWebsite } from "src/shared/types/admin";
import { WebsiteCard } from "./websites-card";

export const WebsitesList = ({
  deleteWebsite,
  isDeleted = false,
  deletedWebsites,
  isHidden = false,
}: {
  deleteWebsite?: (website_slug: string, isRecovery: boolean) => Promise<void>;
  isDeleted?: boolean;
  deletedWebsites?: TWebsite[];
  isHidden?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");
  const { websites } = useAuthStore();
  const [selectedWebsite, setSelectedWebsite] = useState<TWebsite | null>(null);

  const websitesArr = (isDeleted ? deletedWebsites : websites) || [];

  useEffect(() => {
    if (!!website_slug && !selectedWebsite && !!websites?.length) {
      const currentWebsite = websites.find((el) => el.website_slug === website_slug);
      if (!currentWebsite) {
        return;
      }
      setSelectedWebsite(currentWebsite);
    }
  }, [website_slug, websites])

  return (
    <div
      className={cn(
        `space-y-2 rounded-md bg-secondary py-2 h-fit duration-300`,
        !isHidden
          ? "max-w-78 px-2 opacity-100 mx-0"
          : "hidden lg:block lg:max-w-[0] lg:px-0 lg:opacity-0 lg:-mx-2 lg:overflow-hidden"
      )}>
      {websitesArr?.map((el) => (
        <WebsiteCard
          key={el.website_id}
          item={el}
          selectedWebsite={selectedWebsite}
          setSelectedWebsite={setSelectedWebsite}
          deleteWebsite={deleteWebsite}
          isDeleted={isDeleted}
          isHidden={isHidden}
        />
      ))}
    </div>
  );
};
