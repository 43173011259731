import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Minus, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useDirections } from "src/shared/api/query/use-website-settings/use-directions";
import { useCommonStore } from "src/shared/store/common-store";
import { useWebsiteStore } from "src/shared/store/website-store";
import { TDirection } from "src/shared/types/websites-settings";
import { DirectionsAdder } from "src/entities/adder/directions-adder/directions-adder";
import { DirectionsList } from "./components/directions-list";

export const WebsiteDirections = () => {
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");
  const [isAddMode, setIsAddMode] = useState(false);
  const [selectedDirection, setSelectedDirection] = useState<TDirection | null>(null);
  const { limit } = useCommonStore();
  const [params, setParams] = useState(`limit=${limit}&offset=0`);

  const { data: directionsData, isPending: isDirectionsPending } = useDirections(
    website_slug || "",
    params
  );

  const { setDirections, setDirectionsTotal } = useWebsiteStore();

  useEffect(() => {
    if (!!directionsData) {
      setDirectionsTotal(directionsData.total);
      setDirections(directionsData.data);
    } else {
      setDirectionsTotal(null);
      setDirections(null);
    }
  }, [directionsData]);

  return (
    <div className="flex flex-col gap-3 min-w-[230px] w-full">
      {website_slug && (
        <>
          <Button
            type="button"
            variant="link"
            className="flex items-center justify-start gap-2 px-2 rounded-md hover:bg-[#73737311]"
            onClick={() => setIsAddMode(!isAddMode)}>
            {isAddMode ? <Minus className="pb-px w-5 h-5" /> : <Plus className="pb-px w-5 h-5" />}
            {isAddMode ? "Выйти" : "Добавить направления на сайт"}
          </Button>
          {isAddMode ? (
            <DirectionsAdder website_slug={website_slug} />
          ) : (
            <>
              <DirectionsList
                website_slug={website_slug}
                selectedDirection={selectedDirection}
                setSelectedDirection={setSelectedDirection}
                isPending={isDirectionsPending}
                setParams={setParams}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};
