import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { useBaseMutationWithoutPayload } from "../base-mutation-without-payload";
import adminService from "../../services/admin-service";
import { TCreateUser, TUpdateUser, TUser } from "src/shared/types/admin";

export const useUsers = () =>
  useBaseQuery<null, TUser[]>(["get-users"], () => adminService.getUsers());

export const useCreateUser = () =>
  useBaseMutation<TCreateUser, TUser>(
    ["create-user"],
    (payload: TCreateUser) => adminService.addUser(payload),
    {
      403: "Недостаточно прав",
      409: "Пользователь с таким логином уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useUpdateUser = () =>
  useBaseMutation<TUpdateUser, TUser>(
    ["update-user"],
    (payload: TUpdateUser) => adminService.updateUser(payload),
    {
      403: "Недостаточно прав",
      404: "Пользователя не существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useDeleteUser = (user_username: string) =>
  useBaseMutationWithoutPayload<TUser>(["delete-user", user_username], () =>
    adminService.deleteUser(user_username)
  );
