import { CalendarIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { FormControl, FormItem } from "@/components/ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import { format, isToday } from "date-fns";
import { getEndOfTheDate, getStartOfTheDate } from "src/shared/utils/date";

export const DatePicker = ({ start_key, end_key }: { start_key: string; end_key: string }) => {
  const { setValue } = useFormContext();
  const [date, setDate] = useState<DateRange | undefined>({
    from: new Date(getStartOfTheDate(new Date()) * 1000),
    to: new Date(getEndOfTheDate(new Date()) * 1000),
  });

  useEffect(() => {
    if (!date?.from || !date.to) {
      return;
    }
    setValue(start_key, date.from.toISOString());
    setValue(end_key, new Date(getEndOfTheDate(date.to) * 1000).toISOString());
  }, [date]);

  return (
    <FormItem className="flex flex-col">
      <Popover>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              variant={"outline"}
              className={cn(
                "w-[240px] pl-3 text-left font-normal",
                !date && "text-muted-foreground"
              )}>
              {date?.from && date?.to ? (
                isToday(date.from) && isToday(date.to) ? (
                  "Сегодня"
                ) : (
                  <span>
                    {format(date.from, "dd MMM yyyy") || ""} -{" "}
                    {format(date.to, "dd MMM yyyy") || ""}
                  </span>
                )
              ) : (
                <span>Pick a date</span>
              )}
              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar mode="range" selected={date} onSelect={setDate} initialFocus />
        </PopoverContent>
      </Popover>
    </FormItem>
  );
};
