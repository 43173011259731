import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import adminService from "../../services/admin-service";
import {
  TCreateSite,
  TCreateSiteCredentials,
  TUpdateSite,
  TWebsite,
  TWebsiteCredentials,
} from "src/shared/types/admin";

export const useCreateWebsite = () =>
  useBaseMutation<TCreateSite, TWebsite>(
    ["create-website"],
    (payload: TCreateSite) => adminService.addSite(payload),
    {
      403: "Недостаточно прав",
      409: "Сайт с таким названием, URL или идентификатором уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useUpdateWebsite = () =>
  useBaseMutation<TUpdateSite, TWebsite>(
    ["update-website"],
    (payload: TUpdateSite) => adminService.updateSite(payload),
    {
      403: "Недостаточно прав",
      404: "Сайт не найден",
      409: "Сайт с таким названием, URL или идентификатором уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useDeletedWebsites = () =>
  useBaseQuery<null, TWebsite[]>(["get-deleted-websites"], () => adminService.getDeletedSites());

export const useWebsiteCredentials = (website_slug: string) =>
  useBaseQuery<null, TWebsiteCredentials[]>(["get-website-credentials", website_slug], () =>
    adminService.getSiteCredentials(website_slug)
  );

export const useAddWebsiteCredentials = () =>
  useBaseMutation<TCreateSiteCredentials[], TWebsiteCredentials[]>(
    ["update-website-credentials"],
    (payload: TCreateSiteCredentials[]) => adminService.addSiteCredentials(payload),
    {
      403: "Недостаточно прав",
      404: "Запись не найдена",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );
