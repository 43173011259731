import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import Close from "src/shared/image/close";
import { TSelect } from "src/shared/types/common";

export const StringBadges = ({
  baseKey,
  items,
  onDelete,
}: {
  baseKey: string;
  items: TSelect[] | string[];
  onDelete?: (item: string) => void;
}) => {
  return (
    <div className="flex flex-wrap gap-1 -mx-1 my-2">
      {items?.map((el: TSelect | string) => {
        const label = typeof el === "string" ? el : el.label;
        const value = typeof el === "string" ? el : el.value;
        return (
          <Badge
            key={`${baseKey}_${value}`}
            variant="outline"
            className={cn(
              "flex items-center gap-2 text-xs text-foreground bg-background",
              !!onDelete && "cursor-pointer hover:bg-red-500 hover:text-white"
            )}
            onClick={() => onDelete?.(value)}>
            {label}
            {!!onDelete && (
              <div className="w-4 h-4 rounded-full bg-[#ffffff99] text-red-500 -mr-2">
                <Close />
              </div>
            )}
          </Badge>
        );
      })}
    </div>
  );
};
