import { TSelect } from "src/shared/types/common";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import { useState } from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";

export const MultiSelect = ({
  label,
  options,
  className,
  placeholder,
  selected,
  variant = "default",
  setFn,
}: {
  label?: string;
  options: TSelect[];
  className?: string;
  placeholder?: string;
  selected: TSelect[];
  variant?: "default" | "small";
  setFn: (value: string) => void;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={cn(
        className,
        variant === "small" && "text-xs",
        !!label && "grid grid-cols-[100px_1fr] gap-2 items-baseline"
      )}>
      {!!label && <p className="text-sm">{label}</p>}
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn("w-full justify-between", variant === "small" && "h-7 text-xs")}>
            {placeholder}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[200px] p-0">
          <Command>
            <CommandInput placeholder={placeholder} />
            <CommandList>
              <CommandEmpty>No options found.</CommandEmpty>
              <CommandGroup>
                {options?.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.label}
                    onSelect={(currentLabel) => {
                      const currentValue =
                        options?.find((el) => el.label === currentLabel)?.value || "";
                      setFn(currentValue);
                    }}>
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        !!selected?.some((el) => el.value === option.value)
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
