import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";
import { Dispatch, SetStateAction } from "react";
import { TSelectedPair } from "src/shared/types/websites-settings";
import { SelectedPairs } from "./selected-pairs";

interface VectorsPreviewProps {
  emptyPairsMessage: string;
  selectedPairs: TSelectedPair[];
  submit: () => void;
  disabled: boolean;
  isLoading: boolean;
  setSelectedPairs: Dispatch<SetStateAction<TSelectedPair[]>>;
}

export const DirectionsPreview = ({
  emptyPairsMessage,
  selectedPairs,
  submit,
  disabled,
  isLoading,
  setSelectedPairs,
}: VectorsPreviewProps) => {
  return (
    <div className="flex flex-col">
      <h3 className="font-medium text-lg text-center">Созданные направления</h3>
      <div className="w-full overflow-x-auto">
        <SelectedPairs
          emptyMessage={emptyPairsMessage}
          selectedPairs={selectedPairs}
          removeItem={(id: string) =>
            setSelectedPairs((prev) => prev.filter((item) => item.id !== id))
          }
          removeAll={() => setSelectedPairs([])}
        />
      </div>
      <Button className="mx-auto px-[12px] mt-[5px]" disabled={disabled} onClick={submit}>
        {isLoading ? <Loader2 className="animate-spin" /> : "Подтвердить"}
      </Button>
    </div>
  );
};
