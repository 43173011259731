import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export function useBaseQuery<P, R>(
  queryKeys: string[],
  request: (payload?: P) => Promise<AxiosResponse<R, string>>,
  enabled: boolean = true
) {
  const { data, isPending, error } = useQuery({
    queryKey: queryKeys,
    queryFn: async () => {
      try {
        if (queryKeys?.some((el) => el === "")) {
          return null;
        }
        const data = await request();
        if (!data) {
          throw new Error("500");
        }
        if (data.status < 200 || data.status >= 300) {
          throw new Error(data.status?.toString());
        }
        return data.data;
      } catch (e) {
        throw new Error(e as string);
      }
    },
    enabled: enabled,
  });

  return {
    data,
    isPending,
    error,
  };
}
