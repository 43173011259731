export default function Dino() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100">
      <path
        d="M55.5,64.782v-3.691h3.688v-3.685h3.688v-3.691h3.686v3.691h3.688v-3.691h3.689V50.03h-3.689h-3.688v-3.691v-3.685h3.688  h3.689h3.691V38.97h-3.691h-3.689v-3.691h3.689h3.691h3.687H85v-3.685v-3.691v-3.685v-3.691h-3.685v-3.685h-3.687h-3.691h-3.689  h-3.688h-3.686h-3.688H55.5v3.685h-3.688v3.691v3.685v3.691v3.685v3.691h-3.685v3.685h-3.691v3.685h-3.685v3.691H37.06v3.616v0.068  h-3.685h-3.623h-0.068h-3.623V50.03h-3.685v-3.691h-3.691v-3.685V38.97H15v3.685v3.685v3.691v3.685h3.685v3.691h3.691v3.685h3.685  v3.691h3.623v3.623h3.691v0.062v3.623h3.685h0.021v3.692v3.685v3.691h3.685h3.691v-3.691h-3.691v-3.685v-3.617h3.671v-3.691h3.691  v3.691h3.685v3.617v3.685h-0.021v3.691h3.691h3.685v-3.691H55.5v-3.685v-3.617V72.09v-3.616v-3.685 M59.188,31.594v-3.691h3.688  v3.691H59.188z"
        fill="currentColor"
      />
    </svg>
  );
}
