import { create } from "zustand";
import { persist } from "zustand/middleware";
import { LS } from "../constants";

interface CommonStoreState {
  languages: string[] | null;
  setLanguages: (languages: string[] | null) => void;
  limit: number;
  setLimit: (limit: number) => void;
}

const initialState = {
  languages: null,
  limit: 20,
};

export const useCommonStore = create<CommonStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setLanguages: (languages) => set({ languages }),
      setLimit: (limit) => {
        localStorage.setItem(LS.LIMIT, limit.toString());
        set({ limit });
      },
    }),
    {
      name: "commonStore",
    }
  )
);
