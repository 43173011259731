export const BASE_URL = import.meta.env.VITE_API_ENDPOINT;

export const loginRegex = /[^\s"]*$/;
// eslint-disable-next-line
export const mailRegex = /^[A-Za-z0-9\._+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,7}$/;
export const passwordRegex = /^\S*$/;

export enum LS {
  THEME = "theme",
  LIMIT = "limit",
}
