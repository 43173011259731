import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Emptiness } from "@/components/ui/emptiness";
import {
  useDeletedWebsites,
  useUpdateWebsite,
  useWebsiteCredentials,
} from "src/shared/api/query/use-admin/use-websites";
import { useAuthStore } from "src/shared/store/auth-store";
import { TWebsite } from "src/shared/types/admin";
import { WebsitesList } from "src/entities/websites-list";
import { WebsitesForm } from "./components/websites-form";
import { WebsiteCredoForm } from "./components/websites-credo-form";
import { useAdminStore } from "src/shared/store/admin-store";
import { useToast } from "@/components/ui/use-toast";
import { checkStatusCode } from "src/shared/utils/common";

export const Websites = () => {
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");
  const [selectedWebsite, setSelectedWebsite] = useState<TWebsite | null>(null);
  const { websites, setWebsites } = useAuthStore();
  const { deletedWebsites, setDeletedWebsites, setWebsiteCredo } = useAdminStore();

  const {
    data: websiteCredoData,
    isPending: isCredentialsPending,
    error: credentialsError,
  } = useWebsiteCredentials(selectedWebsite?.website_slug || "");
  const { data: deletedData, error: deletedDataError } = useDeletedWebsites();
  const { mutateAsync: updateWebsite, error: deleteWebsiteError } = useUpdateWebsite();

  const deleteWebsite = async (website_slug: string, isRecovery: boolean) => {
    const payload = {
      website_slug,
      website_is_deleted: !isRecovery,
    };
    const data = await updateWebsite(payload);
    if (data) {
      if (isRecovery && !!deletedWebsites) {
        setDeletedWebsites(deletedWebsites.filter((el) => el.website_slug !== website_slug));
        if (!!websites?.length) {
          setWebsites([...websites, data]);
        } else {
          setWebsites([data]);
        }
      } else if (!isRecovery && !!websites) {
        setWebsites(websites.filter((el) => el.website_slug !== website_slug));
        if (!!deletedWebsites?.length) {
          setDeletedWebsites([...deletedWebsites, data]);
        } else {
          setDeletedWebsites([data]);
        }
      }
    }
  };

  useEffect(() => {
    if (!!websites && !!website_slug) {
      const currentWebsite = websites.find((el) => el.website_slug === website_slug);
      if (!currentWebsite) {
        return;
      }
      setSelectedWebsite(currentWebsite);
    }
  }, [website_slug])

  useEffect(() => {
    if (!!deletedData) {
      setDeletedWebsites(deletedData);
    } else {
      setDeletedWebsites(null);
    }
  }, [deletedData]);

  useEffect(() => {
    if (!!websiteCredoData) {
      setWebsiteCredo(websiteCredoData);
    } else {
      setWebsiteCredo(null);
    }
  }, [websiteCredoData]);

  useEffect(() => {
    if (!!credentialsError || !!deletedDataError || !!deleteWebsiteError) {
      const baseToast = {
        variant: "destructive" as const,
        title: "Сайты",
      };
      if (checkStatusCode([credentialsError, deletedDataError, deleteWebsiteError], 403)) {
        toast({ ...baseToast, description: "Недостаточно прав" });
      } else if (checkStatusCode([credentialsError, deletedDataError, deleteWebsiteError], 422)) {
        toast({
          ...baseToast,
          description: "Ошибка валидации - проверьте правильность заполнения полей",
        });
      } else if (checkStatusCode([credentialsError, deletedDataError, deleteWebsiteError], 500)) {
        toast({ ...baseToast, description: "Не удалось совершить действие" });
      } else {
        toast({ ...baseToast, description: "Неизвестная ошибка" });
      }
    }
  }, [credentialsError, deletedDataError, deleteWebsiteError]);

  return (
    <div className={"relative w-full flex flex-col gap-4 lg:flex-row"}>
      <WebsitesForm selectedSite={selectedWebsite} setSelectedSite={setSelectedWebsite} />
      <WebsiteCredoForm
        website_slug={selectedWebsite?.website_slug || ""}
        isFetchPending={isCredentialsPending}
      />
      <div className="flex flex-col xl:flex-row gap-4">
        <div className="flex flex-col gap-1 min-w-[320px]">
          <Card className="text-xs text-neutral-500 px-4 py-3 uppercase">Активные сайты</Card>
          {!!websites?.length ? (
            <WebsitesList
              deleteWebsite={deleteWebsite}
            />
          ) : (
            <div className="mx-auto w-48">
              <Emptiness message="Активные сайты не найдены" />
            </div>
          )}
        </div>
        {!!deletedWebsites?.length && (
          <div className="flex flex-col gap-1 min-w-[320px]">
            <Card className="text-xs text-neutral-500 px-4 py-3 uppercase bg-secondary">
              Неактивные сайты
            </Card>
            <WebsitesList
              deleteWebsite={deleteWebsite}
              deletedWebsites={deletedWebsites}
              isDeleted
            />
          </div>
        )}
      </div>
    </div>
  );
};
