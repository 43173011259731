import { useState } from "react";
import { Search } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { Card } from "@/components/ui/card";
import { Emptiness } from "@/components/ui/emptiness";
import { Loader } from "@/components/ui/loader";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useCreateWebsiteCurrency } from "src/shared/api/query/use-website-settings/use-currencies";
import { ECurrencyType, TCurrency } from "src/shared/types/admin";
import { TSelect } from "src/shared/types/common";
import { charFix, translit } from "src/shared/utils/charfix";
import { AdderGroup } from "./components/adder-group";

export const CurrenciesAdder = ({
  items,
  savedItems,
  setSavedItems,
  website_slug,
  isPending,
}: {
  items: TCurrency[] | null;
  savedItems: TCurrency[] | null;
  setSavedItems: (currencies: TCurrency[]) => void;
  website_slug: string | null;
  isPending: boolean;
}) => {
  const { toast } = useToast();
  const [selectedArr, setSelectedArr] = useState<string[]>([]);
  const [searchString, setSearchString] = useState("");

  const { mutateAsync: addWebsiteCurrencies, isPending: isAddPending } = useCreateWebsiteCurrency(
    website_slug || ""
  );

  const filteredItems = !!searchString?.length
    ? items?.filter((el) => {
        const elStr = JSON.stringify(el)?.toLowerCase();
        const str = searchString?.toLowerCase();
        return (
          elStr?.includes(str) ||
          elStr?.includes(charFix(str, true)) ||
          elStr?.includes(charFix(str, false)) ||
          elStr?.includes(translit(str))
        );
      })
    : items;

  const groups: {
    label: string;
    value: string;
    items: TSelect[];
  }[] = Object.values(ECurrencyType)?.map((currency_type) => ({
    label: currency_type.toUpperCase(),
    value: currency_type,
    items:
      filteredItems
        ?.filter((item) => item.currency_type === currency_type)
        ?.map((el) => ({
          label: el.currency_code,
          value: el.currency_code,
        })) || [],
  }));

  const selectHandler = (item: string) => {
    if (selectedArr?.includes(item)) {
      setSelectedArr(selectedArr.filter((el) => el !== item));
    } else {
      setSelectedArr([...selectedArr, item]);
    }
  };

  const selectGroupHandler = (group_value: string, isSelectGroup: boolean) => {
    const addedArr = groups
      ?.find((el) => el.value === group_value)
      ?.items?.map((el) => el.value)
      ?.filter((el) => !savedItems?.some((saved_el) => saved_el.currency_code === el));

    if (!addedArr?.length) {
      return;
    }
    if (isSelectGroup) {
      const arr = selectedArr.concat(addedArr);
      setSelectedArr(arr);
    } else {
      setSelectedArr(selectedArr?.filter((el) => !addedArr.includes(el)));
    }
  };

  const submit = async () => {
    const payload = selectedArr
      ?.map((el) => items?.find((item) => item.currency_code === el))
      ?.filter((el) => !!el) as TCurrency[];
    const data = await addWebsiteCurrencies(payload);
    if (!!data && Array.isArray(data)) {
      toast({
        title: "Валюты",
        description: "Валюты успешно добавлены",
      });
      setSavedItems((savedItems || []).concat(data));
      setSelectedArr([]);
    }
  };

  return (
    <Card className="w-full min-h-72 h-fit bg-secondary rounded-md p-2 space-y-2">
      {!website_slug ? (
        <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
          Выберите сайт
        </div>
      ) : isPending ? (
        <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
          <Loader />
        </div>
      ) : !!items?.length ? (
        <div className="h-full w-full flex flex-col gap-2 justify-between">
          <div className="relative">
            <Search className="absolute top-1/2 -translate-y-1/2 left-2 w-5 h-5 opacity-50" />
            <Input
              placeholder="Найти..."
              className="pl-9"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
          </div>
          <div className="flex flex-col md:grid grid-cols-3 gap-y-4 gap-x-2">
            {groups?.map((group) => (
              <AdderGroup
                key={group.label}
                group_label={group.label}
                group_value={group.value}
                items={group.items}
                savedItems={savedItems}
                selectedArr={selectedArr}
                selectHandler={selectHandler}
                selectGroupHandler={selectGroupHandler}
              />
            ))}
          </div>
          <Button
            type="button"
            className="self-end"
            disabled={isAddPending || !selectedArr?.length}
            onClick={submit}>
            Сохранить
          </Button>
        </div>
      ) : (
        <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
          <Emptiness message="Валюты не найдены" />
        </div>
      )}
    </Card>
  );
};
