import { useState } from "react";
import { cn } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { ModalButton } from "@/components/ui/modal-button";
import Question from "src/shared/image/question";
import { XML_options } from "src/shared/types/websites-settings";

export const XMLModal = ({ variant = "default" }: { variant?: "default" | "small" }) => {
  const [isXMLHintOpen, setIsXMLHintOpen] = useState(false);

  return (
    <ModalButton
      open={isXMLHintOpen}
      setOpen={setIsXMLHintOpen}
      triggerButton={
        <Button
          type="button"
          variant="outline"
          size="icon"
          className={cn(variant === "small" ? "p-1 w-7 h-7 min-w-7" : "p-2.5 w-10 h-10 min-w-10")}>
          <Question />
        </Button>
      }>
      <div className="max-h-[70vh] overflow-y-scroll mt-3 pr-2 text-sm space-y-4">
        {XML_options.map((el) => {
          return (
            <div key={el.value} className="grid grid-cols-[100px_1fr] gap-3">
              <Badge variant="secondary" className="h-fit w-fit  text-foreground rounded-lg">
                {el.label}
              </Badge>
              <div className="opacity-70">{el.description}</div>
            </div>
          );
        })}
      </div>
    </ModalButton>
  );
};
