import { useState } from "react";
import { Search } from "lucide-react";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { TSelectedPair } from "src/shared/types/websites-settings";
import { charFix, translit } from "src/shared/utils/charfix";
import SelectedPairCard from "./selected-pair-card";

const columns = ["Город", "From", "To"];

export const SelectedPairs = ({
  emptyMessage,
  selectedPairs,
  removeItem,
  removeAll,
  className,
}: {
  emptyMessage?: string;
  className?: string;
  selectedPairs: TSelectedPair[];
  removeItem: (id: string) => void;
  removeAll: () => void;
}) => {
  const [searchString, setSearchString] = useState("");

  return (
    <div
      className={cn(
        "flex flex-col gap-4 overflow-y-auto h-full max-h-[70vh] text-sm px-1",
        className
      )}
      style={{
        height: "auto",
        margin: "10px 0",
      }}>
      <small className="text-lightFont leading-4 whitespace-pre opacity-80 pl-[6px]">
        {`Перед тем, как нажать "Подтверить",\nпроверьте выбранные пары`}
      </small>
      <div className="relative">
        <Search className="absolute top-1/2 -translate-y-1/2 left-2 w-5 h-5 opacity-50" />
        <Input
          placeholder="Найти..."
          className="pl-9"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-between">
        <h3 className="font-bold">Выбранные пары</h3>
        <button type="button" onClick={removeAll} className="text-lightFont">
          удалить все
        </button>
      </div>
      <div className="flex flex-col gap-1">
        <div className="grid grid-cols-[80px_1fr_1fr_40px] gap-2 w-full">
          {columns.map((column) => (
            <span key={column} className="text-left text-xs font-medium px-2 py-1">
              {column}
            </span>
          ))}
        </div>
        {selectedPairs.length === 0 && (
          <div className="flex w-full items-center justify-center py-[6px] text-[14px]">
            {emptyMessage || "Вы еще не создали пары"}
          </div>
        )}
        {selectedPairs
          ?.filter((pair: TSelectedPair) => {
            if (!!searchString?.length) {
              const pairStr = JSON.stringify(pair)?.toLowerCase();
              const str = searchString?.toLowerCase();
              return (
                pairStr?.includes(str) ||
                pairStr?.includes(charFix(str, true)) ||
                pairStr?.includes(charFix(str, false)) ||
                pairStr?.includes(translit(str))
              );
            } else {
              return true;
            }
          })
          ?.map((pair: TSelectedPair) => (
            <SelectedPairCard
              key={pair.id}
              selectedPair={pair}
              onDelete={() => removeItem(pair.id)}
            />
          ))}
      </div>
    </div>
  );
};
