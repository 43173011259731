import { z } from "zod";
import { loginRegex } from "../constants";

export const string_schema = z.string({ required_error: "Это обязательное поле" });

export const user_username_schema = z
  .string({ required_error: "Это обязательное поле" })
  .min(1, { message: "Это обязательное поле" })
  .regex(loginRegex, { message: "Только латинские символы и цифры" });

export const user_password_schema = z
  .string({ required_error: "Это обязательное поле" })
  .min(12, { message: "Пароль от 12 символов" });

export const verify_code_schema = z
  .string({ required_error: "Это обязательное поле" })
  .min(6, { message: "OTP - 6 символов" })
  .max(6, { message: "OTP - 6 символов" });
