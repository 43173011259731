import { useState } from "react";
import { UsersForm } from "./components/users-form";
import { UsersList } from "./components/users-list";
import { TUser } from "src/shared/types/admin";

export const Users = () => {
  const [selectedUser, setSelectedUser] = useState<TUser | null>(null);

  return (
    <div className={"relative w-full flex flex-col gap-2 sm:flex-row"}>
      <UsersForm selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
      <UsersList selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
    </div>
  );
};
