// users

export type TUserColumn = {
  user_role_name: EUserRole;
  color: string;
  users: TUser[];
};

export type TUser = {
  user_id: string;
  user_username: string;
  user_role_name: EUserRole;
  websites?: TWebsite[];
};

export type TCreateUser = {
  user_username: string;
  user_password: string;
  user_role_name: EUserRole;
};

export type TUpdateUser = {
  user_username: string;
  user_new_password?: string;
  user_role_name?: EUserRole;
  websites?: string[]; // website_slug[]
};

export enum EUserRole {
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
  USER = "user",
}

export const colorByUserRole = {
  [EUserRole.SUPER_ADMIN]: "#fbbf24",
  [EUserRole.ADMIN]: "#10b981",
  [EUserRole.USER]: "#d4d4d4",
};

// sites

export type TWebsite = {
  website_id: string;
  website_name: string;
  website_url: string;
  website_slug: string;
};

export type TCreateSite = {
  website_name: string;
  website_url: string;
  website_slug: string;
};

export type TUpdateSite = {
  website_slug: string;
  website_name?: string;
  website_url?: string;
  website_is_deleted?: boolean;
};

export type TWebsiteCredentials = {
  credentials_id: string;
  website_slug: string;
  webcred_api_url: string;
  webcred_service: ECoreService;
};

export type TCreateSiteCredentials = {
  website_slug: string;
  webcred_api_url: string;
  webcred_api_token: string;
  webcred_service: ECoreService;
};

export enum ECoreService {
  BIDS_API = "bids_api",
  TEXT_API = "text_api",
  CUSTOMER_API = "customer_api",
  FEEDBACK_API = "feedback_api",
}

// currency

export type TLocaleName = {
  name: string;
  locale_code: string;
};

export type TCurrency = {
  currency_id: number;
  currency_code: string;
  currency_type: ECurrencyType;
  best_id: number;
  exnode_id: number | null;
  localizations: TLocaleName[];
  decimal: number;
};

export type TCreateCurrency = {
  currency_code: string;
  currency_type: ECurrencyType;
  best_id: number;
  exnode_id: number | null;
  localizations: TLocaleName[];
  decimal: number;
};

export enum ECurrencyType {
  CRYPTO = "crypto",
  CASH = "cash",
  CARD = "card",
}

export const colorByCurrencyType = {
  [ECurrencyType.CRYPTO]: "#fbbf24",
  [ECurrencyType.CARD]: "#10b981",
  [ECurrencyType.CASH]: "#d4d4d4",
};
