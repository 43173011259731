import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { navigation } from "src/app/routing/routes";
import { useAuthStore } from "src/shared/store/auth-store";

export const Breadcrumps = () => {
  const pathname = useLocation().pathname;
  const { me } = useAuthStore();

  const page = useMemo(() => {
    const definedPage = navigation
      ?.filter((el) => !!me && (!el?.permissions || el.permissions?.includes(me?.user_role_name)))
      ?.find(
        (el) => pathname?.split("/")?.filter((el) => el !== "")[0] === el.path?.replace("/", "")
      );
    if (!!definedPage) {
      return definedPage;
    } else if (
      !!me &&
      (!navigation[0]?.permissions || navigation[0]?.permissions?.includes(me?.user_role_name))
    ) {
      return navigation[0];
    } else {
      return null;
    }
  }, [me, pathname]);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>
          <BreadcrumbLink className="text-[#ffffff77] hover:text-white" href="/">
            Home
          </BreadcrumbLink>
        </BreadcrumbItem>
        {!!page && (
          <>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className="text-white">{page.title}</BreadcrumbPage>
            </BreadcrumbItem>
          </>
        )}
      </BreadcrumbList>
    </Breadcrumb>
  );
};
