import { Dispatch, SetStateAction, useState } from "react";
import { cn } from "@/lib/utils";
import { useWebsiteStore } from "src/shared/store/website-store";
import { ECurrencyType, TCurrency } from "src/shared/types/admin";
import { TCurrencyTypesFilter, TPairs } from "src/shared/types/websites-settings";
import { CurrencyTypesFilter } from "./currency-types-filter";
import { Button } from "@/components/ui/button";
import { Minus, Plus } from "lucide-react";
import { MultiSelect } from "@/components/ui/multi-select";

export const CurrencyColumn = ({
  pairs,
  setPairs,
  directionType,
}: {
  pairs: TPairs;
  setPairs: Dispatch<SetStateAction<TPairs>>;
  directionType: "from" | "to";
}) => {
  const { websiteCurrencies } = useWebsiteStore();
  const [isAll, setIsAll] = useState(false);

  const [currencyFilters, setCurrencyFilters] = useState<TCurrencyTypesFilter>({
    from: [],
    to: [],
  });

  const currenciesOptions =
    websiteCurrencies?.map((el) => ({
      label: el.currency_code,
      value: el.currency_code,
    })) || [];

  const handleSelect = (currency_code: string) => {
    const currentCurrency = websiteCurrencies?.find((el) => el.currency_code === currency_code);
    setPairs((prev) => ({
      ...prev,
      [directionType]: prev[directionType].some((item) => item.currency_code === currency_code)
        ? prev[directionType].filter((item) => item.currency_code !== currency_code)
        : [...prev[directionType], currentCurrency],
    }));
  };

  const handleCurrencyClick = (currency: TCurrency, type: "from" | "to") => {
    setPairs((prev) => ({
      ...prev,
      [type]: prev[type].some((item) => item.currency_code === currency.currency_code)
        ? prev[type].filter((item) => item.currency_code !== currency.currency_code)
        : [...prev[type], currency],
    }));
  };

  const onCurrencyFilterClick = (type: ECurrencyType) => {
    setCurrencyFilters((prev) => ({
      ...prev,
      [directionType]: prev[directionType].includes(type)
        ? prev[directionType].filter((item) => item !== type)
        : [...prev[directionType], type],
    }));
  };

  const getFilteredCurrencies = (type: "from" | "to") => {
    return websiteCurrencies?.filter((item) => {
      if (currencyFilters[type].length === 0) {
        return true;
      }

      return currencyFilters[type].includes(item.currency_type);
    });
  };

  const selectAll = (isSelect: boolean) => {
    setPairs((prev) => ({
      ...prev,
      [directionType]: isSelect ? getFilteredCurrencies(directionType) : [],
    }));
  };

  const setAll = () => {
    if (isAll) {
      setIsAll(false);
      selectAll(false);
    } else {
      setIsAll(true);
      selectAll(true);
    }
  };

  return (
    <div className="w-full flex flex-col gap-1">
      <div className={cn("sticky top-0 flex flex-col gap-1 z-10 px-1 bg-secondary pb-1")}>
        {!!pairs[directionType]?.length && (
          <div className="absolute flex top-1 right-1.5 bg-foreground text-background items-center rounded-full text-[10px] leading-0 font-bold w-fit min-w-4 h-4 px-[5px] z-[30]">
            {pairs[directionType]?.length}
          </div>
        )}
        <MultiSelect
          options={currenciesOptions}
          placeholder={directionType}
          selected={pairs[directionType]?.map((el) => ({
            label: el.currency_code,
            value: el.currency_code,
          }))}
          setFn={handleSelect}
        />
        <Button
          type="button"
          variant={isAll ? "default" : "outline"}
          className="text-neutral-500"
          onClick={setAll}>
          {isAll ? <Minus className="w-4 h-4" /> : <Plus className="w-4 h-4" />}
        </Button>
        <div className="flex gap-1">
          <CurrencyTypesFilter
            currencyFilters={currencyFilters[directionType]}
            onSelect={onCurrencyFilterClick}
          />
        </div>
      </div>
      <div className="flex flex-col gap-1 h-full overflow-y-auto px-1">
        {getFilteredCurrencies(directionType)?.map((currency) => {
          const isSelected = pairs[directionType].some(
            (item) => item.currency_code === currency.currency_code
          );
          return (
            <Button
              key={currency.currency_code}
              variant={isSelected ? "default" : "outline"}
              onClick={() => handleCurrencyClick(currency, directionType)}>
              {currency.currency_code}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
