import { Badge } from "@/components/ui/badge";
import Close from "src/shared/image/close";
import { TWebsite } from "src/shared/types/admin";

export const WebsitesBadges = ({
  websites,
  onDelete,
}: {
  websites: TWebsite[];
  onDelete?: (website_slug: string) => void;
}) => {
  return (
    <div className="flex flex-wrap gap-1 -mx-1">
      {websites?.map((el) => {
        return (
          <Badge
            key={el.website_id}
            variant="secondary"
            className="flex items-center gap-2 text-xs">
            {el.website_name}
            {!!onDelete && (
              <button
                type="button"
                className="w-4 h-4 rounded-full bg-[#ffffff99] text-red-500 -mr-2"
                onClick={() => onDelete(el.website_slug)}>
                <Close />
              </button>
            )}
          </Badge>
        );
      })}
    </div>
  );
};
