export default function Loading() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={40}
      height={40}
      shapeRendering="crispEdges"
      x="0px"
      y="0px"
      viewBox="0 0 96 120">
      <rect x="24" y="6" width="1" height="1" fill="currentColor" />
      <rect x="25" y="6" width="1" height="1" fill="currentColor" />
      <rect x="26" y="6" width="1" height="1" fill="currentColor" />
      <rect x="27" y="6" width="1" height="1" fill="currentColor" />
      <rect x="28" y="6" width="1" height="1" fill="currentColor" />
      <rect x="29" y="6" width="1" height="1" fill="currentColor" />
      <rect x="30" y="6" width="1" height="1" fill="currentColor" />
      <rect x="31" y="6" width="1" height="1" fill="currentColor" />
      <rect x="32" y="6" width="1" height="1" fill="currentColor" />
      <rect x="33" y="6" width="1" height="1" fill="currentColor" />
      <rect x="34" y="6" width="1" height="1" fill="currentColor" />
      <rect x="35" y="6" width="1" height="1" fill="currentColor" />
      <rect x="36" y="6" width="1" height="1" fill="currentColor" />
      <rect x="37" y="6" width="1" height="1" fill="currentColor" />
      <rect x="38" y="6" width="1" height="1" fill="currentColor" />
      <rect x="39" y="6" width="1" height="1" fill="currentColor" />
      <rect x="40" y="6" width="1" height="1" fill="currentColor" />
      <rect x="41" y="6" width="1" height="1" fill="currentColor" />
      <rect x="42" y="6" width="1" height="1" fill="currentColor" />
      <rect x="43" y="6" width="1" height="1" fill="currentColor" />
      <rect x="44" y="6" width="1" height="1" fill="currentColor" />
      <rect x="45" y="6" width="1" height="1" fill="currentColor" />
      <rect x="46" y="6" width="1" height="1" fill="currentColor" />
      <rect x="47" y="6" width="1" height="1" fill="currentColor" />
      <rect x="48" y="6" width="1" height="1" fill="currentColor" />
      <rect x="49" y="6" width="1" height="1" fill="currentColor" />
      <rect x="50" y="6" width="1" height="1" fill="currentColor" />
      <rect x="51" y="6" width="1" height="1" fill="currentColor" />
      <rect x="52" y="6" width="1" height="1" fill="currentColor" />
      <rect x="53" y="6" width="1" height="1" fill="currentColor" />
      <rect x="54" y="6" width="1" height="1" fill="currentColor" />
      <rect x="55" y="6" width="1" height="1" fill="currentColor" />
      <rect x="56" y="6" width="1" height="1" fill="currentColor" />
      <rect x="57" y="6" width="1" height="1" fill="currentColor" />
      <rect x="58" y="6" width="1" height="1" fill="currentColor" />
      <rect x="59" y="6" width="1" height="1" fill="currentColor" />
      <rect x="60" y="6" width="1" height="1" fill="currentColor" />
      <rect x="61" y="6" width="1" height="1" fill="currentColor" />
      <rect x="62" y="6" width="1" height="1" fill="currentColor" />
      <rect x="63" y="6" width="1" height="1" fill="currentColor" />
      <rect x="64" y="6" width="1" height="1" fill="currentColor" />
      <rect x="65" y="6" width="1" height="1" fill="currentColor" />
      <rect x="66" y="6" width="1" height="1" fill="currentColor" />
      <rect x="67" y="6" width="1" height="1" fill="currentColor" />
      <rect x="68" y="6" width="1" height="1" fill="currentColor" />
      <rect x="69" y="6" width="1" height="1" fill="currentColor" />
      <rect x="70" y="6" width="1" height="1" fill="currentColor" />
      <rect x="71" y="6" width="1" height="1" fill="currentColor" />
      <rect x="24" y="7" width="1" height="1" fill="currentColor" />
      <rect x="25" y="7" width="1" height="1" fill="currentColor" />
      <rect x="26" y="7" width="1" height="1" fill="currentColor" />
      <rect x="27" y="7" width="1" height="1" fill="currentColor" />
      <rect x="28" y="7" width="1" height="1" fill="currentColor" />
      <rect x="29" y="7" width="1" height="1" fill="currentColor" />
      <rect x="30" y="7" width="1" height="1" fill="currentColor" />
      <rect x="31" y="7" width="1" height="1" fill="currentColor" />
      <rect x="32" y="7" width="1" height="1" fill="currentColor" />
      <rect x="33" y="7" width="1" height="1" fill="currentColor" />
      <rect x="34" y="7" width="1" height="1" fill="currentColor" />
      <rect x="35" y="7" width="1" height="1" fill="currentColor" />
      <rect x="36" y="7" width="1" height="1" fill="currentColor" />
      <rect x="37" y="7" width="1" height="1" fill="currentColor" />
      <rect x="38" y="7" width="1" height="1" fill="currentColor" />
      <rect x="39" y="7" width="1" height="1" fill="currentColor" />
      <rect x="40" y="7" width="1" height="1" fill="currentColor" />
      <rect x="41" y="7" width="1" height="1" fill="currentColor" />
      <rect x="42" y="7" width="1" height="1" fill="currentColor" />
      <rect x="43" y="7" width="1" height="1" fill="currentColor" />
      <rect x="44" y="7" width="1" height="1" fill="currentColor" />
      <rect x="45" y="7" width="1" height="1" fill="currentColor" />
      <rect x="46" y="7" width="1" height="1" fill="currentColor" />
      <rect x="47" y="7" width="1" height="1" fill="currentColor" />
      <rect x="48" y="7" width="1" height="1" fill="currentColor" />
      <rect x="49" y="7" width="1" height="1" fill="currentColor" />
      <rect x="50" y="7" width="1" height="1" fill="currentColor" />
      <rect x="51" y="7" width="1" height="1" fill="currentColor" />
      <rect x="52" y="7" width="1" height="1" fill="currentColor" />
      <rect x="53" y="7" width="1" height="1" fill="currentColor" />
      <rect x="54" y="7" width="1" height="1" fill="currentColor" />
      <rect x="55" y="7" width="1" height="1" fill="currentColor" />
      <rect x="56" y="7" width="1" height="1" fill="currentColor" />
      <rect x="57" y="7" width="1" height="1" fill="currentColor" />
      <rect x="58" y="7" width="1" height="1" fill="currentColor" />
      <rect x="59" y="7" width="1" height="1" fill="currentColor" />
      <rect x="60" y="7" width="1" height="1" fill="currentColor" />
      <rect x="61" y="7" width="1" height="1" fill="currentColor" />
      <rect x="62" y="7" width="1" height="1" fill="currentColor" />
      <rect x="63" y="7" width="1" height="1" fill="currentColor" />
      <rect x="64" y="7" width="1" height="1" fill="currentColor" />
      <rect x="65" y="7" width="1" height="1" fill="currentColor" />
      <rect x="66" y="7" width="1" height="1" fill="currentColor" />
      <rect x="67" y="7" width="1" height="1" fill="currentColor" />
      <rect x="68" y="7" width="1" height="1" fill="currentColor" />
      <rect x="69" y="7" width="1" height="1" fill="currentColor" />
      <rect x="70" y="7" width="1" height="1" fill="currentColor" />
      <rect x="71" y="7" width="1" height="1" fill="currentColor" />
      <rect x="24" y="8" width="1" height="1" fill="currentColor" />
      <rect x="25" y="8" width="1" height="1" fill="currentColor" />
      <rect x="26" y="8" width="1" height="1" fill="currentColor" />
      <rect x="27" y="8" width="1" height="1" fill="currentColor" />
      <rect x="28" y="8" width="1" height="1" fill="currentColor" />
      <rect x="29" y="8" width="1" height="1" fill="currentColor" />
      <rect x="30" y="8" width="1" height="1" fill="currentColor" />
      <rect x="31" y="8" width="1" height="1" fill="currentColor" />
      <rect x="32" y="8" width="1" height="1" fill="currentColor" />
      <rect x="33" y="8" width="1" height="1" fill="currentColor" />
      <rect x="34" y="8" width="1" height="1" fill="currentColor" />
      <rect x="35" y="8" width="1" height="1" fill="currentColor" />
      <rect x="36" y="8" width="1" height="1" fill="currentColor" />
      <rect x="37" y="8" width="1" height="1" fill="currentColor" />
      <rect x="38" y="8" width="1" height="1" fill="currentColor" />
      <rect x="39" y="8" width="1" height="1" fill="currentColor" />
      <rect x="40" y="8" width="1" height="1" fill="currentColor" />
      <rect x="41" y="8" width="1" height="1" fill="currentColor" />
      <rect x="42" y="8" width="1" height="1" fill="currentColor" />
      <rect x="43" y="8" width="1" height="1" fill="currentColor" />
      <rect x="44" y="8" width="1" height="1" fill="currentColor" />
      <rect x="45" y="8" width="1" height="1" fill="currentColor" />
      <rect x="46" y="8" width="1" height="1" fill="currentColor" />
      <rect x="47" y="8" width="1" height="1" fill="currentColor" />
      <rect x="48" y="8" width="1" height="1" fill="currentColor" />
      <rect x="49" y="8" width="1" height="1" fill="currentColor" />
      <rect x="50" y="8" width="1" height="1" fill="currentColor" />
      <rect x="51" y="8" width="1" height="1" fill="currentColor" />
      <rect x="52" y="8" width="1" height="1" fill="currentColor" />
      <rect x="53" y="8" width="1" height="1" fill="currentColor" />
      <rect x="54" y="8" width="1" height="1" fill="currentColor" />
      <rect x="55" y="8" width="1" height="1" fill="currentColor" />
      <rect x="56" y="8" width="1" height="1" fill="currentColor" />
      <rect x="57" y="8" width="1" height="1" fill="currentColor" />
      <rect x="58" y="8" width="1" height="1" fill="currentColor" />
      <rect x="59" y="8" width="1" height="1" fill="currentColor" />
      <rect x="60" y="8" width="1" height="1" fill="currentColor" />
      <rect x="61" y="8" width="1" height="1" fill="currentColor" />
      <rect x="62" y="8" width="1" height="1" fill="currentColor" />
      <rect x="63" y="8" width="1" height="1" fill="currentColor" />
      <rect x="64" y="8" width="1" height="1" fill="currentColor" />
      <rect x="65" y="8" width="1" height="1" fill="currentColor" />
      <rect x="66" y="8" width="1" height="1" fill="currentColor" />
      <rect x="67" y="8" width="1" height="1" fill="currentColor" />
      <rect x="68" y="8" width="1" height="1" fill="currentColor" />
      <rect x="69" y="8" width="1" height="1" fill="currentColor" />
      <rect x="70" y="8" width="1" height="1" fill="currentColor" />
      <rect x="71" y="8" width="1" height="1" fill="currentColor" />
      <rect x="21" y="9" width="1" height="1" fill="currentColor" />
      <rect x="22" y="9" width="1" height="1" fill="currentColor" />
      <rect x="23" y="9" width="1" height="1" fill="currentColor" />
      <rect x="72" y="9" width="1" height="1" fill="currentColor" />
      <rect x="73" y="9" width="1" height="1" fill="currentColor" />
      <rect x="74" y="9" width="1" height="1" fill="currentColor" />
      <rect x="21" y="10" width="1" height="1" fill="currentColor" />
      <rect x="22" y="10" width="1" height="1" fill="currentColor" />
      <rect x="23" y="10" width="1" height="1" fill="currentColor" />
      <rect x="72" y="10" width="1" height="1" fill="currentColor" />
      <rect x="73" y="10" width="1" height="1" fill="currentColor" />
      <rect x="74" y="10" width="1" height="1" fill="currentColor" />
      <rect x="21" y="11" width="1" height="1" fill="currentColor" />
      <rect x="22" y="11" width="1" height="1" fill="currentColor" />
      <rect x="23" y="11" width="1" height="1" fill="currentColor" />
      <rect x="72" y="11" width="1" height="1" fill="currentColor" />
      <rect x="73" y="11" width="1" height="1" fill="currentColor" />
      <rect x="74" y="11" width="1" height="1" fill="currentColor" />
      <rect x="21" y="12" width="1" height="1" fill="currentColor" />
      <rect x="22" y="12" width="1" height="1" fill="currentColor" />
      <rect x="23" y="12" width="1" height="1" fill="currentColor" />
      <rect x="72" y="12" width="1" height="1" fill="currentColor" />
      <rect x="73" y="12" width="1" height="1" fill="currentColor" />
      <rect x="74" y="12" width="1" height="1" fill="currentColor" />
      <rect x="21" y="13" width="1" height="1" fill="currentColor" />
      <rect x="22" y="13" width="1" height="1" fill="currentColor" />
      <rect x="23" y="13" width="1" height="1" fill="currentColor" />
      <rect x="72" y="13" width="1" height="1" fill="currentColor" />
      <rect x="73" y="13" width="1" height="1" fill="currentColor" />
      <rect x="74" y="13" width="1" height="1" fill="currentColor" />
      <rect x="21" y="14" width="1" height="1" fill="currentColor" />
      <rect x="22" y="14" width="1" height="1" fill="currentColor" />
      <rect x="23" y="14" width="1" height="1" fill="currentColor" />
      <rect x="72" y="14" width="1" height="1" fill="currentColor" />
      <rect x="73" y="14" width="1" height="1" fill="currentColor" />
      <rect x="74" y="14" width="1" height="1" fill="currentColor" />
      <rect x="21" y="15" width="1" height="1" fill="currentColor" />
      <rect x="22" y="15" width="1" height="1" fill="currentColor" />
      <rect x="23" y="15" width="1" height="1" fill="currentColor" />
      <rect x="72" y="15" width="1" height="1" fill="currentColor" />
      <rect x="73" y="15" width="1" height="1" fill="currentColor" />
      <rect x="74" y="15" width="1" height="1" fill="currentColor" />
      <rect x="21" y="16" width="1" height="1" fill="currentColor" />
      <rect x="22" y="16" width="1" height="1" fill="currentColor" />
      <rect x="23" y="16" width="1" height="1" fill="currentColor" />
      <rect x="72" y="16" width="1" height="1" fill="currentColor" />
      <rect x="73" y="16" width="1" height="1" fill="currentColor" />
      <rect x="74" y="16" width="1" height="1" fill="currentColor" />
      <rect x="21" y="17" width="1" height="1" fill="currentColor" />
      <rect x="22" y="17" width="1" height="1" fill="currentColor" />
      <rect x="23" y="17" width="1" height="1" fill="currentColor" />
      <rect x="72" y="17" width="1" height="1" fill="currentColor" />
      <rect x="73" y="17" width="1" height="1" fill="currentColor" />
      <rect x="74" y="17" width="1" height="1" fill="currentColor" />
      <rect x="24" y="18" width="1" height="1" fill="currentColor" />
      <rect x="25" y="18" width="1" height="1" fill="currentColor" />
      <rect x="26" y="18" width="1" height="1" fill="currentColor" />
      <rect x="69" y="18" width="1" height="1" fill="currentColor" />
      <rect x="70" y="18" width="1" height="1" fill="currentColor" />
      <rect x="71" y="18" width="1" height="1" fill="currentColor" />
      <rect x="24" y="19" width="1" height="1" fill="currentColor" />
      <rect x="25" y="19" width="1" height="1" fill="currentColor" />
      <rect x="26" y="19" width="1" height="1" fill="currentColor" />
      <rect x="69" y="19" width="1" height="1" fill="currentColor" />
      <rect x="70" y="19" width="1" height="1" fill="currentColor" />
      <rect x="71" y="19" width="1" height="1" fill="currentColor" />
      <rect x="24" y="20" width="1" height="1" fill="currentColor" />
      <rect x="25" y="20" width="1" height="1" fill="currentColor" />
      <rect x="26" y="20" width="1" height="1" fill="currentColor" />
      <rect x="69" y="20" width="1" height="1" fill="currentColor" />
      <rect x="70" y="20" width="1" height="1" fill="currentColor" />
      <rect x="71" y="20" width="1" height="1" fill="currentColor" />
      <rect x="24" y="21" width="1" height="1" fill="currentColor" />
      <rect x="25" y="21" width="1" height="1" fill="currentColor" />
      <rect x="26" y="21" width="1" height="1" fill="currentColor" />
      <rect x="69" y="21" width="1" height="1" fill="currentColor" />
      <rect x="70" y="21" width="1" height="1" fill="currentColor" />
      <rect x="71" y="21" width="1" height="1" fill="currentColor" />
      <rect x="24" y="22" width="1" height="1" fill="currentColor" />
      <rect x="25" y="22" width="1" height="1" fill="currentColor" />
      <rect x="26" y="22" width="1" height="1" fill="currentColor" />
      <rect x="69" y="22" width="1" height="1" fill="currentColor" />
      <rect x="70" y="22" width="1" height="1" fill="currentColor" />
      <rect x="71" y="22" width="1" height="1" fill="currentColor" />
      <rect x="24" y="23" width="1" height="1" fill="currentColor" />
      <rect x="25" y="23" width="1" height="1" fill="currentColor" />
      <rect x="26" y="23" width="1" height="1" fill="currentColor" />
      <rect x="69" y="23" width="1" height="1" fill="currentColor" />
      <rect x="70" y="23" width="1" height="1" fill="currentColor" />
      <rect x="71" y="23" width="1" height="1" fill="currentColor" />
      <rect x="27" y="24" width="1" height="1" fill="currentColor" />
      <rect x="28" y="24" width="1" height="1" fill="currentColor" />
      <rect x="29" y="24" width="1" height="1" fill="currentColor" />
      <rect x="66" y="24" width="1" height="1" fill="currentColor" />
      <rect x="67" y="24" width="1" height="1" fill="currentColor" />
      <rect x="68" y="24" width="1" height="1" fill="currentColor" />
      <rect x="27" y="25" width="1" height="1" fill="currentColor" />
      <rect x="28" y="25" width="1" height="1" fill="currentColor" />
      <rect x="29" y="25" width="1" height="1" fill="currentColor" />
      <rect x="66" y="25" width="1" height="1" fill="currentColor" />
      <rect x="67" y="25" width="1" height="1" fill="currentColor" />
      <rect x="68" y="25" width="1" height="1" fill="currentColor" />
      <rect x="27" y="26" width="1" height="1" fill="currentColor" />
      <rect x="28" y="26" width="1" height="1" fill="currentColor" />
      <rect x="29" y="26" width="1" height="1" fill="currentColor" />
      <rect x="66" y="26" width="1" height="1" fill="currentColor" />
      <rect x="67" y="26" width="1" height="1" fill="currentColor" />
      <rect x="68" y="26" width="1" height="1" fill="currentColor" />
      <rect x="27" y="27" width="1" height="1" fill="currentColor" />
      <rect x="28" y="27" width="1" height="1" fill="currentColor" />
      <rect x="29" y="27" width="1" height="1" fill="currentColor" />
      <rect x="66" y="27" width="1" height="1" fill="currentColor" />
      <rect x="67" y="27" width="1" height="1" fill="currentColor" />
      <rect x="68" y="27" width="1" height="1" fill="currentColor" />
      <rect x="27" y="28" width="1" height="1" fill="currentColor" />
      <rect x="28" y="28" width="1" height="1" fill="currentColor" />
      <rect x="29" y="28" width="1" height="1" fill="currentColor" />
      <rect x="66" y="28" width="1" height="1" fill="currentColor" />
      <rect x="67" y="28" width="1" height="1" fill="currentColor" />
      <rect x="68" y="28" width="1" height="1" fill="currentColor" />
      <rect x="27" y="29" width="1" height="1" fill="currentColor" />
      <rect x="28" y="29" width="1" height="1" fill="currentColor" />
      <rect x="29" y="29" width="1" height="1" fill="currentColor" />
      <rect x="66" y="29" width="1" height="1" fill="currentColor" />
      <rect x="67" y="29" width="1" height="1" fill="currentColor" />
      <rect x="68" y="29" width="1" height="1" fill="currentColor" />
      <rect x="30" y="30" width="1" height="1" fill="currentColor" />
      <rect x="31" y="30" width="1" height="1" fill="currentColor" />
      <rect x="32" y="30" width="1" height="1" fill="currentColor" />
      <rect x="36" y="30" width="1" height="1" fill="currentColor" />
      <rect x="37" y="30" width="1" height="1" fill="currentColor" />
      <rect x="38" y="30" width="1" height="1" fill="currentColor" />
      <rect x="39" y="30" width="1" height="1" fill="currentColor" />
      <rect x="40" y="30" width="1" height="1" fill="currentColor" />
      <rect x="41" y="30" width="1" height="1" fill="currentColor" />
      <rect x="42" y="30" width="1" height="1" fill="currentColor" />
      <rect x="43" y="30" width="1" height="1" fill="currentColor" />
      <rect x="44" y="30" width="1" height="1" fill="currentColor" />
      <rect x="45" y="30" width="1" height="1" fill="currentColor" />
      <rect x="46" y="30" width="1" height="1" fill="currentColor" />
      <rect x="47" y="30" width="1" height="1" fill="currentColor" />
      <rect x="48" y="30" width="1" height="1" fill="currentColor" />
      <rect x="49" y="30" width="1" height="1" fill="currentColor" />
      <rect x="50" y="30" width="1" height="1" fill="currentColor" />
      <rect x="51" y="30" width="1" height="1" fill="currentColor" />
      <rect x="52" y="30" width="1" height="1" fill="currentColor" />
      <rect x="53" y="30" width="1" height="1" fill="currentColor" />
      <rect x="54" y="30" width="1" height="1" fill="currentColor" />
      <rect x="55" y="30" width="1" height="1" fill="currentColor" />
      <rect x="56" y="30" width="1" height="1" fill="currentColor" />
      <rect x="57" y="30" width="1" height="1" fill="currentColor" />
      <rect x="58" y="30" width="1" height="1" fill="currentColor" />
      <rect x="59" y="30" width="1" height="1" fill="currentColor" />
      <rect x="63" y="30" width="1" height="1" fill="currentColor" />
      <rect x="64" y="30" width="1" height="1" fill="currentColor" />
      <rect x="65" y="30" width="1" height="1" fill="currentColor" />
      <rect x="30" y="31" width="1" height="1" fill="currentColor" />
      <rect x="31" y="31" width="1" height="1" fill="currentColor" />
      <rect x="32" y="31" width="1" height="1" fill="currentColor" />
      <rect x="36" y="31" width="1" height="1" fill="currentColor" />
      <rect x="37" y="31" width="1" height="1" fill="currentColor" />
      <rect x="38" y="31" width="1" height="1" fill="currentColor" />
      <rect x="39" y="31" width="1" height="1" fill="currentColor" />
      <rect x="40" y="31" width="1" height="1" fill="currentColor" />
      <rect x="41" y="31" width="1" height="1" fill="currentColor" />
      <rect x="42" y="31" width="1" height="1" fill="currentColor" />
      <rect x="43" y="31" width="1" height="1" fill="currentColor" />
      <rect x="44" y="31" width="1" height="1" fill="currentColor" />
      <rect x="45" y="31" width="1" height="1" fill="currentColor" />
      <rect x="46" y="31" width="1" height="1" fill="currentColor" />
      <rect x="47" y="31" width="1" height="1" fill="currentColor" />
      <rect x="48" y="31" width="1" height="1" fill="currentColor" />
      <rect x="49" y="31" width="1" height="1" fill="currentColor" />
      <rect x="50" y="31" width="1" height="1" fill="currentColor" />
      <rect x="51" y="31" width="1" height="1" fill="currentColor" />
      <rect x="52" y="31" width="1" height="1" fill="currentColor" />
      <rect x="53" y="31" width="1" height="1" fill="currentColor" />
      <rect x="54" y="31" width="1" height="1" fill="currentColor" />
      <rect x="55" y="31" width="1" height="1" fill="currentColor" />
      <rect x="56" y="31" width="1" height="1" fill="currentColor" />
      <rect x="57" y="31" width="1" height="1" fill="currentColor" />
      <rect x="58" y="31" width="1" height="1" fill="currentColor" />
      <rect x="59" y="31" width="1" height="1" fill="currentColor" />
      <rect x="63" y="31" width="1" height="1" fill="currentColor" />
      <rect x="64" y="31" width="1" height="1" fill="currentColor" />
      <rect x="65" y="31" width="1" height="1" fill="currentColor" />
      <rect x="30" y="32" width="1" height="1" fill="currentColor" />
      <rect x="31" y="32" width="1" height="1" fill="currentColor" />
      <rect x="32" y="32" width="1" height="1" fill="currentColor" />
      <rect x="36" y="32" width="1" height="1" fill="currentColor" />
      <rect x="37" y="32" width="1" height="1" fill="currentColor" />
      <rect x="38" y="32" width="1" height="1" fill="currentColor" />
      <rect x="39" y="32" width="1" height="1" fill="currentColor" />
      <rect x="40" y="32" width="1" height="1" fill="currentColor" />
      <rect x="41" y="32" width="1" height="1" fill="currentColor" />
      <rect x="42" y="32" width="1" height="1" fill="currentColor" />
      <rect x="43" y="32" width="1" height="1" fill="currentColor" />
      <rect x="44" y="32" width="1" height="1" fill="currentColor" />
      <rect x="45" y="32" width="1" height="1" fill="currentColor" />
      <rect x="46" y="32" width="1" height="1" fill="currentColor" />
      <rect x="47" y="32" width="1" height="1" fill="currentColor" />
      <rect x="48" y="32" width="1" height="1" fill="currentColor" />
      <rect x="49" y="32" width="1" height="1" fill="currentColor" />
      <rect x="50" y="32" width="1" height="1" fill="currentColor" />
      <rect x="51" y="32" width="1" height="1" fill="currentColor" />
      <rect x="52" y="32" width="1" height="1" fill="currentColor" />
      <rect x="53" y="32" width="1" height="1" fill="currentColor" />
      <rect x="54" y="32" width="1" height="1" fill="currentColor" />
      <rect x="55" y="32" width="1" height="1" fill="currentColor" />
      <rect x="56" y="32" width="1" height="1" fill="currentColor" />
      <rect x="57" y="32" width="1" height="1" fill="currentColor" />
      <rect x="58" y="32" width="1" height="1" fill="currentColor" />
      <rect x="59" y="32" width="1" height="1" fill="currentColor" />
      <rect x="63" y="32" width="1" height="1" fill="currentColor" />
      <rect x="64" y="32" width="1" height="1" fill="currentColor" />
      <rect x="65" y="32" width="1" height="1" fill="currentColor" />
      <rect x="33" y="33" width="1" height="1" fill="currentColor" />
      <rect x="34" y="33" width="1" height="1" fill="currentColor" />
      <rect x="35" y="33" width="1" height="1" fill="currentColor" />
      <rect x="39" y="33" width="1" height="1" fill="currentColor" />
      <rect x="40" y="33" width="1" height="1" fill="currentColor" />
      <rect x="41" y="33" width="1" height="1" fill="currentColor" />
      <rect x="42" y="33" width="1" height="1" fill="currentColor" />
      <rect x="43" y="33" width="1" height="1" fill="currentColor" />
      <rect x="44" y="33" width="1" height="1" fill="currentColor" />
      <rect x="45" y="33" width="1" height="1" fill="currentColor" />
      <rect x="46" y="33" width="1" height="1" fill="currentColor" />
      <rect x="47" y="33" width="1" height="1" fill="currentColor" />
      <rect x="48" y="33" width="1" height="1" fill="currentColor" />
      <rect x="49" y="33" width="1" height="1" fill="currentColor" />
      <rect x="50" y="33" width="1" height="1" fill="currentColor" />
      <rect x="51" y="33" width="1" height="1" fill="currentColor" />
      <rect x="52" y="33" width="1" height="1" fill="currentColor" />
      <rect x="53" y="33" width="1" height="1" fill="currentColor" />
      <rect x="54" y="33" width="1" height="1" fill="currentColor" />
      <rect x="55" y="33" width="1" height="1" fill="currentColor" />
      <rect x="56" y="33" width="1" height="1" fill="currentColor" />
      <rect x="60" y="33" width="1" height="1" fill="currentColor" />
      <rect x="61" y="33" width="1" height="1" fill="currentColor" />
      <rect x="62" y="33" width="1" height="1" fill="currentColor" />
      <rect x="33" y="34" width="1" height="1" fill="currentColor" />
      <rect x="34" y="34" width="1" height="1" fill="currentColor" />
      <rect x="35" y="34" width="1" height="1" fill="currentColor" />
      <rect x="39" y="34" width="1" height="1" fill="currentColor" />
      <rect x="40" y="34" width="1" height="1" fill="currentColor" />
      <rect x="41" y="34" width="1" height="1" fill="currentColor" />
      <rect x="42" y="34" width="1" height="1" fill="currentColor" />
      <rect x="43" y="34" width="1" height="1" fill="currentColor" />
      <rect x="44" y="34" width="1" height="1" fill="currentColor" />
      <rect x="45" y="34" width="1" height="1" fill="currentColor" />
      <rect x="46" y="34" width="1" height="1" fill="currentColor" />
      <rect x="47" y="34" width="1" height="1" fill="currentColor" />
      <rect x="48" y="34" width="1" height="1" fill="currentColor" />
      <rect x="49" y="34" width="1" height="1" fill="currentColor" />
      <rect x="50" y="34" width="1" height="1" fill="currentColor" />
      <rect x="51" y="34" width="1" height="1" fill="currentColor" />
      <rect x="52" y="34" width="1" height="1" fill="currentColor" />
      <rect x="53" y="34" width="1" height="1" fill="currentColor" />
      <rect x="54" y="34" width="1" height="1" fill="currentColor" />
      <rect x="55" y="34" width="1" height="1" fill="currentColor" />
      <rect x="56" y="34" width="1" height="1" fill="currentColor" />
      <rect x="60" y="34" width="1" height="1" fill="currentColor" />
      <rect x="61" y="34" width="1" height="1" fill="currentColor" />
      <rect x="62" y="34" width="1" height="1" fill="currentColor" />
      <rect x="33" y="35" width="1" height="1" fill="currentColor" />
      <rect x="34" y="35" width="1" height="1" fill="currentColor" />
      <rect x="35" y="35" width="1" height="1" fill="currentColor" />
      <rect x="39" y="35" width="1" height="1" fill="currentColor" />
      <rect x="40" y="35" width="1" height="1" fill="currentColor" />
      <rect x="41" y="35" width="1" height="1" fill="currentColor" />
      <rect x="42" y="35" width="1" height="1" fill="currentColor" />
      <rect x="43" y="35" width="1" height="1" fill="currentColor" />
      <rect x="44" y="35" width="1" height="1" fill="currentColor" />
      <rect x="45" y="35" width="1" height="1" fill="currentColor" />
      <rect x="46" y="35" width="1" height="1" fill="currentColor" />
      <rect x="47" y="35" width="1" height="1" fill="currentColor" />
      <rect x="48" y="35" width="1" height="1" fill="currentColor" />
      <rect x="49" y="35" width="1" height="1" fill="currentColor" />
      <rect x="50" y="35" width="1" height="1" fill="currentColor" />
      <rect x="51" y="35" width="1" height="1" fill="currentColor" />
      <rect x="52" y="35" width="1" height="1" fill="currentColor" />
      <rect x="53" y="35" width="1" height="1" fill="currentColor" />
      <rect x="54" y="35" width="1" height="1" fill="currentColor" />
      <rect x="55" y="35" width="1" height="1" fill="currentColor" />
      <rect x="56" y="35" width="1" height="1" fill="currentColor" />
      <rect x="60" y="35" width="1" height="1" fill="currentColor" />
      <rect x="61" y="35" width="1" height="1" fill="currentColor" />
      <rect x="62" y="35" width="1" height="1" fill="currentColor" />
      <rect x="36" y="36" width="1" height="1" fill="currentColor" />
      <rect x="37" y="36" width="1" height="1" fill="currentColor" />
      <rect x="38" y="36" width="1" height="1" fill="currentColor" />
      <rect x="42" y="36" width="1" height="1" fill="currentColor" />
      <rect x="43" y="36" width="1" height="1" fill="currentColor" />
      <rect x="44" y="36" width="1" height="1" fill="currentColor" />
      <rect x="45" y="36" width="1" height="1" fill="currentColor" />
      <rect x="46" y="36" width="1" height="1" fill="currentColor" />
      <rect x="47" y="36" width="1" height="1" fill="currentColor" />
      <rect x="48" y="36" width="1" height="1" fill="currentColor" />
      <rect x="49" y="36" width="1" height="1" fill="currentColor" />
      <rect x="50" y="36" width="1" height="1" fill="currentColor" />
      <rect x="57" y="36" width="1" height="1" fill="currentColor" />
      <rect x="58" y="36" width="1" height="1" fill="currentColor" />
      <rect x="59" y="36" width="1" height="1" fill="currentColor" />
      <rect x="36" y="37" width="1" height="1" fill="currentColor" />
      <rect x="37" y="37" width="1" height="1" fill="currentColor" />
      <rect x="38" y="37" width="1" height="1" fill="currentColor" />
      <rect x="42" y="37" width="1" height="1" fill="currentColor" />
      <rect x="43" y="37" width="1" height="1" fill="currentColor" />
      <rect x="44" y="37" width="1" height="1" fill="currentColor" />
      <rect x="45" y="37" width="1" height="1" fill="currentColor" />
      <rect x="46" y="37" width="1" height="1" fill="currentColor" />
      <rect x="47" y="37" width="1" height="1" fill="currentColor" />
      <rect x="48" y="37" width="1" height="1" fill="currentColor" />
      <rect x="49" y="37" width="1" height="1" fill="currentColor" />
      <rect x="50" y="37" width="1" height="1" fill="currentColor" />
      <rect x="57" y="37" width="1" height="1" fill="currentColor" />
      <rect x="58" y="37" width="1" height="1" fill="currentColor" />
      <rect x="59" y="37" width="1" height="1" fill="currentColor" />
      <rect x="36" y="38" width="1" height="1" fill="currentColor" />
      <rect x="37" y="38" width="1" height="1" fill="currentColor" />
      <rect x="38" y="38" width="1" height="1" fill="currentColor" />
      <rect x="42" y="38" width="1" height="1" fill="currentColor" />
      <rect x="43" y="38" width="1" height="1" fill="currentColor" />
      <rect x="44" y="38" width="1" height="1" fill="currentColor" />
      <rect x="45" y="38" width="1" height="1" fill="currentColor" />
      <rect x="46" y="38" width="1" height="1" fill="currentColor" />
      <rect x="47" y="38" width="1" height="1" fill="currentColor" />
      <rect x="48" y="38" width="1" height="1" fill="currentColor" />
      <rect x="49" y="38" width="1" height="1" fill="currentColor" />
      <rect x="50" y="38" width="1" height="1" fill="currentColor" />
      <rect x="57" y="38" width="1" height="1" fill="currentColor" />
      <rect x="58" y="38" width="1" height="1" fill="currentColor" />
      <rect x="59" y="38" width="1" height="1" fill="currentColor" />
      <rect x="39" y="39" width="1" height="1" fill="currentColor" />
      <rect x="40" y="39" width="1" height="1" fill="currentColor" />
      <rect x="41" y="39" width="1" height="1" fill="currentColor" />
      <rect x="45" y="39" width="1" height="1" fill="currentColor" />
      <rect x="46" y="39" width="1" height="1" fill="currentColor" />
      <rect x="47" y="39" width="1" height="1" fill="currentColor" />
      <rect x="54" y="39" width="1" height="1" fill="currentColor" />
      <rect x="55" y="39" width="1" height="1" fill="currentColor" />
      <rect x="56" y="39" width="1" height="1" fill="currentColor" />
      <rect x="39" y="40" width="1" height="1" fill="currentColor" />
      <rect x="40" y="40" width="1" height="1" fill="currentColor" />
      <rect x="41" y="40" width="1" height="1" fill="currentColor" />
      <rect x="45" y="40" width="1" height="1" fill="currentColor" />
      <rect x="46" y="40" width="1" height="1" fill="currentColor" />
      <rect x="47" y="40" width="1" height="1" fill="currentColor" />
      <rect x="54" y="40" width="1" height="1" fill="currentColor" />
      <rect x="55" y="40" width="1" height="1" fill="currentColor" />
      <rect x="56" y="40" width="1" height="1" fill="currentColor" />
      <rect x="39" y="41" width="1" height="1" fill="currentColor" />
      <rect x="40" y="41" width="1" height="1" fill="currentColor" />
      <rect x="41" y="41" width="1" height="1" fill="currentColor" />
      <rect x="45" y="41" width="1" height="1" fill="currentColor" />
      <rect x="46" y="41" width="1" height="1" fill="currentColor" />
      <rect x="47" y="41" width="1" height="1" fill="currentColor" />
      <rect x="54" y="41" width="1" height="1" fill="currentColor" />
      <rect x="55" y="41" width="1" height="1" fill="currentColor" />
      <rect x="56" y="41" width="1" height="1" fill="currentColor" />
      <rect x="39" y="42" width="1" height="1" fill="currentColor" />
      <rect x="40" y="42" width="1" height="1" fill="currentColor" />
      <rect x="41" y="42" width="1" height="1" fill="currentColor" />
      <rect x="45" y="42" width="1" height="1" fill="currentColor" />
      <rect x="46" y="42" width="1" height="1" fill="currentColor" />
      <rect x="47" y="42" width="1" height="1" fill="currentColor" />
      <rect x="54" y="42" width="1" height="1" fill="currentColor" />
      <rect x="55" y="42" width="1" height="1" fill="currentColor" />
      <rect x="56" y="42" width="1" height="1" fill="currentColor" />
      <rect x="39" y="43" width="1" height="1" fill="currentColor" />
      <rect x="40" y="43" width="1" height="1" fill="currentColor" />
      <rect x="41" y="43" width="1" height="1" fill="currentColor" />
      <rect x="45" y="43" width="1" height="1" fill="currentColor" />
      <rect x="46" y="43" width="1" height="1" fill="currentColor" />
      <rect x="47" y="43" width="1" height="1" fill="currentColor" />
      <rect x="54" y="43" width="1" height="1" fill="currentColor" />
      <rect x="55" y="43" width="1" height="1" fill="currentColor" />
      <rect x="56" y="43" width="1" height="1" fill="currentColor" />
      <rect x="39" y="44" width="1" height="1" fill="currentColor" />
      <rect x="40" y="44" width="1" height="1" fill="currentColor" />
      <rect x="41" y="44" width="1" height="1" fill="currentColor" />
      <rect x="45" y="44" width="1" height="1" fill="currentColor" />
      <rect x="46" y="44" width="1" height="1" fill="currentColor" />
      <rect x="47" y="44" width="1" height="1" fill="currentColor" />
      <rect x="54" y="44" width="1" height="1" fill="currentColor" />
      <rect x="55" y="44" width="1" height="1" fill="currentColor" />
      <rect x="56" y="44" width="1" height="1" fill="currentColor" />
      <rect x="39" y="45" width="1" height="1" fill="currentColor" />
      <rect x="40" y="45" width="1" height="1" fill="currentColor" />
      <rect x="41" y="45" width="1" height="1" fill="currentColor" />
      <rect x="54" y="45" width="1" height="1" fill="currentColor" />
      <rect x="55" y="45" width="1" height="1" fill="currentColor" />
      <rect x="56" y="45" width="1" height="1" fill="currentColor" />
      <rect x="39" y="46" width="1" height="1" fill="currentColor" />
      <rect x="40" y="46" width="1" height="1" fill="currentColor" />
      <rect x="41" y="46" width="1" height="1" fill="currentColor" />
      <rect x="54" y="46" width="1" height="1" fill="currentColor" />
      <rect x="55" y="46" width="1" height="1" fill="currentColor" />
      <rect x="56" y="46" width="1" height="1" fill="currentColor" />
      <rect x="39" y="47" width="1" height="1" fill="currentColor" />
      <rect x="40" y="47" width="1" height="1" fill="currentColor" />
      <rect x="41" y="47" width="1" height="1" fill="currentColor" />
      <rect x="54" y="47" width="1" height="1" fill="currentColor" />
      <rect x="55" y="47" width="1" height="1" fill="currentColor" />
      <rect x="56" y="47" width="1" height="1" fill="currentColor" />
      <rect x="39" y="48" width="1" height="1" fill="currentColor" />
      <rect x="40" y="48" width="1" height="1" fill="currentColor" />
      <rect x="41" y="48" width="1" height="1" fill="currentColor" />
      <rect x="45" y="48" width="1" height="1" fill="currentColor" />
      <rect x="46" y="48" width="1" height="1" fill="currentColor" />
      <rect x="47" y="48" width="1" height="1" fill="currentColor" />
      <rect x="54" y="48" width="1" height="1" fill="currentColor" />
      <rect x="55" y="48" width="1" height="1" fill="currentColor" />
      <rect x="56" y="48" width="1" height="1" fill="currentColor" />
      <rect x="39" y="49" width="1" height="1" fill="currentColor" />
      <rect x="40" y="49" width="1" height="1" fill="currentColor" />
      <rect x="41" y="49" width="1" height="1" fill="currentColor" />
      <rect x="45" y="49" width="1" height="1" fill="currentColor" />
      <rect x="46" y="49" width="1" height="1" fill="currentColor" />
      <rect x="47" y="49" width="1" height="1" fill="currentColor" />
      <rect x="54" y="49" width="1" height="1" fill="currentColor" />
      <rect x="55" y="49" width="1" height="1" fill="currentColor" />
      <rect x="56" y="49" width="1" height="1" fill="currentColor" />
      <rect x="39" y="50" width="1" height="1" fill="currentColor" />
      <rect x="40" y="50" width="1" height="1" fill="currentColor" />
      <rect x="41" y="50" width="1" height="1" fill="currentColor" />
      <rect x="45" y="50" width="1" height="1" fill="currentColor" />
      <rect x="46" y="50" width="1" height="1" fill="currentColor" />
      <rect x="47" y="50" width="1" height="1" fill="currentColor" />
      <rect x="54" y="50" width="1" height="1" fill="currentColor" />
      <rect x="55" y="50" width="1" height="1" fill="currentColor" />
      <rect x="56" y="50" width="1" height="1" fill="currentColor" />
      <rect x="39" y="51" width="1" height="1" fill="currentColor" />
      <rect x="40" y="51" width="1" height="1" fill="currentColor" />
      <rect x="41" y="51" width="1" height="1" fill="currentColor" />
      <rect x="54" y="51" width="1" height="1" fill="currentColor" />
      <rect x="55" y="51" width="1" height="1" fill="currentColor" />
      <rect x="56" y="51" width="1" height="1" fill="currentColor" />
      <rect x="39" y="52" width="1" height="1" fill="currentColor" />
      <rect x="40" y="52" width="1" height="1" fill="currentColor" />
      <rect x="41" y="52" width="1" height="1" fill="currentColor" />
      <rect x="54" y="52" width="1" height="1" fill="currentColor" />
      <rect x="55" y="52" width="1" height="1" fill="currentColor" />
      <rect x="56" y="52" width="1" height="1" fill="currentColor" />
      <rect x="39" y="53" width="1" height="1" fill="currentColor" />
      <rect x="40" y="53" width="1" height="1" fill="currentColor" />
      <rect x="41" y="53" width="1" height="1" fill="currentColor" />
      <rect x="54" y="53" width="1" height="1" fill="currentColor" />
      <rect x="55" y="53" width="1" height="1" fill="currentColor" />
      <rect x="56" y="53" width="1" height="1" fill="currentColor" />
      <rect x="39" y="54" width="1" height="1" fill="currentColor" />
      <rect x="40" y="54" width="1" height="1" fill="currentColor" />
      <rect x="41" y="54" width="1" height="1" fill="currentColor" />
      <rect x="54" y="54" width="1" height="1" fill="currentColor" />
      <rect x="55" y="54" width="1" height="1" fill="currentColor" />
      <rect x="56" y="54" width="1" height="1" fill="currentColor" />
      <rect x="39" y="55" width="1" height="1" fill="currentColor" />
      <rect x="40" y="55" width="1" height="1" fill="currentColor" />
      <rect x="41" y="55" width="1" height="1" fill="currentColor" />
      <rect x="54" y="55" width="1" height="1" fill="currentColor" />
      <rect x="55" y="55" width="1" height="1" fill="currentColor" />
      <rect x="56" y="55" width="1" height="1" fill="currentColor" />
      <rect x="39" y="56" width="1" height="1" fill="currentColor" />
      <rect x="40" y="56" width="1" height="1" fill="currentColor" />
      <rect x="41" y="56" width="1" height="1" fill="currentColor" />
      <rect x="54" y="56" width="1" height="1" fill="currentColor" />
      <rect x="55" y="56" width="1" height="1" fill="currentColor" />
      <rect x="56" y="56" width="1" height="1" fill="currentColor" />
      <rect x="36" y="57" width="1" height="1" fill="currentColor" />
      <rect x="37" y="57" width="1" height="1" fill="currentColor" />
      <rect x="38" y="57" width="1" height="1" fill="currentColor" />
      <rect x="45" y="57" width="1" height="1" fill="currentColor" />
      <rect x="46" y="57" width="1" height="1" fill="currentColor" />
      <rect x="47" y="57" width="1" height="1" fill="currentColor" />
      <rect x="57" y="57" width="1" height="1" fill="currentColor" />
      <rect x="58" y="57" width="1" height="1" fill="currentColor" />
      <rect x="59" y="57" width="1" height="1" fill="currentColor" />
      <rect x="36" y="58" width="1" height="1" fill="currentColor" />
      <rect x="37" y="58" width="1" height="1" fill="currentColor" />
      <rect x="38" y="58" width="1" height="1" fill="currentColor" />
      <rect x="45" y="58" width="1" height="1" fill="currentColor" />
      <rect x="46" y="58" width="1" height="1" fill="currentColor" />
      <rect x="47" y="58" width="1" height="1" fill="currentColor" />
      <rect x="57" y="58" width="1" height="1" fill="currentColor" />
      <rect x="58" y="58" width="1" height="1" fill="currentColor" />
      <rect x="59" y="58" width="1" height="1" fill="currentColor" />
      <rect x="36" y="59" width="1" height="1" fill="currentColor" />
      <rect x="37" y="59" width="1" height="1" fill="currentColor" />
      <rect x="38" y="59" width="1" height="1" fill="currentColor" />
      <rect x="45" y="59" width="1" height="1" fill="currentColor" />
      <rect x="46" y="59" width="1" height="1" fill="currentColor" />
      <rect x="47" y="59" width="1" height="1" fill="currentColor" />
      <rect x="57" y="59" width="1" height="1" fill="currentColor" />
      <rect x="58" y="59" width="1" height="1" fill="currentColor" />
      <rect x="59" y="59" width="1" height="1" fill="currentColor" />
      <rect x="33" y="60" width="1" height="1" fill="currentColor" />
      <rect x="34" y="60" width="1" height="1" fill="currentColor" />
      <rect x="35" y="60" width="1" height="1" fill="currentColor" />
      <rect x="45" y="60" width="1" height="1" fill="currentColor" />
      <rect x="46" y="60" width="1" height="1" fill="currentColor" />
      <rect x="47" y="60" width="1" height="1" fill="currentColor" />
      <rect x="60" y="60" width="1" height="1" fill="currentColor" />
      <rect x="61" y="60" width="1" height="1" fill="currentColor" />
      <rect x="62" y="60" width="1" height="1" fill="currentColor" />
      <rect x="33" y="61" width="1" height="1" fill="currentColor" />
      <rect x="34" y="61" width="1" height="1" fill="currentColor" />
      <rect x="35" y="61" width="1" height="1" fill="currentColor" />
      <rect x="45" y="61" width="1" height="1" fill="currentColor" />
      <rect x="46" y="61" width="1" height="1" fill="currentColor" />
      <rect x="47" y="61" width="1" height="1" fill="currentColor" />
      <rect x="60" y="61" width="1" height="1" fill="currentColor" />
      <rect x="61" y="61" width="1" height="1" fill="currentColor" />
      <rect x="62" y="61" width="1" height="1" fill="currentColor" />
      <rect x="33" y="62" width="1" height="1" fill="currentColor" />
      <rect x="34" y="62" width="1" height="1" fill="currentColor" />
      <rect x="35" y="62" width="1" height="1" fill="currentColor" />
      <rect x="45" y="62" width="1" height="1" fill="currentColor" />
      <rect x="46" y="62" width="1" height="1" fill="currentColor" />
      <rect x="47" y="62" width="1" height="1" fill="currentColor" />
      <rect x="60" y="62" width="1" height="1" fill="currentColor" />
      <rect x="61" y="62" width="1" height="1" fill="currentColor" />
      <rect x="62" y="62" width="1" height="1" fill="currentColor" />
      <rect x="30" y="63" width="1" height="1" fill="currentColor" />
      <rect x="31" y="63" width="1" height="1" fill="currentColor" />
      <rect x="32" y="63" width="1" height="1" fill="currentColor" />
      <rect x="42" y="63" width="1" height="1" fill="currentColor" />
      <rect x="43" y="63" width="1" height="1" fill="currentColor" />
      <rect x="44" y="63" width="1" height="1" fill="currentColor" />
      <rect x="45" y="63" width="1" height="1" fill="currentColor" />
      <rect x="46" y="63" width="1" height="1" fill="currentColor" />
      <rect x="47" y="63" width="1" height="1" fill="currentColor" />
      <rect x="48" y="63" width="1" height="1" fill="currentColor" />
      <rect x="49" y="63" width="1" height="1" fill="currentColor" />
      <rect x="50" y="63" width="1" height="1" fill="currentColor" />
      <rect x="51" y="63" width="1" height="1" fill="currentColor" />
      <rect x="52" y="63" width="1" height="1" fill="currentColor" />
      <rect x="53" y="63" width="1" height="1" fill="currentColor" />
      <rect x="63" y="63" width="1" height="1" fill="currentColor" />
      <rect x="64" y="63" width="1" height="1" fill="currentColor" />
      <rect x="65" y="63" width="1" height="1" fill="currentColor" />
      <rect x="30" y="64" width="1" height="1" fill="currentColor" />
      <rect x="31" y="64" width="1" height="1" fill="currentColor" />
      <rect x="32" y="64" width="1" height="1" fill="currentColor" />
      <rect x="42" y="64" width="1" height="1" fill="currentColor" />
      <rect x="43" y="64" width="1" height="1" fill="currentColor" />
      <rect x="44" y="64" width="1" height="1" fill="currentColor" />
      <rect x="45" y="64" width="1" height="1" fill="currentColor" />
      <rect x="46" y="64" width="1" height="1" fill="currentColor" />
      <rect x="47" y="64" width="1" height="1" fill="currentColor" />
      <rect x="48" y="64" width="1" height="1" fill="currentColor" />
      <rect x="49" y="64" width="1" height="1" fill="currentColor" />
      <rect x="50" y="64" width="1" height="1" fill="currentColor" />
      <rect x="51" y="64" width="1" height="1" fill="currentColor" />
      <rect x="52" y="64" width="1" height="1" fill="currentColor" />
      <rect x="53" y="64" width="1" height="1" fill="currentColor" />
      <rect x="63" y="64" width="1" height="1" fill="currentColor" />
      <rect x="64" y="64" width="1" height="1" fill="currentColor" />
      <rect x="65" y="64" width="1" height="1" fill="currentColor" />
      <rect x="30" y="65" width="1" height="1" fill="currentColor" />
      <rect x="31" y="65" width="1" height="1" fill="currentColor" />
      <rect x="32" y="65" width="1" height="1" fill="currentColor" />
      <rect x="42" y="65" width="1" height="1" fill="currentColor" />
      <rect x="43" y="65" width="1" height="1" fill="currentColor" />
      <rect x="44" y="65" width="1" height="1" fill="currentColor" />
      <rect x="45" y="65" width="1" height="1" fill="currentColor" />
      <rect x="46" y="65" width="1" height="1" fill="currentColor" />
      <rect x="47" y="65" width="1" height="1" fill="currentColor" />
      <rect x="48" y="65" width="1" height="1" fill="currentColor" />
      <rect x="49" y="65" width="1" height="1" fill="currentColor" />
      <rect x="50" y="65" width="1" height="1" fill="currentColor" />
      <rect x="51" y="65" width="1" height="1" fill="currentColor" />
      <rect x="52" y="65" width="1" height="1" fill="currentColor" />
      <rect x="53" y="65" width="1" height="1" fill="currentColor" />
      <rect x="63" y="65" width="1" height="1" fill="currentColor" />
      <rect x="64" y="65" width="1" height="1" fill="currentColor" />
      <rect x="65" y="65" width="1" height="1" fill="currentColor" />
      <rect x="27" y="66" width="1" height="1" fill="currentColor" />
      <rect x="28" y="66" width="1" height="1" fill="currentColor" />
      <rect x="29" y="66" width="1" height="1" fill="currentColor" />
      <rect x="36" y="66" width="1" height="1" fill="currentColor" />
      <rect x="37" y="66" width="1" height="1" fill="currentColor" />
      <rect x="38" y="66" width="1" height="1" fill="currentColor" />
      <rect x="39" y="66" width="1" height="1" fill="currentColor" />
      <rect x="40" y="66" width="1" height="1" fill="currentColor" />
      <rect x="41" y="66" width="1" height="1" fill="currentColor" />
      <rect x="42" y="66" width="1" height="1" fill="currentColor" />
      <rect x="43" y="66" width="1" height="1" fill="currentColor" />
      <rect x="44" y="66" width="1" height="1" fill="currentColor" />
      <rect x="45" y="66" width="1" height="1" fill="currentColor" />
      <rect x="46" y="66" width="1" height="1" fill="currentColor" />
      <rect x="47" y="66" width="1" height="1" fill="currentColor" />
      <rect x="48" y="66" width="1" height="1" fill="currentColor" />
      <rect x="49" y="66" width="1" height="1" fill="currentColor" />
      <rect x="50" y="66" width="1" height="1" fill="currentColor" />
      <rect x="51" y="66" width="1" height="1" fill="currentColor" />
      <rect x="52" y="66" width="1" height="1" fill="currentColor" />
      <rect x="53" y="66" width="1" height="1" fill="currentColor" />
      <rect x="54" y="66" width="1" height="1" fill="currentColor" />
      <rect x="55" y="66" width="1" height="1" fill="currentColor" />
      <rect x="56" y="66" width="1" height="1" fill="currentColor" />
      <rect x="57" y="66" width="1" height="1" fill="currentColor" />
      <rect x="58" y="66" width="1" height="1" fill="currentColor" />
      <rect x="59" y="66" width="1" height="1" fill="currentColor" />
      <rect x="66" y="66" width="1" height="1" fill="currentColor" />
      <rect x="67" y="66" width="1" height="1" fill="currentColor" />
      <rect x="68" y="66" width="1" height="1" fill="currentColor" />
      <rect x="27" y="67" width="1" height="1" fill="currentColor" />
      <rect x="28" y="67" width="1" height="1" fill="currentColor" />
      <rect x="29" y="67" width="1" height="1" fill="currentColor" />
      <rect x="36" y="67" width="1" height="1" fill="currentColor" />
      <rect x="37" y="67" width="1" height="1" fill="currentColor" />
      <rect x="38" y="67" width="1" height="1" fill="currentColor" />
      <rect x="39" y="67" width="1" height="1" fill="currentColor" />
      <rect x="40" y="67" width="1" height="1" fill="currentColor" />
      <rect x="41" y="67" width="1" height="1" fill="currentColor" />
      <rect x="42" y="67" width="1" height="1" fill="currentColor" />
      <rect x="43" y="67" width="1" height="1" fill="currentColor" />
      <rect x="44" y="67" width="1" height="1" fill="currentColor" />
      <rect x="45" y="67" width="1" height="1" fill="currentColor" />
      <rect x="46" y="67" width="1" height="1" fill="currentColor" />
      <rect x="47" y="67" width="1" height="1" fill="currentColor" />
      <rect x="48" y="67" width="1" height="1" fill="currentColor" />
      <rect x="49" y="67" width="1" height="1" fill="currentColor" />
      <rect x="50" y="67" width="1" height="1" fill="currentColor" />
      <rect x="51" y="67" width="1" height="1" fill="currentColor" />
      <rect x="52" y="67" width="1" height="1" fill="currentColor" />
      <rect x="53" y="67" width="1" height="1" fill="currentColor" />
      <rect x="54" y="67" width="1" height="1" fill="currentColor" />
      <rect x="55" y="67" width="1" height="1" fill="currentColor" />
      <rect x="56" y="67" width="1" height="1" fill="currentColor" />
      <rect x="57" y="67" width="1" height="1" fill="currentColor" />
      <rect x="58" y="67" width="1" height="1" fill="currentColor" />
      <rect x="59" y="67" width="1" height="1" fill="currentColor" />
      <rect x="66" y="67" width="1" height="1" fill="currentColor" />
      <rect x="67" y="67" width="1" height="1" fill="currentColor" />
      <rect x="68" y="67" width="1" height="1" fill="currentColor" />
      <rect x="27" y="68" width="1" height="1" fill="currentColor" />
      <rect x="28" y="68" width="1" height="1" fill="currentColor" />
      <rect x="29" y="68" width="1" height="1" fill="currentColor" />
      <rect x="36" y="68" width="1" height="1" fill="currentColor" />
      <rect x="37" y="68" width="1" height="1" fill="currentColor" />
      <rect x="38" y="68" width="1" height="1" fill="currentColor" />
      <rect x="39" y="68" width="1" height="1" fill="currentColor" />
      <rect x="40" y="68" width="1" height="1" fill="currentColor" />
      <rect x="41" y="68" width="1" height="1" fill="currentColor" />
      <rect x="42" y="68" width="1" height="1" fill="currentColor" />
      <rect x="43" y="68" width="1" height="1" fill="currentColor" />
      <rect x="44" y="68" width="1" height="1" fill="currentColor" />
      <rect x="45" y="68" width="1" height="1" fill="currentColor" />
      <rect x="46" y="68" width="1" height="1" fill="currentColor" />
      <rect x="47" y="68" width="1" height="1" fill="currentColor" />
      <rect x="48" y="68" width="1" height="1" fill="currentColor" />
      <rect x="49" y="68" width="1" height="1" fill="currentColor" />
      <rect x="50" y="68" width="1" height="1" fill="currentColor" />
      <rect x="51" y="68" width="1" height="1" fill="currentColor" />
      <rect x="52" y="68" width="1" height="1" fill="currentColor" />
      <rect x="53" y="68" width="1" height="1" fill="currentColor" />
      <rect x="54" y="68" width="1" height="1" fill="currentColor" />
      <rect x="55" y="68" width="1" height="1" fill="currentColor" />
      <rect x="56" y="68" width="1" height="1" fill="currentColor" />
      <rect x="57" y="68" width="1" height="1" fill="currentColor" />
      <rect x="58" y="68" width="1" height="1" fill="currentColor" />
      <rect x="59" y="68" width="1" height="1" fill="currentColor" />
      <rect x="66" y="68" width="1" height="1" fill="currentColor" />
      <rect x="67" y="68" width="1" height="1" fill="currentColor" />
      <rect x="68" y="68" width="1" height="1" fill="currentColor" />
      <rect x="27" y="69" width="1" height="1" fill="currentColor" />
      <rect x="28" y="69" width="1" height="1" fill="currentColor" />
      <rect x="29" y="69" width="1" height="1" fill="currentColor" />
      <rect x="33" y="69" width="1" height="1" fill="currentColor" />
      <rect x="34" y="69" width="1" height="1" fill="currentColor" />
      <rect x="35" y="69" width="1" height="1" fill="currentColor" />
      <rect x="36" y="69" width="1" height="1" fill="currentColor" />
      <rect x="37" y="69" width="1" height="1" fill="currentColor" />
      <rect x="38" y="69" width="1" height="1" fill="currentColor" />
      <rect x="39" y="69" width="1" height="1" fill="currentColor" />
      <rect x="40" y="69" width="1" height="1" fill="currentColor" />
      <rect x="41" y="69" width="1" height="1" fill="currentColor" />
      <rect x="42" y="69" width="1" height="1" fill="currentColor" />
      <rect x="43" y="69" width="1" height="1" fill="currentColor" />
      <rect x="44" y="69" width="1" height="1" fill="currentColor" />
      <rect x="45" y="69" width="1" height="1" fill="currentColor" />
      <rect x="46" y="69" width="1" height="1" fill="currentColor" />
      <rect x="47" y="69" width="1" height="1" fill="currentColor" />
      <rect x="48" y="69" width="1" height="1" fill="currentColor" />
      <rect x="49" y="69" width="1" height="1" fill="currentColor" />
      <rect x="50" y="69" width="1" height="1" fill="currentColor" />
      <rect x="51" y="69" width="1" height="1" fill="currentColor" />
      <rect x="52" y="69" width="1" height="1" fill="currentColor" />
      <rect x="53" y="69" width="1" height="1" fill="currentColor" />
      <rect x="54" y="69" width="1" height="1" fill="currentColor" />
      <rect x="55" y="69" width="1" height="1" fill="currentColor" />
      <rect x="56" y="69" width="1" height="1" fill="currentColor" />
      <rect x="57" y="69" width="1" height="1" fill="currentColor" />
      <rect x="58" y="69" width="1" height="1" fill="currentColor" />
      <rect x="59" y="69" width="1" height="1" fill="currentColor" />
      <rect x="60" y="69" width="1" height="1" fill="currentColor" />
      <rect x="61" y="69" width="1" height="1" fill="currentColor" />
      <rect x="62" y="69" width="1" height="1" fill="currentColor" />
      <rect x="66" y="69" width="1" height="1" fill="currentColor" />
      <rect x="67" y="69" width="1" height="1" fill="currentColor" />
      <rect x="68" y="69" width="1" height="1" fill="currentColor" />
      <rect x="27" y="70" width="1" height="1" fill="currentColor" />
      <rect x="28" y="70" width="1" height="1" fill="currentColor" />
      <rect x="29" y="70" width="1" height="1" fill="currentColor" />
      <rect x="33" y="70" width="1" height="1" fill="currentColor" />
      <rect x="34" y="70" width="1" height="1" fill="currentColor" />
      <rect x="35" y="70" width="1" height="1" fill="currentColor" />
      <rect x="36" y="70" width="1" height="1" fill="currentColor" />
      <rect x="37" y="70" width="1" height="1" fill="currentColor" />
      <rect x="38" y="70" width="1" height="1" fill="currentColor" />
      <rect x="39" y="70" width="1" height="1" fill="currentColor" />
      <rect x="40" y="70" width="1" height="1" fill="currentColor" />
      <rect x="41" y="70" width="1" height="1" fill="currentColor" />
      <rect x="42" y="70" width="1" height="1" fill="currentColor" />
      <rect x="43" y="70" width="1" height="1" fill="currentColor" />
      <rect x="44" y="70" width="1" height="1" fill="currentColor" />
      <rect x="45" y="70" width="1" height="1" fill="currentColor" />
      <rect x="46" y="70" width="1" height="1" fill="currentColor" />
      <rect x="47" y="70" width="1" height="1" fill="currentColor" />
      <rect x="48" y="70" width="1" height="1" fill="currentColor" />
      <rect x="49" y="70" width="1" height="1" fill="currentColor" />
      <rect x="50" y="70" width="1" height="1" fill="currentColor" />
      <rect x="51" y="70" width="1" height="1" fill="currentColor" />
      <rect x="52" y="70" width="1" height="1" fill="currentColor" />
      <rect x="53" y="70" width="1" height="1" fill="currentColor" />
      <rect x="54" y="70" width="1" height="1" fill="currentColor" />
      <rect x="55" y="70" width="1" height="1" fill="currentColor" />
      <rect x="56" y="70" width="1" height="1" fill="currentColor" />
      <rect x="57" y="70" width="1" height="1" fill="currentColor" />
      <rect x="58" y="70" width="1" height="1" fill="currentColor" />
      <rect x="59" y="70" width="1" height="1" fill="currentColor" />
      <rect x="60" y="70" width="1" height="1" fill="currentColor" />
      <rect x="61" y="70" width="1" height="1" fill="currentColor" />
      <rect x="62" y="70" width="1" height="1" fill="currentColor" />
      <rect x="66" y="70" width="1" height="1" fill="currentColor" />
      <rect x="67" y="70" width="1" height="1" fill="currentColor" />
      <rect x="68" y="70" width="1" height="1" fill="currentColor" />
      <rect x="27" y="71" width="1" height="1" fill="currentColor" />
      <rect x="28" y="71" width="1" height="1" fill="currentColor" />
      <rect x="29" y="71" width="1" height="1" fill="currentColor" />
      <rect x="33" y="71" width="1" height="1" fill="currentColor" />
      <rect x="34" y="71" width="1" height="1" fill="currentColor" />
      <rect x="35" y="71" width="1" height="1" fill="currentColor" />
      <rect x="36" y="71" width="1" height="1" fill="currentColor" />
      <rect x="37" y="71" width="1" height="1" fill="currentColor" />
      <rect x="38" y="71" width="1" height="1" fill="currentColor" />
      <rect x="39" y="71" width="1" height="1" fill="currentColor" />
      <rect x="40" y="71" width="1" height="1" fill="currentColor" />
      <rect x="41" y="71" width="1" height="1" fill="currentColor" />
      <rect x="42" y="71" width="1" height="1" fill="currentColor" />
      <rect x="43" y="71" width="1" height="1" fill="currentColor" />
      <rect x="44" y="71" width="1" height="1" fill="currentColor" />
      <rect x="45" y="71" width="1" height="1" fill="currentColor" />
      <rect x="46" y="71" width="1" height="1" fill="currentColor" />
      <rect x="47" y="71" width="1" height="1" fill="currentColor" />
      <rect x="48" y="71" width="1" height="1" fill="currentColor" />
      <rect x="49" y="71" width="1" height="1" fill="currentColor" />
      <rect x="50" y="71" width="1" height="1" fill="currentColor" />
      <rect x="51" y="71" width="1" height="1" fill="currentColor" />
      <rect x="52" y="71" width="1" height="1" fill="currentColor" />
      <rect x="53" y="71" width="1" height="1" fill="currentColor" />
      <rect x="54" y="71" width="1" height="1" fill="currentColor" />
      <rect x="55" y="71" width="1" height="1" fill="currentColor" />
      <rect x="56" y="71" width="1" height="1" fill="currentColor" />
      <rect x="57" y="71" width="1" height="1" fill="currentColor" />
      <rect x="58" y="71" width="1" height="1" fill="currentColor" />
      <rect x="59" y="71" width="1" height="1" fill="currentColor" />
      <rect x="60" y="71" width="1" height="1" fill="currentColor" />
      <rect x="61" y="71" width="1" height="1" fill="currentColor" />
      <rect x="62" y="71" width="1" height="1" fill="currentColor" />
      <rect x="66" y="71" width="1" height="1" fill="currentColor" />
      <rect x="67" y="71" width="1" height="1" fill="currentColor" />
      <rect x="68" y="71" width="1" height="1" fill="currentColor" />
      <rect x="24" y="72" width="1" height="1" fill="currentColor" />
      <rect x="25" y="72" width="1" height="1" fill="currentColor" />
      <rect x="26" y="72" width="1" height="1" fill="currentColor" />
      <rect x="30" y="72" width="1" height="1" fill="currentColor" />
      <rect x="31" y="72" width="1" height="1" fill="currentColor" />
      <rect x="32" y="72" width="1" height="1" fill="currentColor" />
      <rect x="33" y="72" width="1" height="1" fill="currentColor" />
      <rect x="34" y="72" width="1" height="1" fill="currentColor" />
      <rect x="35" y="72" width="1" height="1" fill="currentColor" />
      <rect x="36" y="72" width="1" height="1" fill="currentColor" />
      <rect x="37" y="72" width="1" height="1" fill="currentColor" />
      <rect x="38" y="72" width="1" height="1" fill="currentColor" />
      <rect x="39" y="72" width="1" height="1" fill="currentColor" />
      <rect x="40" y="72" width="1" height="1" fill="currentColor" />
      <rect x="41" y="72" width="1" height="1" fill="currentColor" />
      <rect x="42" y="72" width="1" height="1" fill="currentColor" />
      <rect x="43" y="72" width="1" height="1" fill="currentColor" />
      <rect x="44" y="72" width="1" height="1" fill="currentColor" />
      <rect x="45" y="72" width="1" height="1" fill="currentColor" />
      <rect x="46" y="72" width="1" height="1" fill="currentColor" />
      <rect x="47" y="72" width="1" height="1" fill="currentColor" />
      <rect x="48" y="72" width="1" height="1" fill="currentColor" />
      <rect x="49" y="72" width="1" height="1" fill="currentColor" />
      <rect x="50" y="72" width="1" height="1" fill="currentColor" />
      <rect x="51" y="72" width="1" height="1" fill="currentColor" />
      <rect x="52" y="72" width="1" height="1" fill="currentColor" />
      <rect x="53" y="72" width="1" height="1" fill="currentColor" />
      <rect x="54" y="72" width="1" height="1" fill="currentColor" />
      <rect x="55" y="72" width="1" height="1" fill="currentColor" />
      <rect x="56" y="72" width="1" height="1" fill="currentColor" />
      <rect x="57" y="72" width="1" height="1" fill="currentColor" />
      <rect x="58" y="72" width="1" height="1" fill="currentColor" />
      <rect x="59" y="72" width="1" height="1" fill="currentColor" />
      <rect x="60" y="72" width="1" height="1" fill="currentColor" />
      <rect x="61" y="72" width="1" height="1" fill="currentColor" />
      <rect x="62" y="72" width="1" height="1" fill="currentColor" />
      <rect x="63" y="72" width="1" height="1" fill="currentColor" />
      <rect x="64" y="72" width="1" height="1" fill="currentColor" />
      <rect x="65" y="72" width="1" height="1" fill="currentColor" />
      <rect x="69" y="72" width="1" height="1" fill="currentColor" />
      <rect x="70" y="72" width="1" height="1" fill="currentColor" />
      <rect x="71" y="72" width="1" height="1" fill="currentColor" />
      <rect x="24" y="73" width="1" height="1" fill="currentColor" />
      <rect x="25" y="73" width="1" height="1" fill="currentColor" />
      <rect x="26" y="73" width="1" height="1" fill="currentColor" />
      <rect x="30" y="73" width="1" height="1" fill="currentColor" />
      <rect x="31" y="73" width="1" height="1" fill="currentColor" />
      <rect x="32" y="73" width="1" height="1" fill="currentColor" />
      <rect x="33" y="73" width="1" height="1" fill="currentColor" />
      <rect x="34" y="73" width="1" height="1" fill="currentColor" />
      <rect x="35" y="73" width="1" height="1" fill="currentColor" />
      <rect x="36" y="73" width="1" height="1" fill="currentColor" />
      <rect x="37" y="73" width="1" height="1" fill="currentColor" />
      <rect x="38" y="73" width="1" height="1" fill="currentColor" />
      <rect x="39" y="73" width="1" height="1" fill="currentColor" />
      <rect x="40" y="73" width="1" height="1" fill="currentColor" />
      <rect x="41" y="73" width="1" height="1" fill="currentColor" />
      <rect x="42" y="73" width="1" height="1" fill="currentColor" />
      <rect x="43" y="73" width="1" height="1" fill="currentColor" />
      <rect x="44" y="73" width="1" height="1" fill="currentColor" />
      <rect x="45" y="73" width="1" height="1" fill="currentColor" />
      <rect x="46" y="73" width="1" height="1" fill="currentColor" />
      <rect x="47" y="73" width="1" height="1" fill="currentColor" />
      <rect x="48" y="73" width="1" height="1" fill="currentColor" />
      <rect x="49" y="73" width="1" height="1" fill="currentColor" />
      <rect x="50" y="73" width="1" height="1" fill="currentColor" />
      <rect x="51" y="73" width="1" height="1" fill="currentColor" />
      <rect x="52" y="73" width="1" height="1" fill="currentColor" />
      <rect x="53" y="73" width="1" height="1" fill="currentColor" />
      <rect x="54" y="73" width="1" height="1" fill="currentColor" />
      <rect x="55" y="73" width="1" height="1" fill="currentColor" />
      <rect x="56" y="73" width="1" height="1" fill="currentColor" />
      <rect x="57" y="73" width="1" height="1" fill="currentColor" />
      <rect x="58" y="73" width="1" height="1" fill="currentColor" />
      <rect x="59" y="73" width="1" height="1" fill="currentColor" />
      <rect x="60" y="73" width="1" height="1" fill="currentColor" />
      <rect x="61" y="73" width="1" height="1" fill="currentColor" />
      <rect x="62" y="73" width="1" height="1" fill="currentColor" />
      <rect x="63" y="73" width="1" height="1" fill="currentColor" />
      <rect x="64" y="73" width="1" height="1" fill="currentColor" />
      <rect x="65" y="73" width="1" height="1" fill="currentColor" />
      <rect x="69" y="73" width="1" height="1" fill="currentColor" />
      <rect x="70" y="73" width="1" height="1" fill="currentColor" />
      <rect x="71" y="73" width="1" height="1" fill="currentColor" />
      <rect x="24" y="74" width="1" height="1" fill="currentColor" />
      <rect x="25" y="74" width="1" height="1" fill="currentColor" />
      <rect x="26" y="74" width="1" height="1" fill="currentColor" />
      <rect x="30" y="74" width="1" height="1" fill="currentColor" />
      <rect x="31" y="74" width="1" height="1" fill="currentColor" />
      <rect x="32" y="74" width="1" height="1" fill="currentColor" />
      <rect x="33" y="74" width="1" height="1" fill="currentColor" />
      <rect x="34" y="74" width="1" height="1" fill="currentColor" />
      <rect x="35" y="74" width="1" height="1" fill="currentColor" />
      <rect x="36" y="74" width="1" height="1" fill="currentColor" />
      <rect x="37" y="74" width="1" height="1" fill="currentColor" />
      <rect x="38" y="74" width="1" height="1" fill="currentColor" />
      <rect x="39" y="74" width="1" height="1" fill="currentColor" />
      <rect x="40" y="74" width="1" height="1" fill="currentColor" />
      <rect x="41" y="74" width="1" height="1" fill="currentColor" />
      <rect x="42" y="74" width="1" height="1" fill="currentColor" />
      <rect x="43" y="74" width="1" height="1" fill="currentColor" />
      <rect x="44" y="74" width="1" height="1" fill="currentColor" />
      <rect x="45" y="74" width="1" height="1" fill="currentColor" />
      <rect x="46" y="74" width="1" height="1" fill="currentColor" />
      <rect x="47" y="74" width="1" height="1" fill="currentColor" />
      <rect x="48" y="74" width="1" height="1" fill="currentColor" />
      <rect x="49" y="74" width="1" height="1" fill="currentColor" />
      <rect x="50" y="74" width="1" height="1" fill="currentColor" />
      <rect x="51" y="74" width="1" height="1" fill="currentColor" />
      <rect x="52" y="74" width="1" height="1" fill="currentColor" />
      <rect x="53" y="74" width="1" height="1" fill="currentColor" />
      <rect x="54" y="74" width="1" height="1" fill="currentColor" />
      <rect x="55" y="74" width="1" height="1" fill="currentColor" />
      <rect x="56" y="74" width="1" height="1" fill="currentColor" />
      <rect x="57" y="74" width="1" height="1" fill="currentColor" />
      <rect x="58" y="74" width="1" height="1" fill="currentColor" />
      <rect x="59" y="74" width="1" height="1" fill="currentColor" />
      <rect x="60" y="74" width="1" height="1" fill="currentColor" />
      <rect x="61" y="74" width="1" height="1" fill="currentColor" />
      <rect x="62" y="74" width="1" height="1" fill="currentColor" />
      <rect x="63" y="74" width="1" height="1" fill="currentColor" />
      <rect x="64" y="74" width="1" height="1" fill="currentColor" />
      <rect x="65" y="74" width="1" height="1" fill="currentColor" />
      <rect x="69" y="74" width="1" height="1" fill="currentColor" />
      <rect x="70" y="74" width="1" height="1" fill="currentColor" />
      <rect x="71" y="74" width="1" height="1" fill="currentColor" />
      <rect x="24" y="75" width="1" height="1" fill="currentColor" />
      <rect x="25" y="75" width="1" height="1" fill="currentColor" />
      <rect x="26" y="75" width="1" height="1" fill="currentColor" />
      <rect x="30" y="75" width="1" height="1" fill="currentColor" />
      <rect x="31" y="75" width="1" height="1" fill="currentColor" />
      <rect x="32" y="75" width="1" height="1" fill="currentColor" />
      <rect x="33" y="75" width="1" height="1" fill="currentColor" />
      <rect x="34" y="75" width="1" height="1" fill="currentColor" />
      <rect x="35" y="75" width="1" height="1" fill="currentColor" />
      <rect x="36" y="75" width="1" height="1" fill="currentColor" />
      <rect x="37" y="75" width="1" height="1" fill="currentColor" />
      <rect x="38" y="75" width="1" height="1" fill="currentColor" />
      <rect x="39" y="75" width="1" height="1" fill="currentColor" />
      <rect x="40" y="75" width="1" height="1" fill="currentColor" />
      <rect x="41" y="75" width="1" height="1" fill="currentColor" />
      <rect x="42" y="75" width="1" height="1" fill="currentColor" />
      <rect x="43" y="75" width="1" height="1" fill="currentColor" />
      <rect x="44" y="75" width="1" height="1" fill="currentColor" />
      <rect x="45" y="75" width="1" height="1" fill="currentColor" />
      <rect x="46" y="75" width="1" height="1" fill="currentColor" />
      <rect x="47" y="75" width="1" height="1" fill="currentColor" />
      <rect x="48" y="75" width="1" height="1" fill="currentColor" />
      <rect x="49" y="75" width="1" height="1" fill="currentColor" />
      <rect x="50" y="75" width="1" height="1" fill="currentColor" />
      <rect x="51" y="75" width="1" height="1" fill="currentColor" />
      <rect x="52" y="75" width="1" height="1" fill="currentColor" />
      <rect x="53" y="75" width="1" height="1" fill="currentColor" />
      <rect x="54" y="75" width="1" height="1" fill="currentColor" />
      <rect x="55" y="75" width="1" height="1" fill="currentColor" />
      <rect x="56" y="75" width="1" height="1" fill="currentColor" />
      <rect x="57" y="75" width="1" height="1" fill="currentColor" />
      <rect x="58" y="75" width="1" height="1" fill="currentColor" />
      <rect x="59" y="75" width="1" height="1" fill="currentColor" />
      <rect x="60" y="75" width="1" height="1" fill="currentColor" />
      <rect x="61" y="75" width="1" height="1" fill="currentColor" />
      <rect x="62" y="75" width="1" height="1" fill="currentColor" />
      <rect x="63" y="75" width="1" height="1" fill="currentColor" />
      <rect x="64" y="75" width="1" height="1" fill="currentColor" />
      <rect x="65" y="75" width="1" height="1" fill="currentColor" />
      <rect x="69" y="75" width="1" height="1" fill="currentColor" />
      <rect x="70" y="75" width="1" height="1" fill="currentColor" />
      <rect x="71" y="75" width="1" height="1" fill="currentColor" />
      <rect x="24" y="76" width="1" height="1" fill="currentColor" />
      <rect x="25" y="76" width="1" height="1" fill="currentColor" />
      <rect x="26" y="76" width="1" height="1" fill="currentColor" />
      <rect x="30" y="76" width="1" height="1" fill="currentColor" />
      <rect x="31" y="76" width="1" height="1" fill="currentColor" />
      <rect x="32" y="76" width="1" height="1" fill="currentColor" />
      <rect x="33" y="76" width="1" height="1" fill="currentColor" />
      <rect x="34" y="76" width="1" height="1" fill="currentColor" />
      <rect x="35" y="76" width="1" height="1" fill="currentColor" />
      <rect x="36" y="76" width="1" height="1" fill="currentColor" />
      <rect x="37" y="76" width="1" height="1" fill="currentColor" />
      <rect x="38" y="76" width="1" height="1" fill="currentColor" />
      <rect x="39" y="76" width="1" height="1" fill="currentColor" />
      <rect x="40" y="76" width="1" height="1" fill="currentColor" />
      <rect x="41" y="76" width="1" height="1" fill="currentColor" />
      <rect x="42" y="76" width="1" height="1" fill="currentColor" />
      <rect x="43" y="76" width="1" height="1" fill="currentColor" />
      <rect x="44" y="76" width="1" height="1" fill="currentColor" />
      <rect x="45" y="76" width="1" height="1" fill="currentColor" />
      <rect x="46" y="76" width="1" height="1" fill="currentColor" />
      <rect x="47" y="76" width="1" height="1" fill="currentColor" />
      <rect x="48" y="76" width="1" height="1" fill="currentColor" />
      <rect x="49" y="76" width="1" height="1" fill="currentColor" />
      <rect x="50" y="76" width="1" height="1" fill="currentColor" />
      <rect x="51" y="76" width="1" height="1" fill="currentColor" />
      <rect x="52" y="76" width="1" height="1" fill="currentColor" />
      <rect x="53" y="76" width="1" height="1" fill="currentColor" />
      <rect x="54" y="76" width="1" height="1" fill="currentColor" />
      <rect x="55" y="76" width="1" height="1" fill="currentColor" />
      <rect x="56" y="76" width="1" height="1" fill="currentColor" />
      <rect x="57" y="76" width="1" height="1" fill="currentColor" />
      <rect x="58" y="76" width="1" height="1" fill="currentColor" />
      <rect x="59" y="76" width="1" height="1" fill="currentColor" />
      <rect x="60" y="76" width="1" height="1" fill="currentColor" />
      <rect x="61" y="76" width="1" height="1" fill="currentColor" />
      <rect x="62" y="76" width="1" height="1" fill="currentColor" />
      <rect x="63" y="76" width="1" height="1" fill="currentColor" />
      <rect x="64" y="76" width="1" height="1" fill="currentColor" />
      <rect x="65" y="76" width="1" height="1" fill="currentColor" />
      <rect x="69" y="76" width="1" height="1" fill="currentColor" />
      <rect x="70" y="76" width="1" height="1" fill="currentColor" />
      <rect x="71" y="76" width="1" height="1" fill="currentColor" />
      <rect x="24" y="77" width="1" height="1" fill="currentColor" />
      <rect x="25" y="77" width="1" height="1" fill="currentColor" />
      <rect x="26" y="77" width="1" height="1" fill="currentColor" />
      <rect x="30" y="77" width="1" height="1" fill="currentColor" />
      <rect x="31" y="77" width="1" height="1" fill="currentColor" />
      <rect x="32" y="77" width="1" height="1" fill="currentColor" />
      <rect x="33" y="77" width="1" height="1" fill="currentColor" />
      <rect x="34" y="77" width="1" height="1" fill="currentColor" />
      <rect x="35" y="77" width="1" height="1" fill="currentColor" />
      <rect x="36" y="77" width="1" height="1" fill="currentColor" />
      <rect x="37" y="77" width="1" height="1" fill="currentColor" />
      <rect x="38" y="77" width="1" height="1" fill="currentColor" />
      <rect x="39" y="77" width="1" height="1" fill="currentColor" />
      <rect x="40" y="77" width="1" height="1" fill="currentColor" />
      <rect x="41" y="77" width="1" height="1" fill="currentColor" />
      <rect x="42" y="77" width="1" height="1" fill="currentColor" />
      <rect x="43" y="77" width="1" height="1" fill="currentColor" />
      <rect x="44" y="77" width="1" height="1" fill="currentColor" />
      <rect x="45" y="77" width="1" height="1" fill="currentColor" />
      <rect x="46" y="77" width="1" height="1" fill="currentColor" />
      <rect x="47" y="77" width="1" height="1" fill="currentColor" />
      <rect x="48" y="77" width="1" height="1" fill="currentColor" />
      <rect x="49" y="77" width="1" height="1" fill="currentColor" />
      <rect x="50" y="77" width="1" height="1" fill="currentColor" />
      <rect x="51" y="77" width="1" height="1" fill="currentColor" />
      <rect x="52" y="77" width="1" height="1" fill="currentColor" />
      <rect x="53" y="77" width="1" height="1" fill="currentColor" />
      <rect x="54" y="77" width="1" height="1" fill="currentColor" />
      <rect x="55" y="77" width="1" height="1" fill="currentColor" />
      <rect x="56" y="77" width="1" height="1" fill="currentColor" />
      <rect x="57" y="77" width="1" height="1" fill="currentColor" />
      <rect x="58" y="77" width="1" height="1" fill="currentColor" />
      <rect x="59" y="77" width="1" height="1" fill="currentColor" />
      <rect x="60" y="77" width="1" height="1" fill="currentColor" />
      <rect x="61" y="77" width="1" height="1" fill="currentColor" />
      <rect x="62" y="77" width="1" height="1" fill="currentColor" />
      <rect x="63" y="77" width="1" height="1" fill="currentColor" />
      <rect x="64" y="77" width="1" height="1" fill="currentColor" />
      <rect x="65" y="77" width="1" height="1" fill="currentColor" />
      <rect x="69" y="77" width="1" height="1" fill="currentColor" />
      <rect x="70" y="77" width="1" height="1" fill="currentColor" />
      <rect x="71" y="77" width="1" height="1" fill="currentColor" />
      <rect x="21" y="78" width="1" height="1" fill="currentColor" />
      <rect x="22" y="78" width="1" height="1" fill="currentColor" />
      <rect x="23" y="78" width="1" height="1" fill="currentColor" />
      <rect x="27" y="78" width="1" height="1" fill="currentColor" />
      <rect x="28" y="78" width="1" height="1" fill="currentColor" />
      <rect x="29" y="78" width="1" height="1" fill="currentColor" />
      <rect x="30" y="78" width="1" height="1" fill="currentColor" />
      <rect x="31" y="78" width="1" height="1" fill="currentColor" />
      <rect x="32" y="78" width="1" height="1" fill="currentColor" />
      <rect x="33" y="78" width="1" height="1" fill="currentColor" />
      <rect x="34" y="78" width="1" height="1" fill="currentColor" />
      <rect x="35" y="78" width="1" height="1" fill="currentColor" />
      <rect x="36" y="78" width="1" height="1" fill="currentColor" />
      <rect x="37" y="78" width="1" height="1" fill="currentColor" />
      <rect x="38" y="78" width="1" height="1" fill="currentColor" />
      <rect x="39" y="78" width="1" height="1" fill="currentColor" />
      <rect x="40" y="78" width="1" height="1" fill="currentColor" />
      <rect x="41" y="78" width="1" height="1" fill="currentColor" />
      <rect x="42" y="78" width="1" height="1" fill="currentColor" />
      <rect x="43" y="78" width="1" height="1" fill="currentColor" />
      <rect x="44" y="78" width="1" height="1" fill="currentColor" />
      <rect x="45" y="78" width="1" height="1" fill="currentColor" />
      <rect x="46" y="78" width="1" height="1" fill="currentColor" />
      <rect x="47" y="78" width="1" height="1" fill="currentColor" />
      <rect x="48" y="78" width="1" height="1" fill="currentColor" />
      <rect x="49" y="78" width="1" height="1" fill="currentColor" />
      <rect x="50" y="78" width="1" height="1" fill="currentColor" />
      <rect x="51" y="78" width="1" height="1" fill="currentColor" />
      <rect x="52" y="78" width="1" height="1" fill="currentColor" />
      <rect x="53" y="78" width="1" height="1" fill="currentColor" />
      <rect x="54" y="78" width="1" height="1" fill="currentColor" />
      <rect x="55" y="78" width="1" height="1" fill="currentColor" />
      <rect x="56" y="78" width="1" height="1" fill="currentColor" />
      <rect x="57" y="78" width="1" height="1" fill="currentColor" />
      <rect x="58" y="78" width="1" height="1" fill="currentColor" />
      <rect x="59" y="78" width="1" height="1" fill="currentColor" />
      <rect x="60" y="78" width="1" height="1" fill="currentColor" />
      <rect x="61" y="78" width="1" height="1" fill="currentColor" />
      <rect x="62" y="78" width="1" height="1" fill="currentColor" />
      <rect x="63" y="78" width="1" height="1" fill="currentColor" />
      <rect x="64" y="78" width="1" height="1" fill="currentColor" />
      <rect x="65" y="78" width="1" height="1" fill="currentColor" />
      <rect x="66" y="78" width="1" height="1" fill="currentColor" />
      <rect x="67" y="78" width="1" height="1" fill="currentColor" />
      <rect x="68" y="78" width="1" height="1" fill="currentColor" />
      <rect x="72" y="78" width="1" height="1" fill="currentColor" />
      <rect x="73" y="78" width="1" height="1" fill="currentColor" />
      <rect x="74" y="78" width="1" height="1" fill="currentColor" />
      <rect x="21" y="79" width="1" height="1" fill="currentColor" />
      <rect x="22" y="79" width="1" height="1" fill="currentColor" />
      <rect x="23" y="79" width="1" height="1" fill="currentColor" />
      <rect x="27" y="79" width="1" height="1" fill="currentColor" />
      <rect x="28" y="79" width="1" height="1" fill="currentColor" />
      <rect x="29" y="79" width="1" height="1" fill="currentColor" />
      <rect x="30" y="79" width="1" height="1" fill="currentColor" />
      <rect x="31" y="79" width="1" height="1" fill="currentColor" />
      <rect x="32" y="79" width="1" height="1" fill="currentColor" />
      <rect x="33" y="79" width="1" height="1" fill="currentColor" />
      <rect x="34" y="79" width="1" height="1" fill="currentColor" />
      <rect x="35" y="79" width="1" height="1" fill="currentColor" />
      <rect x="36" y="79" width="1" height="1" fill="currentColor" />
      <rect x="37" y="79" width="1" height="1" fill="currentColor" />
      <rect x="38" y="79" width="1" height="1" fill="currentColor" />
      <rect x="39" y="79" width="1" height="1" fill="currentColor" />
      <rect x="40" y="79" width="1" height="1" fill="currentColor" />
      <rect x="41" y="79" width="1" height="1" fill="currentColor" />
      <rect x="42" y="79" width="1" height="1" fill="currentColor" />
      <rect x="43" y="79" width="1" height="1" fill="currentColor" />
      <rect x="44" y="79" width="1" height="1" fill="currentColor" />
      <rect x="45" y="79" width="1" height="1" fill="currentColor" />
      <rect x="46" y="79" width="1" height="1" fill="currentColor" />
      <rect x="47" y="79" width="1" height="1" fill="currentColor" />
      <rect x="48" y="79" width="1" height="1" fill="currentColor" />
      <rect x="49" y="79" width="1" height="1" fill="currentColor" />
      <rect x="50" y="79" width="1" height="1" fill="currentColor" />
      <rect x="51" y="79" width="1" height="1" fill="currentColor" />
      <rect x="52" y="79" width="1" height="1" fill="currentColor" />
      <rect x="53" y="79" width="1" height="1" fill="currentColor" />
      <rect x="54" y="79" width="1" height="1" fill="currentColor" />
      <rect x="55" y="79" width="1" height="1" fill="currentColor" />
      <rect x="56" y="79" width="1" height="1" fill="currentColor" />
      <rect x="57" y="79" width="1" height="1" fill="currentColor" />
      <rect x="58" y="79" width="1" height="1" fill="currentColor" />
      <rect x="59" y="79" width="1" height="1" fill="currentColor" />
      <rect x="60" y="79" width="1" height="1" fill="currentColor" />
      <rect x="61" y="79" width="1" height="1" fill="currentColor" />
      <rect x="62" y="79" width="1" height="1" fill="currentColor" />
      <rect x="63" y="79" width="1" height="1" fill="currentColor" />
      <rect x="64" y="79" width="1" height="1" fill="currentColor" />
      <rect x="65" y="79" width="1" height="1" fill="currentColor" />
      <rect x="66" y="79" width="1" height="1" fill="currentColor" />
      <rect x="67" y="79" width="1" height="1" fill="currentColor" />
      <rect x="68" y="79" width="1" height="1" fill="currentColor" />
      <rect x="72" y="79" width="1" height="1" fill="currentColor" />
      <rect x="73" y="79" width="1" height="1" fill="currentColor" />
      <rect x="74" y="79" width="1" height="1" fill="currentColor" />
      <rect x="21" y="80" width="1" height="1" fill="currentColor" />
      <rect x="22" y="80" width="1" height="1" fill="currentColor" />
      <rect x="23" y="80" width="1" height="1" fill="currentColor" />
      <rect x="27" y="80" width="1" height="1" fill="currentColor" />
      <rect x="28" y="80" width="1" height="1" fill="currentColor" />
      <rect x="29" y="80" width="1" height="1" fill="currentColor" />
      <rect x="30" y="80" width="1" height="1" fill="currentColor" />
      <rect x="31" y="80" width="1" height="1" fill="currentColor" />
      <rect x="32" y="80" width="1" height="1" fill="currentColor" />
      <rect x="33" y="80" width="1" height="1" fill="currentColor" />
      <rect x="34" y="80" width="1" height="1" fill="currentColor" />
      <rect x="35" y="80" width="1" height="1" fill="currentColor" />
      <rect x="36" y="80" width="1" height="1" fill="currentColor" />
      <rect x="37" y="80" width="1" height="1" fill="currentColor" />
      <rect x="38" y="80" width="1" height="1" fill="currentColor" />
      <rect x="39" y="80" width="1" height="1" fill="currentColor" />
      <rect x="40" y="80" width="1" height="1" fill="currentColor" />
      <rect x="41" y="80" width="1" height="1" fill="currentColor" />
      <rect x="42" y="80" width="1" height="1" fill="currentColor" />
      <rect x="43" y="80" width="1" height="1" fill="currentColor" />
      <rect x="44" y="80" width="1" height="1" fill="currentColor" />
      <rect x="45" y="80" width="1" height="1" fill="currentColor" />
      <rect x="46" y="80" width="1" height="1" fill="currentColor" />
      <rect x="47" y="80" width="1" height="1" fill="currentColor" />
      <rect x="48" y="80" width="1" height="1" fill="currentColor" />
      <rect x="49" y="80" width="1" height="1" fill="currentColor" />
      <rect x="50" y="80" width="1" height="1" fill="currentColor" />
      <rect x="51" y="80" width="1" height="1" fill="currentColor" />
      <rect x="52" y="80" width="1" height="1" fill="currentColor" />
      <rect x="53" y="80" width="1" height="1" fill="currentColor" />
      <rect x="54" y="80" width="1" height="1" fill="currentColor" />
      <rect x="55" y="80" width="1" height="1" fill="currentColor" />
      <rect x="56" y="80" width="1" height="1" fill="currentColor" />
      <rect x="57" y="80" width="1" height="1" fill="currentColor" />
      <rect x="58" y="80" width="1" height="1" fill="currentColor" />
      <rect x="59" y="80" width="1" height="1" fill="currentColor" />
      <rect x="60" y="80" width="1" height="1" fill="currentColor" />
      <rect x="61" y="80" width="1" height="1" fill="currentColor" />
      <rect x="62" y="80" width="1" height="1" fill="currentColor" />
      <rect x="63" y="80" width="1" height="1" fill="currentColor" />
      <rect x="64" y="80" width="1" height="1" fill="currentColor" />
      <rect x="65" y="80" width="1" height="1" fill="currentColor" />
      <rect x="66" y="80" width="1" height="1" fill="currentColor" />
      <rect x="67" y="80" width="1" height="1" fill="currentColor" />
      <rect x="68" y="80" width="1" height="1" fill="currentColor" />
      <rect x="72" y="80" width="1" height="1" fill="currentColor" />
      <rect x="73" y="80" width="1" height="1" fill="currentColor" />
      <rect x="74" y="80" width="1" height="1" fill="currentColor" />
      <rect x="21" y="81" width="1" height="1" fill="currentColor" />
      <rect x="22" y="81" width="1" height="1" fill="currentColor" />
      <rect x="23" y="81" width="1" height="1" fill="currentColor" />
      <rect x="27" y="81" width="1" height="1" fill="currentColor" />
      <rect x="28" y="81" width="1" height="1" fill="currentColor" />
      <rect x="29" y="81" width="1" height="1" fill="currentColor" />
      <rect x="30" y="81" width="1" height="1" fill="currentColor" />
      <rect x="31" y="81" width="1" height="1" fill="currentColor" />
      <rect x="32" y="81" width="1" height="1" fill="currentColor" />
      <rect x="33" y="81" width="1" height="1" fill="currentColor" />
      <rect x="34" y="81" width="1" height="1" fill="currentColor" />
      <rect x="35" y="81" width="1" height="1" fill="currentColor" />
      <rect x="36" y="81" width="1" height="1" fill="currentColor" />
      <rect x="37" y="81" width="1" height="1" fill="currentColor" />
      <rect x="38" y="81" width="1" height="1" fill="currentColor" />
      <rect x="39" y="81" width="1" height="1" fill="currentColor" />
      <rect x="40" y="81" width="1" height="1" fill="currentColor" />
      <rect x="41" y="81" width="1" height="1" fill="currentColor" />
      <rect x="42" y="81" width="1" height="1" fill="currentColor" />
      <rect x="43" y="81" width="1" height="1" fill="currentColor" />
      <rect x="44" y="81" width="1" height="1" fill="currentColor" />
      <rect x="45" y="81" width="1" height="1" fill="currentColor" />
      <rect x="46" y="81" width="1" height="1" fill="currentColor" />
      <rect x="47" y="81" width="1" height="1" fill="currentColor" />
      <rect x="48" y="81" width="1" height="1" fill="currentColor" />
      <rect x="49" y="81" width="1" height="1" fill="currentColor" />
      <rect x="50" y="81" width="1" height="1" fill="currentColor" />
      <rect x="51" y="81" width="1" height="1" fill="currentColor" />
      <rect x="52" y="81" width="1" height="1" fill="currentColor" />
      <rect x="53" y="81" width="1" height="1" fill="currentColor" />
      <rect x="54" y="81" width="1" height="1" fill="currentColor" />
      <rect x="55" y="81" width="1" height="1" fill="currentColor" />
      <rect x="56" y="81" width="1" height="1" fill="currentColor" />
      <rect x="57" y="81" width="1" height="1" fill="currentColor" />
      <rect x="58" y="81" width="1" height="1" fill="currentColor" />
      <rect x="59" y="81" width="1" height="1" fill="currentColor" />
      <rect x="60" y="81" width="1" height="1" fill="currentColor" />
      <rect x="61" y="81" width="1" height="1" fill="currentColor" />
      <rect x="62" y="81" width="1" height="1" fill="currentColor" />
      <rect x="63" y="81" width="1" height="1" fill="currentColor" />
      <rect x="64" y="81" width="1" height="1" fill="currentColor" />
      <rect x="65" y="81" width="1" height="1" fill="currentColor" />
      <rect x="66" y="81" width="1" height="1" fill="currentColor" />
      <rect x="67" y="81" width="1" height="1" fill="currentColor" />
      <rect x="68" y="81" width="1" height="1" fill="currentColor" />
      <rect x="72" y="81" width="1" height="1" fill="currentColor" />
      <rect x="73" y="81" width="1" height="1" fill="currentColor" />
      <rect x="74" y="81" width="1" height="1" fill="currentColor" />
      <rect x="21" y="82" width="1" height="1" fill="currentColor" />
      <rect x="22" y="82" width="1" height="1" fill="currentColor" />
      <rect x="23" y="82" width="1" height="1" fill="currentColor" />
      <rect x="27" y="82" width="1" height="1" fill="currentColor" />
      <rect x="28" y="82" width="1" height="1" fill="currentColor" />
      <rect x="29" y="82" width="1" height="1" fill="currentColor" />
      <rect x="30" y="82" width="1" height="1" fill="currentColor" />
      <rect x="31" y="82" width="1" height="1" fill="currentColor" />
      <rect x="32" y="82" width="1" height="1" fill="currentColor" />
      <rect x="33" y="82" width="1" height="1" fill="currentColor" />
      <rect x="34" y="82" width="1" height="1" fill="currentColor" />
      <rect x="35" y="82" width="1" height="1" fill="currentColor" />
      <rect x="36" y="82" width="1" height="1" fill="currentColor" />
      <rect x="37" y="82" width="1" height="1" fill="currentColor" />
      <rect x="38" y="82" width="1" height="1" fill="currentColor" />
      <rect x="39" y="82" width="1" height="1" fill="currentColor" />
      <rect x="40" y="82" width="1" height="1" fill="currentColor" />
      <rect x="41" y="82" width="1" height="1" fill="currentColor" />
      <rect x="42" y="82" width="1" height="1" fill="currentColor" />
      <rect x="43" y="82" width="1" height="1" fill="currentColor" />
      <rect x="44" y="82" width="1" height="1" fill="currentColor" />
      <rect x="45" y="82" width="1" height="1" fill="currentColor" />
      <rect x="46" y="82" width="1" height="1" fill="currentColor" />
      <rect x="47" y="82" width="1" height="1" fill="currentColor" />
      <rect x="48" y="82" width="1" height="1" fill="currentColor" />
      <rect x="49" y="82" width="1" height="1" fill="currentColor" />
      <rect x="50" y="82" width="1" height="1" fill="currentColor" />
      <rect x="51" y="82" width="1" height="1" fill="currentColor" />
      <rect x="52" y="82" width="1" height="1" fill="currentColor" />
      <rect x="53" y="82" width="1" height="1" fill="currentColor" />
      <rect x="54" y="82" width="1" height="1" fill="currentColor" />
      <rect x="55" y="82" width="1" height="1" fill="currentColor" />
      <rect x="56" y="82" width="1" height="1" fill="currentColor" />
      <rect x="57" y="82" width="1" height="1" fill="currentColor" />
      <rect x="58" y="82" width="1" height="1" fill="currentColor" />
      <rect x="59" y="82" width="1" height="1" fill="currentColor" />
      <rect x="60" y="82" width="1" height="1" fill="currentColor" />
      <rect x="61" y="82" width="1" height="1" fill="currentColor" />
      <rect x="62" y="82" width="1" height="1" fill="currentColor" />
      <rect x="63" y="82" width="1" height="1" fill="currentColor" />
      <rect x="64" y="82" width="1" height="1" fill="currentColor" />
      <rect x="65" y="82" width="1" height="1" fill="currentColor" />
      <rect x="66" y="82" width="1" height="1" fill="currentColor" />
      <rect x="67" y="82" width="1" height="1" fill="currentColor" />
      <rect x="68" y="82" width="1" height="1" fill="currentColor" />
      <rect x="72" y="82" width="1" height="1" fill="currentColor" />
      <rect x="73" y="82" width="1" height="1" fill="currentColor" />
      <rect x="74" y="82" width="1" height="1" fill="currentColor" />
      <rect x="21" y="83" width="1" height="1" fill="currentColor" />
      <rect x="22" y="83" width="1" height="1" fill="currentColor" />
      <rect x="23" y="83" width="1" height="1" fill="currentColor" />
      <rect x="27" y="83" width="1" height="1" fill="currentColor" />
      <rect x="28" y="83" width="1" height="1" fill="currentColor" />
      <rect x="29" y="83" width="1" height="1" fill="currentColor" />
      <rect x="30" y="83" width="1" height="1" fill="currentColor" />
      <rect x="31" y="83" width="1" height="1" fill="currentColor" />
      <rect x="32" y="83" width="1" height="1" fill="currentColor" />
      <rect x="33" y="83" width="1" height="1" fill="currentColor" />
      <rect x="34" y="83" width="1" height="1" fill="currentColor" />
      <rect x="35" y="83" width="1" height="1" fill="currentColor" />
      <rect x="36" y="83" width="1" height="1" fill="currentColor" />
      <rect x="37" y="83" width="1" height="1" fill="currentColor" />
      <rect x="38" y="83" width="1" height="1" fill="currentColor" />
      <rect x="39" y="83" width="1" height="1" fill="currentColor" />
      <rect x="40" y="83" width="1" height="1" fill="currentColor" />
      <rect x="41" y="83" width="1" height="1" fill="currentColor" />
      <rect x="42" y="83" width="1" height="1" fill="currentColor" />
      <rect x="43" y="83" width="1" height="1" fill="currentColor" />
      <rect x="44" y="83" width="1" height="1" fill="currentColor" />
      <rect x="45" y="83" width="1" height="1" fill="currentColor" />
      <rect x="46" y="83" width="1" height="1" fill="currentColor" />
      <rect x="47" y="83" width="1" height="1" fill="currentColor" />
      <rect x="48" y="83" width="1" height="1" fill="currentColor" />
      <rect x="49" y="83" width="1" height="1" fill="currentColor" />
      <rect x="50" y="83" width="1" height="1" fill="currentColor" />
      <rect x="51" y="83" width="1" height="1" fill="currentColor" />
      <rect x="52" y="83" width="1" height="1" fill="currentColor" />
      <rect x="53" y="83" width="1" height="1" fill="currentColor" />
      <rect x="54" y="83" width="1" height="1" fill="currentColor" />
      <rect x="55" y="83" width="1" height="1" fill="currentColor" />
      <rect x="56" y="83" width="1" height="1" fill="currentColor" />
      <rect x="57" y="83" width="1" height="1" fill="currentColor" />
      <rect x="58" y="83" width="1" height="1" fill="currentColor" />
      <rect x="59" y="83" width="1" height="1" fill="currentColor" />
      <rect x="60" y="83" width="1" height="1" fill="currentColor" />
      <rect x="61" y="83" width="1" height="1" fill="currentColor" />
      <rect x="62" y="83" width="1" height="1" fill="currentColor" />
      <rect x="63" y="83" width="1" height="1" fill="currentColor" />
      <rect x="64" y="83" width="1" height="1" fill="currentColor" />
      <rect x="65" y="83" width="1" height="1" fill="currentColor" />
      <rect x="66" y="83" width="1" height="1" fill="currentColor" />
      <rect x="67" y="83" width="1" height="1" fill="currentColor" />
      <rect x="68" y="83" width="1" height="1" fill="currentColor" />
      <rect x="72" y="83" width="1" height="1" fill="currentColor" />
      <rect x="73" y="83" width="1" height="1" fill="currentColor" />
      <rect x="74" y="83" width="1" height="1" fill="currentColor" />
      <rect x="21" y="84" width="1" height="1" fill="currentColor" />
      <rect x="22" y="84" width="1" height="1" fill="currentColor" />
      <rect x="23" y="84" width="1" height="1" fill="currentColor" />
      <rect x="72" y="84" width="1" height="1" fill="currentColor" />
      <rect x="73" y="84" width="1" height="1" fill="currentColor" />
      <rect x="74" y="84" width="1" height="1" fill="currentColor" />
      <rect x="21" y="85" width="1" height="1" fill="currentColor" />
      <rect x="22" y="85" width="1" height="1" fill="currentColor" />
      <rect x="23" y="85" width="1" height="1" fill="currentColor" />
      <rect x="72" y="85" width="1" height="1" fill="currentColor" />
      <rect x="73" y="85" width="1" height="1" fill="currentColor" />
      <rect x="74" y="85" width="1" height="1" fill="currentColor" />
      <rect x="21" y="86" width="1" height="1" fill="currentColor" />
      <rect x="22" y="86" width="1" height="1" fill="currentColor" />
      <rect x="23" y="86" width="1" height="1" fill="currentColor" />
      <rect x="72" y="86" width="1" height="1" fill="currentColor" />
      <rect x="73" y="86" width="1" height="1" fill="currentColor" />
      <rect x="74" y="86" width="1" height="1" fill="currentColor" />
      <rect x="24" y="87" width="1" height="1" fill="currentColor" />
      <rect x="25" y="87" width="1" height="1" fill="currentColor" />
      <rect x="26" y="87" width="1" height="1" fill="currentColor" />
      <rect x="27" y="87" width="1" height="1" fill="currentColor" />
      <rect x="28" y="87" width="1" height="1" fill="currentColor" />
      <rect x="29" y="87" width="1" height="1" fill="currentColor" />
      <rect x="30" y="87" width="1" height="1" fill="currentColor" />
      <rect x="31" y="87" width="1" height="1" fill="currentColor" />
      <rect x="32" y="87" width="1" height="1" fill="currentColor" />
      <rect x="33" y="87" width="1" height="1" fill="currentColor" />
      <rect x="34" y="87" width="1" height="1" fill="currentColor" />
      <rect x="35" y="87" width="1" height="1" fill="currentColor" />
      <rect x="36" y="87" width="1" height="1" fill="currentColor" />
      <rect x="37" y="87" width="1" height="1" fill="currentColor" />
      <rect x="38" y="87" width="1" height="1" fill="currentColor" />
      <rect x="39" y="87" width="1" height="1" fill="currentColor" />
      <rect x="40" y="87" width="1" height="1" fill="currentColor" />
      <rect x="41" y="87" width="1" height="1" fill="currentColor" />
      <rect x="42" y="87" width="1" height="1" fill="currentColor" />
      <rect x="43" y="87" width="1" height="1" fill="currentColor" />
      <rect x="44" y="87" width="1" height="1" fill="currentColor" />
      <rect x="45" y="87" width="1" height="1" fill="currentColor" />
      <rect x="46" y="87" width="1" height="1" fill="currentColor" />
      <rect x="47" y="87" width="1" height="1" fill="currentColor" />
      <rect x="48" y="87" width="1" height="1" fill="currentColor" />
      <rect x="49" y="87" width="1" height="1" fill="currentColor" />
      <rect x="50" y="87" width="1" height="1" fill="currentColor" />
      <rect x="51" y="87" width="1" height="1" fill="currentColor" />
      <rect x="52" y="87" width="1" height="1" fill="currentColor" />
      <rect x="53" y="87" width="1" height="1" fill="currentColor" />
      <rect x="54" y="87" width="1" height="1" fill="currentColor" />
      <rect x="55" y="87" width="1" height="1" fill="currentColor" />
      <rect x="56" y="87" width="1" height="1" fill="currentColor" />
      <rect x="57" y="87" width="1" height="1" fill="currentColor" />
      <rect x="58" y="87" width="1" height="1" fill="currentColor" />
      <rect x="59" y="87" width="1" height="1" fill="currentColor" />
      <rect x="60" y="87" width="1" height="1" fill="currentColor" />
      <rect x="61" y="87" width="1" height="1" fill="currentColor" />
      <rect x="62" y="87" width="1" height="1" fill="currentColor" />
      <rect x="63" y="87" width="1" height="1" fill="currentColor" />
      <rect x="64" y="87" width="1" height="1" fill="currentColor" />
      <rect x="65" y="87" width="1" height="1" fill="currentColor" />
      <rect x="66" y="87" width="1" height="1" fill="currentColor" />
      <rect x="67" y="87" width="1" height="1" fill="currentColor" />
      <rect x="68" y="87" width="1" height="1" fill="currentColor" />
      <rect x="69" y="87" width="1" height="1" fill="currentColor" />
      <rect x="70" y="87" width="1" height="1" fill="currentColor" />
      <rect x="71" y="87" width="1" height="1" fill="currentColor" />
      <rect x="24" y="88" width="1" height="1" fill="currentColor" />
      <rect x="25" y="88" width="1" height="1" fill="currentColor" />
      <rect x="26" y="88" width="1" height="1" fill="currentColor" />
      <rect x="27" y="88" width="1" height="1" fill="currentColor" />
      <rect x="28" y="88" width="1" height="1" fill="currentColor" />
      <rect x="29" y="88" width="1" height="1" fill="currentColor" />
      <rect x="30" y="88" width="1" height="1" fill="currentColor" />
      <rect x="31" y="88" width="1" height="1" fill="currentColor" />
      <rect x="32" y="88" width="1" height="1" fill="currentColor" />
      <rect x="33" y="88" width="1" height="1" fill="currentColor" />
      <rect x="34" y="88" width="1" height="1" fill="currentColor" />
      <rect x="35" y="88" width="1" height="1" fill="currentColor" />
      <rect x="36" y="88" width="1" height="1" fill="currentColor" />
      <rect x="37" y="88" width="1" height="1" fill="currentColor" />
      <rect x="38" y="88" width="1" height="1" fill="currentColor" />
      <rect x="39" y="88" width="1" height="1" fill="currentColor" />
      <rect x="40" y="88" width="1" height="1" fill="currentColor" />
      <rect x="41" y="88" width="1" height="1" fill="currentColor" />
      <rect x="42" y="88" width="1" height="1" fill="currentColor" />
      <rect x="43" y="88" width="1" height="1" fill="currentColor" />
      <rect x="44" y="88" width="1" height="1" fill="currentColor" />
      <rect x="45" y="88" width="1" height="1" fill="currentColor" />
      <rect x="46" y="88" width="1" height="1" fill="currentColor" />
      <rect x="47" y="88" width="1" height="1" fill="currentColor" />
      <rect x="48" y="88" width="1" height="1" fill="currentColor" />
      <rect x="49" y="88" width="1" height="1" fill="currentColor" />
      <rect x="50" y="88" width="1" height="1" fill="currentColor" />
      <rect x="51" y="88" width="1" height="1" fill="currentColor" />
      <rect x="52" y="88" width="1" height="1" fill="currentColor" />
      <rect x="53" y="88" width="1" height="1" fill="currentColor" />
      <rect x="54" y="88" width="1" height="1" fill="currentColor" />
      <rect x="55" y="88" width="1" height="1" fill="currentColor" />
      <rect x="56" y="88" width="1" height="1" fill="currentColor" />
      <rect x="57" y="88" width="1" height="1" fill="currentColor" />
      <rect x="58" y="88" width="1" height="1" fill="currentColor" />
      <rect x="59" y="88" width="1" height="1" fill="currentColor" />
      <rect x="60" y="88" width="1" height="1" fill="currentColor" />
      <rect x="61" y="88" width="1" height="1" fill="currentColor" />
      <rect x="62" y="88" width="1" height="1" fill="currentColor" />
      <rect x="63" y="88" width="1" height="1" fill="currentColor" />
      <rect x="64" y="88" width="1" height="1" fill="currentColor" />
      <rect x="65" y="88" width="1" height="1" fill="currentColor" />
      <rect x="66" y="88" width="1" height="1" fill="currentColor" />
      <rect x="67" y="88" width="1" height="1" fill="currentColor" />
      <rect x="68" y="88" width="1" height="1" fill="currentColor" />
      <rect x="69" y="88" width="1" height="1" fill="currentColor" />
      <rect x="70" y="88" width="1" height="1" fill="currentColor" />
      <rect x="71" y="88" width="1" height="1" fill="currentColor" />
      <rect x="24" y="89" width="1" height="1" fill="currentColor" />
      <rect x="25" y="89" width="1" height="1" fill="currentColor" />
      <rect x="26" y="89" width="1" height="1" fill="currentColor" />
      <rect x="27" y="89" width="1" height="1" fill="currentColor" />
      <rect x="28" y="89" width="1" height="1" fill="currentColor" />
      <rect x="29" y="89" width="1" height="1" fill="currentColor" />
      <rect x="30" y="89" width="1" height="1" fill="currentColor" />
      <rect x="31" y="89" width="1" height="1" fill="currentColor" />
      <rect x="32" y="89" width="1" height="1" fill="currentColor" />
      <rect x="33" y="89" width="1" height="1" fill="currentColor" />
      <rect x="34" y="89" width="1" height="1" fill="currentColor" />
      <rect x="35" y="89" width="1" height="1" fill="currentColor" />
      <rect x="36" y="89" width="1" height="1" fill="currentColor" />
      <rect x="37" y="89" width="1" height="1" fill="currentColor" />
      <rect x="38" y="89" width="1" height="1" fill="currentColor" />
      <rect x="39" y="89" width="1" height="1" fill="currentColor" />
      <rect x="40" y="89" width="1" height="1" fill="currentColor" />
      <rect x="41" y="89" width="1" height="1" fill="currentColor" />
      <rect x="42" y="89" width="1" height="1" fill="currentColor" />
      <rect x="43" y="89" width="1" height="1" fill="currentColor" />
      <rect x="44" y="89" width="1" height="1" fill="currentColor" />
      <rect x="45" y="89" width="1" height="1" fill="currentColor" />
      <rect x="46" y="89" width="1" height="1" fill="currentColor" />
      <rect x="47" y="89" width="1" height="1" fill="currentColor" />
      <rect x="48" y="89" width="1" height="1" fill="currentColor" />
      <rect x="49" y="89" width="1" height="1" fill="currentColor" />
      <rect x="50" y="89" width="1" height="1" fill="currentColor" />
      <rect x="51" y="89" width="1" height="1" fill="currentColor" />
      <rect x="52" y="89" width="1" height="1" fill="currentColor" />
      <rect x="53" y="89" width="1" height="1" fill="currentColor" />
      <rect x="54" y="89" width="1" height="1" fill="currentColor" />
      <rect x="55" y="89" width="1" height="1" fill="currentColor" />
      <rect x="56" y="89" width="1" height="1" fill="currentColor" />
      <rect x="57" y="89" width="1" height="1" fill="currentColor" />
      <rect x="58" y="89" width="1" height="1" fill="currentColor" />
      <rect x="59" y="89" width="1" height="1" fill="currentColor" />
      <rect x="60" y="89" width="1" height="1" fill="currentColor" />
      <rect x="61" y="89" width="1" height="1" fill="currentColor" />
      <rect x="62" y="89" width="1" height="1" fill="currentColor" />
      <rect x="63" y="89" width="1" height="1" fill="currentColor" />
      <rect x="64" y="89" width="1" height="1" fill="currentColor" />
      <rect x="65" y="89" width="1" height="1" fill="currentColor" />
      <rect x="66" y="89" width="1" height="1" fill="currentColor" />
      <rect x="67" y="89" width="1" height="1" fill="currentColor" />
      <rect x="68" y="89" width="1" height="1" fill="currentColor" />
      <rect x="69" y="89" width="1" height="1" fill="currentColor" />
      <rect x="70" y="89" width="1" height="1" fill="currentColor" />
      <rect x="71" y="89" width="1" height="1" fill="currentColor" />
    </svg>
  );
}
