import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TDirection, TFilters } from "../types/websites-settings";
import { TCurrency } from "../types/admin";
import { TSelect } from "../types/common";

interface WebsiteStoreState {
  websiteCurrencies: TCurrency[] | null;
  setWebsiteCurrencies: (currencies: TCurrency[] | null) => void;
  directions: TDirection[] | null;
  setDirections: (directions: TDirection[] | null) => void;
  directionsTotal: number | null;
  setDirectionsTotal: (directionsTotal: number | null) => void;
  filters: TFilters;
  setFilters: (attr: string, value: null | TSelect[]) => void;
}

const initialState = {
  websiteCurrencies: null,
  directions: null,
  directionsTotal: null,
  filters: {
    from: null,
    to: null,
    cities: null,
  },
};

export const useWebsiteStore = create<WebsiteStoreState>()(
  persist(
    (set, get) => ({
      ...initialState,
      setWebsiteCurrencies: (websiteCurrencies) => set({ websiteCurrencies }),
      setDirections: (directions) => set({ directions }),
      setDirectionsTotal: (directionsTotal) => set({ directionsTotal }),
      setFilters: (attr, value) =>
        set({
          filters: {
            ...get().filters,
            [attr]: value,
          },
        }),
    }),
    {
      name: "websiteStore",
    }
  )
);
