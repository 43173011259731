import { AxiosResponse } from "axios";
import { TUser } from "src/shared/types/admin";
import { TLogin } from "src/shared/types/auth";
import { BASE_URL } from "../../constants";
import api from "../api-instance";

export default class authService {
  static async login(data: TLogin): Promise<AxiosResponse<TUser>> {
    return api.post(`${BASE_URL}/v1/auth/login`, data);
  }
  static async logout() {
    return api.post(`${BASE_URL}/v1/auth/logout`);
  }
}
