import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { TDirection } from "src/shared/types/websites-settings";

export const DirectionReserveCard = ({
  item,
}: // isEditManualCourse,
{
  item: TDirection;
  // isEditManualCourse: boolean;
}) => {
  const lang_attr = "RU";
  const city_name = item.city.localizations.find((el) => el.locale_code === lang_attr)?.name;

  return (
    <Card className={`flex flex-col gap-2 p-2 pl-4 text-xs sm:text-sm`}>
      <div className={cn("flex flex-col sm:grid grid-cols-2 sm:items-center gap-3 min-h-10")}>
        <div className="flex flex-col">
          <p>{city_name}</p>
          <div className="flex gap-2 justify-between max-w-[200px] font-semibold items-center">
            <p>{item.currency_from.currency_code}</p>
            <p className="w-full border-b-4 border-dotted" />
            <p>{item.currency_to.currency_code}</p>
          </div>
        </div>
        <div>
          {/* {(item.direction_course_is_manual || isEditManualCourse) && (
            <>
              <InputWithLabel
                label={`Ручной курс from`}
                name={`${item.direction_id}.direction_manual_course_from`}
                type="number"
                variant="small"
              />
              <InputWithLabel
                label={`Ручной курс to`}
                name={`${item.direction_id}.direction_manual_course_to`}
                type="number"
                variant="small"
              />
            </>
          )} */}
          <InputWithLabel
            label={`MIN сумма`}
            name={`${item.direction_id}.min_from`}
            type="number"
            variant="small"
          />
          <InputWithLabel
            label={`MAX сумма`}
            name={`${item.direction_id}.max_from`}
            type="number"
            variant="small"
          />
          <InputWithLabel
            label={`Резерв`}
            name={`${item.direction_id}.reserve`}
            type="number"
            variant="small"
          />
        </div>
      </div>
    </Card>
  );
};
