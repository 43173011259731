import { TBid, TBidsPagination, TUpdateBid } from "src/shared/types/bids";
import bidsService from "../../services/bids-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";

export const useBids = (website_slug: string, params: string) =>
  useBaseQuery<null, TBidsPagination>(
    ["get-bids", website_slug, params],
    () => bidsService.getBids(website_slug, params),
    !!website_slug?.length
  );

export const useUpdateBid = (website_slug: string) =>
  useBaseMutation<TUpdateBid, TBid>(
    ["update-bid"],
    (payload: TUpdateBid) => bidsService.updateBid(website_slug, payload),
    {
      403: "Недостаточно прав",
      404: "Заявка не найдена",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );
