import { Dispatch, SetStateAction, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { CloseButton } from "@/components/ui/close-button";
import { Card, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { TCreateSite, TWebsite } from "src/shared/types/admin";
import { useCreateWebsite, useUpdateWebsite } from "src/shared/api/query/use-admin/use-websites";
import { useAuthStore } from "src/shared/store/auth-store";
import { string_schema } from "src/shared/validation/field-schemas";

const FormSchema = z.object({
  website_name: string_schema,
  website_url: string_schema,
  website_slug: string_schema,
});

export const WebsitesForm = ({
  selectedSite,
  setSelectedSite,
}: {
  selectedSite: TWebsite | null;
  setSelectedSite: Dispatch<SetStateAction<TWebsite | null>>;
}) => {
  const { toast } = useToast();
  const defaultValues = {
    website_name: "",
    website_url: "",
    website_slug: "",
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });
  const { websites, setWebsites } = useAuthStore();
  const { mutateAsync: createWebsite, isPending: isCreatePending } = useCreateWebsite();
  const { mutateAsync: updateWebsite, isPending: isUpdatePending } = useUpdateWebsite();

  const onSubmit = async (e: TCreateSite) => {
    if (!!selectedSite) {
      const data = await updateWebsite(e);
      if (data && !!websites) {
        toast({
          title: "Сайты",
          description: "Данные успешно обновлены",
        });
        setWebsites(
          websites.map((el) => (el.website_slug === selectedSite.website_slug ? data : el))
        );
      }
    } else {
      const data = await createWebsite(e);
      if (data) {
        toast({
          title: "Сайты",
          description: "Сайт успешно добавлен",
        });
        if (!!websites) {
          setWebsites([...websites, data]);
        } else {
          setWebsites([data]);
        }
        form.reset(defaultValues);
      }
    }
  };

  useEffect(() => {
    if (selectedSite) {
      form.reset(selectedSite);
    } else {
      form.reset(defaultValues);
    }
  }, [selectedSite]);

  return (
    <Card className="lg:sticky top-4 min-w-72 p-4 h-fit">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
          <CardTitle className="text-lg flex items-center justify-between h-10">
            {!!selectedSite ? selectedSite.website_name : "Новый сайт"}
            {!!selectedSite && <CloseButton onClick={() => setSelectedSite(null)} />}
          </CardTitle>
          <div className="w-full space-y-2">
            <InputWithLabel label="Название сайта" name="website_name" required />
            <InputWithLabel label="URL" name="website_url" required />
            <InputWithLabel
              label="Уникальный идентификатор"
              name="website_slug"
              disabled={!!selectedSite}
              required
            />
          </div>
          <Button type="submit" disabled={isCreatePending || isUpdatePending}>
            {!!selectedSite ? "Обновить" : "Добавить сайт"}
          </Button>
        </form>
      </Form>
    </Card>
  );
};
