import { DeleteButton } from "@/components/ui/delete-button";
import { cn } from "@/lib/utils";
import { ECurrencyType } from "src/shared/types/admin";
import { TSelectedPair } from "src/shared/types/websites-settings";

interface SelectedPairCardProps {
  selectedPair: TSelectedPair;
  onDelete: () => void;
}

const SelectedPairCard = ({ selectedPair, onDelete }: SelectedPairCardProps) => {
  const lang_attr = "RU";
  return (
    <div className="flex justify-between items-center gap-4 bg-secondary p-1 pl-2 rounded-md">
      <div className={cn("grid grid-cols-[80px_1fr_1fr] gap-8 w-full")}>
        <div className="text-left whitespace-nowrap">
          {!!selectedPair?.city
            ? selectedPair?.city?.localizations?.find((el) => el.locale_code === lang_attr)?.name
            : selectedPair?.from?.currency_type === ECurrencyType.CARD ||
              selectedPair?.to?.currency_type === ECurrencyType.CARD
            ? "Авто"
            : ""}
        </div>
        <div>{selectedPair?.from?.currency_code}</div>
        <div>{selectedPair?.to?.currency_code}</div>
      </div>
      <DeleteButton onClick={onDelete} />
    </div>
  );
};

export default SelectedPairCard;
