import { Dispatch, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import { navigation } from "src/app/routing/routes";
import { useAuthStore } from "src/shared/store/auth-store";
import { NavIcon } from "src/widgets/navigation/nav-icon";
import { Chevron } from "src/shared/image/chevron";

export const Sidebar = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const pathname = useLocation().pathname;
  const { me } = useAuthStore();

  return (
    <div
      className={`burger h-full font-normal duration-700 bg-secondary border-r-2 dark:border-background text-foreground ${
        isOpen
          ? "w-[200px] max-w-[200px] px-2"
          : "w-px max-w-px sm:w-[60px] sm:max-w-[60px] px-0  sm:px-2"
      } h-full py-1 text-left z-[100]`}>
      <button
        type="button"
        className="hidden md:flex justify-center shrink-0 items-center w-6 h-6 m-2 rounded-full bg-background p-1"
        onClick={() => {
          setIsOpen(!isOpen);
        }}>
        <Chevron className={`duration-300 w-5 h-5 ${isOpen ? "rotate-180" : "rotate-0"}`} />
      </button>
      <div className={`flex flex-col duration-500 overflow-hidden`}>
        {navigation
          ?.filter(
            (el) => !!me && (!el?.permissions || el.permissions?.includes(me?.user_role_name))
          )
          ?.map((el) => (
            <NavIcon key={el.path} item={el} pathname={pathname} />
          ))}
      </div>
    </div>
  );
};
