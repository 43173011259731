export const Glasses = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      fill="currentColor">
      <g>
        <path d="M94.996,57.046c-0.001-0.155-0.02-0.312-0.072-0.468l-8.611-25.47c-1.264-3.738-4.272-6.653-8.048-7.798l-6.367-1.932   c-0.795-0.242-1.63,0.207-1.871,1c-0.24,0.793,0.208,1.631,1,1.871l6.368,1.932c2.851,0.864,5.123,3.065,6.077,5.888l8.445,24.976   H8.085l8.445-24.976c0.954-2.822,3.226-5.023,6.077-5.888l6.367-1.932c0.792-0.24,1.24-1.078,1-1.871   c-0.241-0.793-1.077-1.24-1.871-1l-6.367,1.932c-3.776,1.145-6.785,4.06-8.048,7.798l-8.611,25.47   c-0.052,0.155-0.071,0.312-0.072,0.468H5v6.893h5.383v4.916h5.311v4.916h3.593v4.916h18.111v-4.916h3.593v-4.916h5.311v-4.916   h7.395v4.916h5.311v4.916h3.593v4.916h18.111v-4.916h3.593v-4.916h5.311v-4.916H95v-6.893H94.996z" />
      </g>
    </svg>
  );
};
