import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TWebsite, TUser } from "../types/admin";

interface AuthStoreState {
  me: TUser | null;
  setMe: (me: TUser | null) => void;
  websites: TWebsite[] | null;
  setWebsites: (websites: TWebsite[] | null) => void;
}

const initialState = {
  me: null,
  websites: null,
};

export const useAuthStore = create<AuthStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setMe: (me) => set({ me }),
      setWebsites: (websites) => set({ websites }),
    }),
    {
      name: "authStore",
    }
  )
);
