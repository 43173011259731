export const NavTab = ({
  title,
  isActive,
  onClick,
}: {
  title: string;
  isActive: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={`font-semibold flex flex-col w-full sm:w-[150px] justify-center duration-300 hover:opacity-80 cursor-pointer  gap-4 h-[70px] ${
        isActive ? "opacity-80" : "opacity-40"
      }`}
      onClick={onClick}>
      <div
        className={`flex w-full items-center p-4 rounded-md duration-300 max-h-[70px] h-[70px] bg-background text-foreground whitespace-pre-line`}>
        {title}
      </div>
    </div>
  );
};
