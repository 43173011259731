import { Emptiness } from "@/components/ui/emptiness";
import { Loader } from "@/components/ui/loader";
import { useBidsStore } from "src/shared/store/bids-store";
import { EBidStatus } from "src/shared/types/bids";
import { PaginationButtons } from "@/components/ui/pagination-buttons";
import { BidColumn } from "./bid-column";
import { useEffect } from "react";

export const BidsList = ({
  isPending,
  website_slug,
}: {
  isPending: boolean;
  website_slug: string;
}) => {
  const { bids, bidsTotal } = useBidsStore();

  const limit = 20;
  const totalPages = Math.ceil((bidsTotal || 1) / limit);

  const columns = [
    {
      status: EBidStatus.CREATED,
      title: "Новые",
    },
    {
      status: EBidStatus.DONE,
      title: "Исполненные",
    },
    {
      status: EBidStatus.DELETED,
      title: "Удаленные",
    },
  ];

  const scrollListener = (list: HTMLElement) => {
    list.scrollIntoView();
  };

  useEffect(() => {
    const list = document.getElementById("list");
    if (!list) {
      return;
    }
    list.addEventListener("scrollend", () => scrollListener(list));
    return () => list.removeEventListener("scrollend", () => scrollListener(list));
  }, [bids?.length]);

  return (
    <div className="w-full flex flex-col gap-1">
      {isPending ? (
        <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
          <Loader />
        </div>
      ) : !!bids?.length ? (
        <>
          <div
            id="list"
            className="relative w-full max-w-[calc(100vw-32px)] overflow-x-auto snap-x snap-always snap-mandatory scroll-auto">
            <div className="flex gap-2">
              {columns?.map((status) => {
                const items =
                  status.status === EBidStatus.CREATED
                    ? bids?.filter(
                        (el) =>
                          el.bid_status !== EBidStatus.DONE && el.bid_status !== EBidStatus.DELETED
                      )
                    : bids?.filter((el) => el.bid_status === status.status);
                return (
                  <BidColumn
                    key={status.status}
                    title={status.title}
                    items={items}
                    website_slug={website_slug}
                  />
                );
              })}
            </div>
          </div>
          {totalPages > 1 && (
            <div className="sticky bottom-0">
              <PaginationButtons totalPages={totalPages} />
            </div>
          )}
        </>
      ) : (
        <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
          <Emptiness message="Заявки по выбранным фильтрам не найдены" />
        </div>
      )}
    </div>
  );
};
