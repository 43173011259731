export default function Update() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-2 -2 30 30"
      x="0px"
      y="0px"
      width={40}
      height={40}
      fill="currentColor">
      <path d="M21.6667 6.5V5.41667H20.5833V4.33333H19.5V3.25H8.66667V4.33333H7.58333V5.41667H6.5V6.5H5.41667V5.41667V4.33333H3.25V10.8333H9.75V8.66667H7.58333V7.58333H8.66667V6.5H9.75V5.41667H17.3333V6.5H18.4167V7.58333H19.5V8.66667H20.5833V17.3333H19.5V18.4167H18.4167V19.5H17.3333V20.5833H9.75V19.5H8.66667V18.4167H7.58333V17.3333H6.5V16.25H4.33333V19.5H5.41667V20.5833H6.5V21.6667H7.58333V22.75H19.5V21.6667H20.5833V20.5833H21.6667V19.5H22.75V6.5H21.6667Z" />
    </svg>
  );
}
