import { useCallback, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useToast } from "@/components/ui/use-toast";
import { ModalButton } from "@/components/ui/modal-button";
import { TCity, TCreateDirection, TPairs, TSelectedPair } from "src/shared/types/websites-settings";
import { createAllCombinations } from "src/shared/utils/directions-adder";
import { useCreateDirection } from "src/shared/api/query/use-website-settings/use-directions";
import { useWebsiteCurrencies } from "src/shared/api/query/use-website-settings/use-currencies";
import { useAdminStore } from "src/shared/store/admin-store";
import { useWebsiteStore } from "src/shared/store/website-store";
import { CurrencyColumn } from "../components/currency-column";
import { DirectionsPreview } from "./components/directions-preview";

export const DirectionsAdder = ({ website_slug }: { website_slug: string }) => {
  const { toast } = useToast();
  const { countries } = useAdminStore();
  const { setWebsiteCurrencies, directions, setDirections } = useWebsiteStore();
  const [selectedPairs, setSelectedPairs] = useState<TSelectedPair[]>([]);
  const [noValidVectors, setNoValidVectors] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState<number[]>([]);
  const [cities, setCities] = useState<TCity[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pairs, setPairs] = useState<TPairs>({
    from: [],
    to: [],
  });

  const { data: websiteCurrenciesData } = useWebsiteCurrencies(website_slug || "");

  const { mutateAsync: createDirections, isPending } = useCreateDirection(website_slug);

  const lang_attr = "RU";

  const cityAdd = (city: Partial<TCity>) => {
    const newData = [...cities];
    const existingCityIndex = newData.findIndex((item) => item.city_code === city.city_code);
    if (~existingCityIndex) {
      newData.splice(existingCityIndex, 1);
    } else {
      newData.push(city as TCity);
    }
    setCities(newData);
  };

  const createCombinations = useCallback(() => {
    const { combinations, noValidVectors } = createAllCombinations({
      cities,
      currencies_from: pairs.from,
      currencies_to: pairs.to,
    });
    setSelectedPairs(combinations as TSelectedPair[]);
    setNoValidVectors(noValidVectors);
  }, [pairs, cities]);

  useEffect(() => {
    createCombinations();
  }, [pairs, cities]);

  const openCreateVectors = () => {
    setIsModalOpen(true);
    createCombinations();
  };

  const submit = async () => {
    const payload: TCreateDirection[] = selectedPairs?.map((el) => ({
      city_code: el.city?.city_code || "",
      currency_from_code: el.from?.currency_code || "",
      currency_to_code: el.to?.currency_code || "",
      min_from: 0.0000001,
      max_from: 1000000,
      reserve: 1000000,
      xml_value: [],
    }));
    const data = await createDirections(payload);
    if (data) {
      toast({
        title: "Направления",
        description: "Направления успешно добавлены",
      });
      setSelectedPairs([]);
      setPairs({
        from: [],
        to: [],
      });
      setIsModalOpen(false);
      if (!!directions) {
        const newDirections = data?.filter(
          (el) =>
            !directions?.some(
              (dir) =>
                `${el.city.city_code}_${el.currency_from?.currency_code}_${el.currency_to?.currency_code}` ===
                `${dir.city.city_code}_${dir.currency_from?.currency_code}_${dir.currency_to?.currency_code}`
            )
        );
        setDirections(directions.concat(newDirections));
      } else {
        setDirections(data);
      }
    }
  };

  const noItemsSelected = cities.length === 0 || !pairs.from.length || !pairs.to.length;
  const openCreateVectorsDisabled = noItemsSelected || noValidVectors;
  const submitCreateVectorsDisabled = selectedPairs.length === 0;
  const emptyPairsMessage = noItemsSelected
    ? "Вы еще не создали векторы"
    : noValidVectors
    ? "Нет выбранных валидных комбинаций"
    : "";

  useEffect(() => {
    if (!isPending) {
      if (!!websiteCurrenciesData) {
        setWebsiteCurrencies(websiteCurrenciesData);
      } else {
        setWebsiteCurrencies(null);
      }
    }
  }, [websiteCurrenciesData]);

  return (
    <>
      <div className="flex flex-col gap-[10px] pb-[30px]">
        <div className="flex items-center justify-between gap-1 flex-wrap border rounded-md p-1">
          {!!emptyPairsMessage?.length ? (
            <small className="border border-red-500 text-red-500 rounded-md px-2 py-1 md:ml-1 text-muted-foreground w-fit">
              {emptyPairsMessage}
            </small>
          ) : (
            <p />
          )}
          <div className="flex items-center gap-2.5">
            {!noItemsSelected && (
              <Button
                type="button"
                onClick={() => {
                  setCities([]);
                  setPairs({
                    from: [],
                    to: [],
                  });
                }}
                size="sm"
                variant="outline"
                className="px-[10px]">
                Сбросить выбор
              </Button>
            )}
            <ModalButton
              open={isModalOpen}
              setOpen={setIsModalOpen}
              triggerButton={
                <Button
                  type="button"
                  size="sm"
                  className="px-[10px]"
                  disabled={openCreateVectorsDisabled}
                  onClick={openCreateVectors}>
                  Создать векторы
                </Button>
              }>
              <DirectionsPreview
                isLoading={isPending}
                disabled={submitCreateVectorsDisabled || isPending}
                emptyPairsMessage={emptyPairsMessage}
                selectedPairs={selectedPairs}
                setSelectedPairs={setSelectedPairs}
                submit={submit}
              />
            </ModalButton>
          </div>
        </div>
        <div className="flex flex-wrap gap-1 p-1 md:w-[640px] md:max-w-[640px] rounded-sm bg-secondary">
          {countries?.map((country, index) => {
            const isSelected = !!selectedCountries?.some((item) => item === country.country_id);
            const country_name = country?.localizations?.find(
              (country) => country.locale_code === lang_attr
            )?.name;
            return (
              <Button
                key={index}
                id={country?.country_id?.toString()}
                type="button"
                variant={isSelected ? "default" : "outline"}
                onClick={() => {
                  if (isSelected) {
                    setCities(cities?.filter((el) => el.country_id !== country.country_id));
                    setSelectedCountries(
                      selectedCountries.filter((el) => el !== country.country_id)
                    );
                  } else {
                    const newCities = country.cities?.filter(
                      (el) => !cities.some((city) => city.city_id === el.city_id)
                    );
                    setCities(cities?.concat(newCities));
                    setSelectedCountries(selectedCountries.concat([country.country_id]));
                  }
                }}>
                {country_name}
              </Button>
            );
          })}
        </div>
        <div className="relative min-w-fit w-full md:w-[640px] md:max-w-[640px] lg:h-[75vh] lg:max-h-none h-[900px] overflow-y-hidden rounded-sm bg-secondary">
          <div className="flex flex-col lg:flex-row h-full overflow-hidden p-1 divide-y-4 md:divide-none divide-yellow-400">
            <div
              id="cities"
              className="w-full h-full flex flex-col overflow-hidden px-1 pb-4 md:p-0 md:max-w-[300px]">
              <div className="sticky top-0 flex flex-col gap-1 z-10">
                {!!cities?.length && (
                  <div className="absolute flex top-1 right-1.5 bg-foreground text-background items-center rounded-full text-[10px] leading-0 font-bold w-fit min-w-4 h-4 px-[5px] z-[30]">
                    {cities.length}
                  </div>
                )}
              </div>
              <ScrollArea>
                <div className="flex flex-col gap-1 w-full">
                  {countries?.map((country, country_index) => {
                    const country_name = country?.localizations?.find(
                      (locale) => locale.locale_code === lang_attr
                    )?.name;

                    return (
                      <div key={country_index} className="flex flex-col gap-1 w-full">
                        <div className="p-2 pb-1 text-neutral-500">{country_name}</div>
                        {country.cities?.map((el, index) => {
                          const city_name = el?.localizations?.find(
                            (locale) => locale.locale_code === lang_attr
                          )?.name;

                          return (
                            <Button
                              key={`${country_index}_${index}`}
                              id={el?.city_code}
                              type="button"
                              variant={
                                cities?.some((item) => item.city_code === el.city_code)
                                  ? "default"
                                  : "outline"
                              }
                              onClick={() => cityAdd(el)}>
                              {city_name}
                            </Button>
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              </ScrollArea>
            </div>
            <div
              id="vectors"
              className="grid grid-cols-2 w-full mx-auto min-h-[300px] flex-col overflow-hidden pt-4 md:pt-0">
              <div className="overflow-y-auto min-h-[300px]">
                <CurrencyColumn directionType="from" pairs={pairs} setPairs={setPairs} />
              </div>
              <div className="overflow-y-auto">
                <CurrencyColumn directionType="to" pairs={pairs} setPairs={setPairs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
