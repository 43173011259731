import { TBid } from "src/shared/types/bids";
import { BidCard } from "./bid-card";

export const BidColumn = ({
  title,
  items,
  website_slug,
}: {
  title: string;
  items: TBid[];
  website_slug: string;
}) => {
  return (
    <div className="snap-center h-fit lg:h-full min-w-[calc(100vw-32px)] sm:w-fit sm:min-w-[280px] bg-secondary/50 p-2 rounded-md border flex flex-col gap-1">
      <div className="text-neutral-500 p-2">{title.toUpperCase()}</div>
      {items?.map((bid) => (
        <BidCard key={bid.bid_uuid} item={bid} website_slug={website_slug} />
      ))}
    </div>
  );
};
