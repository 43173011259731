import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { CloseButton } from "@/components/ui/close-button";
import { Card, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { SelectWithLabel } from "@/components/ui/select-with-label";
import { BaseSelectWithLabel } from "@/components/ui/base-select-with-label";
import { Dialog, DialogClose, DialogContent } from "@/components/ui/dialog";
import { CopyButton } from "@/components/ui/copy-button";
import { useAuthStore } from "src/shared/store/auth-store";
import { EUserRole, TCreateUser, TWebsite, TUpdateUser, TUser } from "src/shared/types/admin";
import Cursor from "src/shared/image/cursor";
import { TSelect } from "src/shared/types/common";
import { WebsitesBadges } from "./websites-badges";
import { useCreateUser, useUpdateUser } from "src/shared/api/query/use-admin/use-users";
import { useAdminStore } from "src/shared/store/admin-store";
import { user_password_schema, user_username_schema } from "src/shared/validation/field-schemas";

const FormSchema = z.object({
  user_username: user_username_schema,
  user_password: user_password_schema,
  user_role_name: z.custom(() => EUserRole),
});

const FormSchemaUpdate = z.object({
  user_username: user_username_schema,
  user_password: z.string(),
  user_role_name: z.custom(() => EUserRole),
});

export const UsersForm = ({
  selectedUser,
  setSelectedUser,
}: {
  selectedUser: TUser | null;
  setSelectedUser: Dispatch<SetStateAction<TUser | null>>;
}) => {
  const defaultValues = {
    user_username: "",
    user_password: "",
    user_role_name: EUserRole.USER,
  };
  const { toast } = useToast();
  const form = useForm<z.infer<typeof FormSchema | typeof FormSchemaUpdate>>({
    resolver: !!selectedUser ? zodResolver(FormSchemaUpdate) : zodResolver(FormSchema),
    defaultValues,
  });
  const { users, setUsers } = useAdminStore();
  const { mutateAsync: updateUser, isPending: isUpdatePending } = useUpdateUser();
  const { mutateAsync: createUser, isPending: isCreatePending } = useCreateUser();

  const { websites } = useAuthStore();
  const [selectedWebsites, setSelectedWebsites] = useState<TWebsite[]>([]);
  const [isEditPassword, setEditPassword] = useState(false);
  const [savePasswordConfirm, setSavePasswordConfirm] = useState<string | null>(null);

  const websites_options: TSelect[] =
    websites?.map((el) => ({
      label: el.website_name,
      value: el.website_slug,
    })) || [];
  const roles_options = Object.values(EUserRole).map((el) => ({
    label: el,
    value: el,
  }));

  const notModified = !form.formState.isDirty && !form.watch("user_password")?.length;
  const websitesNotModified =
    JSON.stringify(
      selectedUser?.websites?.map((el) => el.website_slug)?.sort((a, b) => (a > b ? 1 : -1))
    ) ===
    JSON.stringify(
      selectedWebsites?.map((el) => el.website_slug)?.sort((a, b) => (a > b ? 1 : -1))
    );

  const disabled =
    isUpdatePending ||
    isCreatePending ||
    (!!selectedUser ? websitesNotModified && notModified : notModified);

  const generateRandomPassword = () => {
    let charset = "";
    let password = "";

    charset += "!@#$%^&*()";
    charset += "0123456789";
    charset += "abcdefghijklmnopqrstuvwxyz";
    charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < 12; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setEditPassword(true);
    form.setValue("user_password", password);
  };

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    if (!!selectedUser) {
      const payload: TUpdateUser = {
        user_username: selectedUser.user_username,
        user_role_name: e.user_role_name,
      };
      if (!websitesNotModified) {
        payload.websites = selectedWebsites?.map((el) => el.website_slug) || [];
      }
      if (!!e.user_password?.length) {
        payload.user_new_password = e.user_password;
      }
      const data = await updateUser(payload);
      if (data && !!users) {
        toast({
          title: "Пользователи",
          description: "Данные пользователя успешно обновлены",
        });
        if (!!e.user_password?.length) {
          setSavePasswordConfirm(e.user_password);
        }
        setSelectedUser(data);
        setUsers(users.map((el) => (el.user_username === selectedUser.user_username ? data : el)));
      }
    } else {
      const payload: TCreateUser = {
        user_username: e.user_username || "",
        user_password: e.user_password || "",
        user_role_name: e.user_role_name || EUserRole.USER,
      };
      const data = await createUser(payload);
      if (data) {
        toast({
          title: "Пользователи",
          description: "Пользователь успешно создан",
        });
        setSavePasswordConfirm(e.user_password);
        if (!!users) {
          setUsers([...users, data]);
        } else {
          setUsers([data]);
        }
        form.reset(defaultValues);
      }
    }
  };

  const onSelectWebsite = (website_slug: string) => {
    const currentWebsite = websites?.find((el) => el.website_slug === website_slug);
    if (!!currentWebsite && !!selectedWebsites?.length) {
      if (!selectedWebsites?.find((el) => el.website_slug === website_slug)) {
        setSelectedWebsites([...selectedWebsites, currentWebsite]);
      }
    } else if (!!currentWebsite) {
      setSelectedWebsites([currentWebsite]);
    }
  };

  const onDeleteWebsite = (website_slug: string) => {
    setSelectedWebsites(selectedWebsites.filter((el) => el.website_slug !== website_slug));
  };

  useEffect(() => {
    setEditPassword(false);
    if (selectedUser) {
      form.reset({
        ...selectedUser,
        user_password: "",
      });
      setSelectedWebsites(selectedUser.websites || []);
    } else {
      form.reset(defaultValues);
      setSelectedWebsites([]);
    }
  }, [selectedUser]);

  return (
    <Card className="sm:sticky top-4 w-full sm:w-80 p-4 h-fit">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
          <CardTitle className="text-lg flex items-center justify-between h-10">
            {!!selectedUser ? selectedUser.user_username : "Новый пользователь"}
            {!!selectedUser && <CloseButton onClick={() => setSelectedUser(null)} />}
          </CardTitle>
          <div className="w-full space-y-2">
            <InputWithLabel label="Логин" name="user_username" readOnly={!!selectedUser} />
            <div className="w-full flex gap-2 items-end">
              <InputWithLabel
                label="Пароль"
                name="user_password"
                readOnly={!!selectedUser && !isEditPassword}
              />
              {!!selectedUser && (
                <Button
                  type="button"
                  variant={isEditPassword ? "default" : "outline"}
                  size="icon"
                  className="p-1 min-w-10"
                  onClick={() => {
                    if (isEditPassword) {
                      form.setValue("user_password", "");
                      setEditPassword(false);
                    } else {
                      setEditPassword(true);
                    }
                  }}>
                  <Cursor />
                </Button>
              )}
            </div>
            <Button
              type="button"
              variant="link"
              className="text-xs text-emerald-500 duration-300 p-0 h-fit"
              onClick={generateRandomPassword}>
              сгенерировать безопасный пароль
            </Button>
            <SelectWithLabel
              label="Роль"
              name="user_role_name"
              placeholder="Выберите роль пользователя"
              options={roles_options}
            />
            {!!selectedUser && selectedUser.user_role_name !== EUserRole.SUPER_ADMIN && (
              <>
                <BaseSelectWithLabel
                  label="Сайты"
                  name="user_websites"
                  placeholder="Выберите сайты пользователя"
                  options={websites_options}
                  onSelect={onSelectWebsite}
                />
                {!!selectedWebsites && (
                  <WebsitesBadges websites={selectedWebsites} onDelete={onDeleteWebsite} />
                )}
              </>
            )}
          </div>
          <Button type="submit" disabled={disabled}>
            {!!selectedUser ? "Обновить" : "Создать пользователя"}
          </Button>
          {!!savePasswordConfirm && (
            <Dialog open={!!savePasswordConfirm} onOpenChange={() => setSavePasswordConfirm(null)}>
              <DialogContent className="sm:max-w-[425px]">
                <p className="text-sm">Сохраните пароль - он отображается один раз:</p>
                <div className="flex gap-3 items-center justify-between">
                  <code>{savePasswordConfirm}</code>
                  <CopyButton value={savePasswordConfirm} />
                </div>
                <DialogClose asChild>
                  <Button
                    type="button"
                    className="justify-self-end"
                    onClick={() => setSavePasswordConfirm(null)}>
                    Закрыть
                  </Button>
                </DialogClose>
              </DialogContent>
            </Dialog>
          )}
        </form>
      </Form>
    </Card>
  );
};
