export const Chevron = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100%"
      height="100%"
      viewBox="0 1 34 34"
      version="1.1"
      xmlSpace="preserve"
      x="0px"
      y="0px"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      fill="currentColor">
      <g>
        <path d="M16.667,25l-4.167,0l-0,4.167l4.167,-0l-0,-4.167Zm4.166,-4.167l-4.166,0l-0,4.167l4.166,0l0,-4.167Zm4.167,-4.166l-4.167,-0l0,4.166l4.167,0l0,-4.166Zm-4.167,-4.167l-4.166,0l-0,4.167l4.166,-0l0,-4.167Zm-4.166,-4.167l-4.167,0l0,4.167l4.167,0l-0,-4.167Z" />
      </g>
    </svg>
  );
};
