import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "@/lib/utils";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Card, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { Switcher } from "@/components/ui/switcher";
import { BaseSelectWithLabel } from "@/components/ui/base-select-with-label";
import {
  TDirection,
  E_XML_Param,
  XML_options,
  T_XML_option,
  TCreateDirection,
} from "src/shared/types/websites-settings";
import { string_schema } from "src/shared/validation/field-schemas";
import { useUpdateDirection } from "src/shared/api/query/use-website-settings/use-directions";
import { useWebsiteStore } from "src/shared/store/website-store";
import { StringBadges } from "src/entities/string-badges";
import { XMLModal } from "src/entities/xml-modal";
import { BooleanNullToggleGroup } from "src/entities/boolean-null-toggle-group";
import { fixMinimalExponent } from "src/shared/utils/charfix";

const FormSchema = z.object({
  city_code: string_schema,
  currency_from_code: string_schema,
  currency_to_code: string_schema,
  enabled: z.boolean().nullable(),
  bestchange_enabled: z.boolean().nullable(),
  min_from: string_schema,
  max_from: string_schema,
  reserve: string_schema,
  // direction_course_is_manual: z.boolean().nullable(),
  // direction_manual_course_from: string_schema,
  // direction_manual_course_to: string_schema,
  xml_value: z.custom(() => [E_XML_Param]),
});

export const DirectionForm = ({
  isMassUpdate = false,
  website_slug,
  selectedDirection,
  setSelectedDirection,
  massSelectedDirections,
}: {
  isMassUpdate?: boolean;
  website_slug: string;
  selectedDirection: TDirection | null;
  setSelectedDirection: Dispatch<SetStateAction<TDirection | null>>;
  massSelectedDirections?: TCreateDirection[];
}) => {
  const lang_attr = "RU";
  const city_name = selectedDirection?.city.localizations.find(
    (el) => el.locale_code === lang_attr
  )?.name;

  const defaultValues = {
    city_code: "",
    currency_from_code: "",
    currency_to_code: "",
    enabled: isMassUpdate ? null : false,
    bestchange_enabled: isMassUpdate ? null : false,
    // direction_course_is_manual: isMassUpdate ? null : false,
    min_from: "",
    max_from: "",
    reserve: "",
    // direction_manual_course_from: "",
    // direction_manual_course_to: "",
    xml_value: [],
  };
  const { toast } = useToast();
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });
  const { directions, setDirections } = useWebsiteStore();
  const { mutateAsync: updateDirection, isPending } = useUpdateDirection(website_slug);
  const [selectedXMLValues, setSelectedXMLValues] = useState<T_XML_option[]>([]);

  const enabled = form.watch("enabled");
  const bestchange_enabled = form.watch("bestchange_enabled");
  // const direction_course_is_manual = form.watch("direction_course_is_manual");

  const notModified = !form.formState.isDirty;
  const xmlValueNotModified =
    JSON.stringify(
      selectedDirection?.xml_value?.map((el) => el)?.sort((a, b) => (a > b ? 1 : -1))
    ) === JSON.stringify(selectedXMLValues?.map((el) => el)?.sort((a, b) => (a > b ? 1 : -1)));
  // const manual_course_error = direction_course_is_manual === true && (
  //   Number(form.watch("direction_manual_course_from")) <= 0 ||
  //   Number(form.watch("direction_manual_course_to")) <= 0
  // );

  const disabled = isPending || (xmlValueNotModified && notModified);
  //|| manual_course_error;

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const generatePayload = (): TCreateDirection[] | null => {
      if (!isMassUpdate) {
        return [
          {
            ...e,
            enabled: e.enabled === true,
            bestchange_enabled: e.bestchange_enabled === true,
            min_from: +e.min_from,
            max_from: +e.max_from,
            reserve: +e.reserve,
            // direction_course_is_manual: e.direction_course_is_manual === true,
            // direction_manual_course_from: (direction_course_is_manual && Number(e.direction_manual_course_from) > 0)
            //   ? +e.direction_manual_course_from
            //   : null,
            // direction_manual_course_to: (direction_course_is_manual && Number(e.direction_manual_course_to) > 0)
            //   ? +e.direction_manual_course_to
            //   : null,
            xml_value: selectedXMLValues?.map((el) => el.value),
          },
        ];
      } else if (!!massSelectedDirections?.length) {
        const updatedFields: Partial<TCreateDirection> = {};

        Object.keys(form.formState.dirtyFields)?.forEach(
          (el) => (updatedFields[el] = Number.isNaN(e[el]) ? e[el] : +e[el])
        );
        if (!!selectedXMLValues?.length) {
          updatedFields.xml_value = selectedXMLValues?.map((el) => el.value);
        }
        if (enabled !== null) {
          updatedFields.enabled = enabled;
        }
        if (bestchange_enabled !== null) {
          updatedFields.bestchange_enabled = bestchange_enabled;
        }
        // if (direction_course_is_manual !== null) {
        //   updatedFields.direction_course_is_manual = direction_course_is_manual;
        // }
        return massSelectedDirections.map((el) => ({
          ...el,
          ...updatedFields,
        }));
      } else {
        return null;
      }
    };
    const payload = generatePayload();
    if (!payload) {
      return;
    }
    const data = await updateDirection(payload);
    if (data && !!directions) {
      toast({
        title: "Направления",
        description: "Данные успешно обновлены",
      });
      if (!isMassUpdate) {
        setSelectedDirection(data[0]);
      }
      setDirections(
        directions.map((el) => {
          const currentEl = data?.find((res) => el.direction_id === res?.direction_id);
          if (!!currentEl) {
            return currentEl;
          } else {
            return el;
          }
        })
      );
    }
  };

  const onSelectXMLValue = (xml_value: E_XML_Param) => {
    const currentValue = XML_options.find((el) => el.value === xml_value) as T_XML_option;
    if (!!selectedXMLValues?.length) {
      if (!selectedXMLValues.some((el) => el.value === xml_value)) {
        setSelectedXMLValues(selectedXMLValues.concat([currentValue]));
      }
    } else {
      setSelectedXMLValues([currentValue]);
    }
  };

  const onDeleteXMLValue = (xml_value: E_XML_Param) => {
    setSelectedXMLValues(selectedXMLValues.filter((el) => el.value !== xml_value));
  };

  useEffect(() => {
    if (selectedDirection) {
      const min = selectedDirection.min_from;

      form.reset({
        city_code: selectedDirection.city.city_code,
        currency_from_code: selectedDirection.currency_from.currency_code,
        currency_to_code: selectedDirection.currency_to.currency_code,
        enabled: selectedDirection.enabled,
        bestchange_enabled: selectedDirection.bestchange_enabled,
        min_from: fixMinimalExponent(min),
        max_from: selectedDirection.max_from.toString(),
        reserve: selectedDirection.reserve.toString(),
        // direction_course_is_manual: selectedDirection.direction_course_is_manual,
        // direction_manual_course_from: selectedDirection.course_from?.toString() || "",
        // direction_manual_course_to: selectedDirection.course_to?.toString() || "",
        xml_value: selectedDirection.xml_value,
      });
      setSelectedXMLValues(
        selectedDirection.xml_value?.map(
          (el) => XML_options.find((option) => option.value === el) || XML_options[0]
        ) || []
      );
    } else {
      form.reset(defaultValues);
      setSelectedXMLValues([]);
    }
  }, [selectedDirection, isMassUpdate]);

  useEffect(() => {
    if (!enabled && form.watch("bestchange_enabled")) {
      form.setValue("bestchange_enabled", false);
    }
  }, [enabled]);

  return (
    <div className="md:sticky top-4 h-fit">
      <Card className="w-full sm:w-80 max-h-[calc(100vh-32px)] overflow-y-auto">
        {!!selectedDirection || isMassUpdate ? (
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6 p-4">
              <CardTitle className="text-lg">
                {isMassUpdate ? (
                  <div className="flex flex-col">
                    Массовое редактирование
                    <small className="text-xs opacity-50">
                      Для выбранных направлений обновятся только измененные поля, выделенные зеленым
                    </small>
                  </div>
                ) : (
                  !!selectedDirection && (
                    <>
                      <p>{city_name}</p>
                      <p>
                        {selectedDirection.currency_from.currency_code}{" "}
                        {selectedDirection.currency_to.currency_code}
                      </p>
                    </>
                  )
                )}
              </CardTitle>
              <div className="w-full space-y-2">
                {isMassUpdate ? (
                  <div className="flex flex-col gap-1">
                    <div
                      className={`w-full ${
                        isMassUpdate && enabled !== null ? "bg-[#22c55e33] rounded-md" : ""
                      }`}>
                      <BooleanNullToggleGroup
                        label="Активен на сайте"
                        value={enabled}
                        setValue={(value) => {
                          form.setValue("enabled", value);
                        }}
                        nullTooltip="Не применять фильтр"
                        trueTooltip="Показать только активные"
                        falseTooltip="Показать только неактивные"
                      />
                    </div>
                    <div
                      className={`w-full ${
                        isMassUpdate && bestchange_enabled !== null
                          ? "bg-[#22c55e33] rounded-md"
                          : ""
                      }`}>
                      <BooleanNullToggleGroup
                        label="Активен для мониторингов"
                        value={bestchange_enabled}
                        setValue={(value) => {
                          form.setValue("bestchange_enabled", value);
                        }}
                        nullTooltip="Не применять фильтр"
                        trueTooltip="Показать только активные"
                        falseTooltip="Показать только неактивные"
                      />
                    </div>
                    {/* <div className={`w-full ${(isMassUpdate && direction_course_is_manual !== null) ? "bg-[#22c55e33] rounded-md" : ""}`}>
                      <BooleanNullToggleGroup
                        label="Ручной курс"
                        value={direction_course_is_manual}
                        setValue={(value) => {
                          form.setValue("direction_course_is_manual", value);
                        }}
                        nullTooltip="Не применять фильтр"
                        trueTooltip="Показать только активные"
                        falseTooltip="Показать только неактивные"
                      />
                    </div> */}
                  </div>
                ) : (
                  <div className="space-y-1">
                    <Switcher label="Активен на сайте" name="enabled" />
                    <Switcher
                      label="Активен для мониторингов"
                      name="bestchange_enabled"
                      description="При включенном тумблере сайт отдает направление в XML, по которому мониторинги могут отображать его при наличии интеграции"
                      disabled={!enabled}
                    />
                    {/* <Switcher
                      label="Ручной курс"
                      name="direction_course_is_manual"
                      description="При включенном тумблере направление игнорирует курс из системы курсов и использует выставленные значения"
                    /> */}
                  </div>
                )}
                <InputWithLabel
                  className={
                    isMassUpdate && form.formState.dirtyFields.min_from ? "bg-[#22c55e33]" : ""
                  }
                  label={`MIN`}
                  name="min_from"
                  type="number"
                  description="Минимальная сумма отдаваемой клиентом валюты для создания заявки"
                />
                <InputWithLabel
                  className={
                    isMassUpdate && form.formState.dirtyFields.max_from ? "bg-[#22c55e33]" : ""
                  }
                  label={`MAX`}
                  name="max_from"
                  type="number"
                  description="Максимальная сумма отдаваемой клиентом валюты для создания заявки"
                />
                <InputWithLabel
                  className={
                    isMassUpdate && form.formState.dirtyFields.reserve ? "bg-[#22c55e33]" : ""
                  }
                  label={`Резерв`}
                  name="reserve"
                  type="number"
                  description="Резервы заполняются для отдаваемой клиентом валюты"
                />
                {/* {direction_course_is_manual && (
                  <>
                    <InputWithLabel
                      className={isMassUpdate && form.formState.dirtyFields.direction_manual_course_from ? "bg-[#22c55e33]" : ""}
                      label={`Ручной курс from`}
                      name="direction_manual_course_from"
                      type="number"
                    />
                    <InputWithLabel
                      className={isMassUpdate && form.formState.dirtyFields.direction_manual_course_to ? "bg-[#22c55e33]" : ""}
                      label={`Ручной курс to`}
                      name="direction_manual_course_to"
                      type="number"
                    />
                  </>
                )} */}
                <div className={cn("w-full flex gap-2 items-end")}>
                  <BaseSelectWithLabel
                    label="Значения XML"
                    name="xml_value"
                    placeholder="Добавьте специальные условия"
                    options={XML_options}
                    onSelect={(e) => onSelectXMLValue(e as E_XML_Param)}
                  />
                  <XMLModal />
                </div>
                {!!selectedXMLValues && (
                  <div
                    className={`w-full px-1 pb-2 pt-px ${
                      isMassUpdate && !!selectedXMLValues?.length ? "bg-[#22c55e33] rounded-md" : ""
                    }`}>
                    <StringBadges
                      baseKey="xml_form"
                      items={selectedXMLValues}
                      onDelete={(e) => onDeleteXMLValue(e as E_XML_Param)}
                    />
                  </div>
                )}
              </div>
              {/* {manual_course_error && (
                <small className="text-destructive">Выставьте ручные курсы или выключите тумблер</small>
              )} */}
              <Button type="submit" disabled={disabled}>
                Обновить
              </Button>
            </form>
          </Form>
        ) : (
          <div className="flex items-center justify-center bg-secondary h-[300px] text-neutral-500">
            Выберите направление
          </div>
        )}
      </Card>
    </div>
  );
};
