import { useBaseMutation } from "../base-mutation";
import referralService from "../../services/referral-service";
import { TManualCheating } from "src/shared/types/referral";

export const useManualCheating = () =>
  useBaseMutation<TManualCheating, TManualCheating>(
    ["referral-manual-cheating"],
    (payload: TManualCheating) => referralService.manualCheating(payload),
    {
      403: "Недостаточно прав",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );
