import { timeFormat } from "@visx/vendor/d3-time-format";

export const formatDate = timeFormat("%d %b %Y %H:%M");

export const getStartOfTheDate = (date: string | number | Date): number => {
  const convertedDate: Date = new Date(date);
  convertedDate.setHours(0, 0, 0, 0);
  return new Date(+convertedDate / 1000).valueOf();
};

export const getEndOfTheDate = (date: string | number | Date): number => {
  const convertedDate = new Date(date);
  convertedDate.setHours(23, 59, 59, 999); // Устанавливаем последние миллисекунды дня
  return new Date(+convertedDate / 1000).valueOf();
};

export function unixToDate(unixTimestamp) {
  const date = new Date(unixTimestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export const presetRanges = [
  {
    label: "За сегодня",
    range: () => ({
      startDate: new Date(getStartOfTheDate(new Date()) * 1000),
      endDate: new Date(),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 24 часа",
    range: () => ({
      startDate: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
      endDate: new Date(getEndOfTheDate(+new Date() * 1000)),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 2 дня",
    range: () => ({
      startDate: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000),
      endDate: new Date(getEndOfTheDate(+new Date() * 1000)),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 7 дней",
    range: () => ({
      startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
      endDate: new Date(getEndOfTheDate(+new Date() * 1000)),
    }),
    isSelected() {
      return false;
    },
  },
  {
    label: "За последние 30 дней",
    range: () => ({
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(getEndOfTheDate(+new Date() * 1000)),
    }),
    isSelected() {
      return false;
    },
  },
];
