import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useBids } from "src/shared/api/query/use-bids/use-bids";
import { useBidsStore } from "src/shared/store/bids-store";
import { useCommonStore } from "src/shared/store/common-store";
import { WebsitesList } from "src/entities/websites-list";
import { Chevron } from "src/shared/image/chevron";
import { BidsList } from "./components/bids-list";
import { BidFilters } from "./components/bid-filters";

const filterFields = {
  bid_id: true,
  bid_status: true,
  user_username: true,
  user_email: true,
  currency_from_code: true,
  currency_to_code: true,
  bid_created_at_start: true,
  bid_created_at_end: true,
  bid_rid: true,
  city_code: true,
  utm_medium: true,
  utm_source: true,
  roistat_visit: true,
  user_telegram: true,
  address_site: true,
  address_user: true,
  ip_address: true,
};

export const Bids = () => {
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");
  const [isChooseWebsite, setIsChooseWebsite] = useState(!website_slug?.length);
  const { limit } = useCommonStore();
  const [params, setParams] = useState(`limit=${limit}&offset=0`);

  const { data: bidsData, isPending } = useBids(website_slug || "", params);

  const { setBids, setBidsTotal } = useBidsStore();

  useEffect(() => {
    if (!!bidsData?.data) {
      setBids(bidsData.data);
      setBidsTotal(bidsData.total);
    } else {
      setBids(null);
    }
  }, [bidsData]);

  return (
    <div>
      <div
        className={`text-background text-lg px-4 py-1 font-bold duration-300 ${
          !!website_slug ? "bg-foreground " : "bg-secondary"
        }`}>
        <div
          className={`flex items-center gap-3 ${
            !!website_slug ? "" : "text-neutral-500 opacity-50"
          }`}>
          {!!website_slug ? (
            <>
              <button
                type="button"
                className="hidden md:flex justify-center shrink-0 items-center w-6 h-6 mx-2 p-1"
                onClick={() => {
                  setIsChooseWebsite(!isChooseWebsite);
                }}>
                <Chevron
                  className={`duration-300 w-5 h-5 ${isChooseWebsite ? "-rotate-90" : "rotate-90"}`}
                />
              </button>
              {website_slug.toUpperCase()}
            </>
          ) : (
            "Выберите сайт"
          )}
        </div>
      </div>
      <div className="p-4 w-full flex flex-col gap-4 md:flex-row">
        <WebsitesList
          isHidden={!isChooseWebsite && !!website_slug}
        />
        {!!website_slug && (
          <div className="w-full flex flex-col gap-2">
            <BidFilters website_slug={website_slug} setParams={setParams} fields={filterFields} />
            <BidsList isPending={isPending} website_slug={website_slug} />
          </div>
        )}
      </div>
    </div>
  );
};
