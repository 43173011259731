import { AxiosResponse } from "axios";
import { BASE_URL } from "src/shared/constants";
import api from "../api-instance";
import {
  TReferralTask,
  TCreateReferralTask,
  TUpdateReferralTask,
  TReferralTaskHistoryItem,
  TManualCheating,
  TWithdrawalPagination,
  TWithdrawal,
  TUpdateWithdrawal,
} from "src/shared/types/referral";

export default class referralService {
  // task-manager
  static async getReferralTasks(website_slug: string): Promise<AxiosResponse<TReferralTask[]>> {
    return api.get(`${BASE_URL}/v1/referral_system/cheating/${website_slug}`);
  }
  static async addReferralTask(
    payload: TCreateReferralTask
  ): Promise<AxiosResponse<TReferralTask>> {
    return api.post(`${BASE_URL}/v1/referral_system/cheating/`, payload);
  }
  static async updateReferralTask(
    task_id: string,
    payload: TUpdateReferralTask
  ): Promise<AxiosResponse<TReferralTask>> {
    return api.patch(`${BASE_URL}/v1/referral_system/cheating/${task_id}`, payload);
  }
  static async deleteReferralTask(task_id: string): Promise<AxiosResponse<TReferralTask>> {
    return api.delete(`${BASE_URL}/v1/referral_system/cheating/${task_id}`);
  }
  static async getReferralTasksHistory(): Promise<AxiosResponse<TReferralTaskHistoryItem[]>> {
    // Сделать пагинацию - потом - фильтр по времени
    return api.get(`${BASE_URL}/v1/referral_system/cheating/history`);
  }
  // manual-cheating
  static async manualCheating(payload: TManualCheating): Promise<AxiosResponse<TManualCheating>> {
    return api.post(`${BASE_URL}/v1/referral_system/cheating/manual`, payload);
  }
  // withdrawal
  static async getWithdrawals(
    website_slug: string,
    params: string
  ): Promise<AxiosResponse<TWithdrawalPagination>> {
    return api.get(
      `${BASE_URL}/v1/referral_system/withdrawal/?website_slug=${website_slug}&${params}`
    );
  }
  static async updateWithdrawal(
    website_slug: string,
    payload: TUpdateWithdrawal
  ): Promise<AxiosResponse<TWithdrawal>> {
    return api.patch(
      `${BASE_URL}/v1/referral_system/withdrawal/?website_slug=${website_slug}`,
      payload
    );
  }
}
