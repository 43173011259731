import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { WEBSITE_SETTINGS_ROUTES } from "src/app/routing/routes";
import { WebsitesList } from "src/entities/websites-list";
import { useCities } from "src/shared/api/query/use-admin/use-cities";
import { Chevron } from "src/shared/image/chevron";
import { useAdminStore } from "src/shared/store/admin-store";
import { TCity } from "src/shared/types/websites-settings";
import { NavTab } from "src/widgets/navigation/nav-tab";

export const WebsiteSettings = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsSite = searchParams.get("website");
  const [isChooseWebsite, setIsChooseWebsite] = useState(!paramsSite?.length);
  const [currentPage, setCurrentPage] = useState(WEBSITE_SETTINGS_ROUTES[0]);
  const Component = useMemo(() => currentPage.component, [currentPage.path]);

  const { data: countriesData } = useCities();
  const { setCountries, setCities: setGlobalCities } = useAdminStore();

  useEffect(() => {
    if (!!countriesData && Array.isArray(countriesData?.countries)) {
      setCountries(countriesData.countries);
      const reducedCities: TCity[] = countriesData.countries?.reduce(
        (acc, val) => acc.concat(val.cities),
        [] as TCity[]
      );
      setGlobalCities(reducedCities);
    }
  }, [countriesData]);

  useEffect(() => {
    const currentTab = WEBSITE_SETTINGS_ROUTES.find((el) => el.path === searchParams.get("tab"));
    if (!!currentTab && currentTab.title !== currentPage?.title) {
      setCurrentPage(currentTab);
    }
    if (!!searchParams.get("website")) {
      setIsChooseWebsite(false);
    }
  }, [searchParams]);

  return (
    <div className="text-sm text-foreground">
      <div className={`bg-secondary grid grid-cols-2 sm:flex gap-4 flex-wrap p-4`}>
        {WEBSITE_SETTINGS_ROUTES.map((el) => {
          const isActive = el.path === currentPage.path;
          return (
            <NavTab
              key={el.title}
              title={el.title}
              isActive={isActive}
              onClick={() => {
                searchParams.set("tab", el.path);
                setSearchParams(searchParams);
              }}
            />
          );
        })}
      </div>
      <div
        className={`text-background text-lg px-4 py-1 font-bold duration-300 ${
          !!paramsSite ? "bg-foreground " : "bg-secondary"
        }`}>
        <div
          className={`flex items-center gap-3 ${
            !!paramsSite ? "" : "text-neutral-500 opacity-50"
          }`}>
          {!!paramsSite ? (
            <>
              <button
                type="button"
                className="flex justify-center shrink-0 items-center w-6 h-6 mx-2 p-1"
                onClick={() => {
                  setIsChooseWebsite(!isChooseWebsite);
                }}>
                <Chevron
                  className={`duration-300 w-5 h-5 ${isChooseWebsite ? "-rotate-90" : "rotate-90"}`}
                />
              </button>
              {paramsSite.toUpperCase()}
            </>
          ) : (
            "Выберите сайт"
          )}
        </div>
      </div>
      <div className="p-4 w-full flex flex-col gap-4 lg:flex-row">
        <WebsitesList
          isHidden={!isChooseWebsite && !!paramsSite}
        />
        <Component />
      </div>
    </div>
  );
};
