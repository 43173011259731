import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TBid } from "../types/bids";

interface BidsStoreState {
  bids: TBid[] | null;
  setBids: (bids: TBid[] | null) => void;
  bidsTotal: number | null;
  setBidsTotal: (bidsTotal: number | null) => void;
}

const initialState = {
  bids: null,
  bidsTotal: null,
};

export const useBidsStore = create<BidsStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setBids: (bids) => set({ bids }),
      setBidsTotal: (bidsTotal) => set({ bidsTotal }),
    }),
    {
      name: "bidsStore",
    }
  )
);
