export default function Cursor() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={40}
      height={40}
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      fill="currentColor">
      <path d="M40,32.66v-2.5h2.5v2.5H40z M45,35.16v-2.5h-2.5v2.5H45z M47.5,37.66v-2.5H45v2.5H47.5z M50,40.16v-2.5h-2.5  v2.5H50z M52.5,42.66v-2.5H50v2.5H52.5z M55,45.16v-2.5h-2.5v2.5H55z M57.5,47.66v-2.5H55v2.5H57.5z M60,50.16v-2.5h-2.5v2.5H60z   M62.5,52.66v-2.5H60v2.5H62.5z M62.5,52.66v2.5H60h-2.5H55h-2.5v2.5v2.5H55v-2.5h2.5H60h2.5H65v-2.5v-2.5H62.5z M45,57.66v2.5h2.5  v-2.5H45z M42.5,62.66H45v-2.5h-2.5V62.66z M40,65.16h2.5v-2.5H40V65.16z M37.5,62.66v-2.5v-2.5v-2.5v-2.5v-2.5v-2.5v-2.5v-2.5v-2.5  v-2.5v-2.5v-2.5v-2.5H40v-2.5h-2.5v-2.5H35v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5v2.5h2.5H40v-2.5h-2.5  V62.66z M55,65.16h2.5v-2.5v-2.5H55v2.5V65.16z M57.5,70.16H60v-2.5v-2.5h-2.5v2.5V70.16z M50,60.16h-2.5v2.5v2.5H50v-2.5V60.16z   M52.5,65.16H50v2.5v2.5h2.5v-2.5V65.16z M52.5,70.16v2.5H55h2.5v-2.5H55H52.5z" />
    </svg>
  );
}
