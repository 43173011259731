import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./src/App";
import "./src/index.css";

const container = document.getElementById("root");
const root = !!container && createRoot(container);

root &&
  root.render(
    <>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>
  );
