import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { CloseButton } from "@/components/ui/close-button";
import { Card, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { ECurrencyType, TCreateCurrency, TCurrency, TLocaleName } from "src/shared/types/admin";
import { string_schema } from "src/shared/validation/field-schemas";
import { SelectWithLabel } from "@/components/ui/select-with-label";
import {
  useCreateCurrency,
  useUpdateCurrency,
} from "src/shared/api/query/use-admin/use-currencies";
import { useAdminStore } from "src/shared/store/admin-store";
import { LocalizationInputWithLabel } from "@/components/ui/localization-input-with-label";
import { useUpdateWebsiteCurrency } from "src/shared/api/query/use-website-settings/use-currencies";
import { useWebsiteStore } from "src/shared/store/website-store";

const FormSchema = z.object({
  currency_code: string_schema,
  best_id: string_schema,
  exnode_id: string_schema,
  decimal: string_schema.refine((value) => /^([1-8])$/.test(value), {
    message: "Число от 1 до 8",
  }),
  currency_type: z.custom(() => ECurrencyType),
});

export const CurrencyForm = ({
  selectedCurrency,
  setSelectedCurrency,
  isWebsite = false,
}: {
  selectedCurrency: TCurrency | null;
  setSelectedCurrency: Dispatch<SetStateAction<TCurrency | null>>;
  isWebsite?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");
  const { toast } = useToast();
  const defaultValues = {
    currency_code: "",
    best_id: "",
    exnode_id: "",
    decimal: "8",
    currency_type: ECurrencyType.CRYPTO,
  };

  const currency_types_options = Object.values(ECurrencyType).map((el) => ({
    label: el,
    value: el,
  }));

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const [locales, setLocales] = useState<TLocaleName[]>([]);

  const { currencies, setCurrencies } = useAdminStore();
  const { websiteCurrencies, setWebsiteCurrencies } = useWebsiteStore();
  const { mutateAsync: createCurrency, isPending: isCreatePending } = useCreateCurrency();
  const { mutateAsync: updateCurrency, isPending: isUpdatePending } = useUpdateCurrency();
  const { mutateAsync: updateWebsiteCurrency, isPending: isUpdateWebsitePending } =
    useUpdateWebsiteCurrency(website_slug || "");

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const payload: TCreateCurrency = {
      currency_code: e.currency_code,
      best_id: +e.best_id,
      exnode_id: +e.exnode_id === 0 || Number.isNaN(+e.exnode_id) ? null : +e.exnode_id,
      decimal: +e.decimal,
      currency_type: e.currency_type,
      localizations: locales,
    };
    if (!!selectedCurrency) {
      if (isWebsite) {
        const data = await updateWebsiteCurrency([
          {
            ...payload,
            currency_id: selectedCurrency.currency_id,
          },
        ]);
        if (data && !!currencies) {
          toast({
            title: "Валюты",
            description: "Данные успешно обновлены",
          });
          if (!!websiteCurrencies) {
            setWebsiteCurrencies(
              websiteCurrencies.map((el) => {
                const currentCurrency = data?.find(
                  (res_el) => el.currency_code === res_el.currency_code
                );
                return !!currentCurrency ? currentCurrency : el;
              })
            );
          }
        }
      } else {
        const data = await updateCurrency([
          {
            ...payload,
            currency_id: selectedCurrency.currency_id,
          },
        ]);
        if (data && !!currencies) {
          toast({
            title: "Валюты",
            description: "Данные успешно обновлены",
          });
          setCurrencies(
            currencies.map((el) => {
              const currentCurrency = data?.find(
                (res_el) => el.currency_code === res_el.currency_code
              );
              return !!currentCurrency ? currentCurrency : el;
            })
          );
        }
      }
    } else {
      const data = await createCurrency(payload);
      if (data) {
        toast({
          title: "Валюты",
          description: "Валюта успешно добавлена",
        });
        if (!!currencies) {
          setCurrencies([...currencies, data]);
        } else {
          setCurrencies([data]);
        }
        form.reset(defaultValues);
      }
    }
  };

  useEffect(() => {
    if (selectedCurrency) {
      form.reset({
        ...selectedCurrency,
        best_id: selectedCurrency.best_id?.toString() || "",
        exnode_id: selectedCurrency.exnode_id?.toString() || "",
        decimal: selectedCurrency.decimal?.toString() || "",
      });
      setLocales(selectedCurrency.localizations);
    } else {
      form.reset(defaultValues);
      setLocales([]);
    }
  }, [selectedCurrency]);

  return (
    <Card className="md:sticky top-4 min-w-72 w-[calc(100vw-34px)] sm:w-[calc(100vw-90px)] md:w-[340px] h-fit">
      {!selectedCurrency && isWebsite ? (
        <div className="flex items-center justify-center bg-secondary min-h-[100px] sm:h-[300px] text-neutral-500">
          Выберите валюту
        </div>
      ) : (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6 p-4">
            <CardTitle className="text-lg flex items-center justify-between h-10">
              {!!selectedCurrency ? selectedCurrency.currency_code : "Новая валюта"}
              {!!selectedCurrency && <CloseButton onClick={() => setSelectedCurrency(null)} />}
            </CardTitle>
            <div className="w-full space-y-2">
              {isWebsite ? (
                <></>
              ) : (
                <>
                  <InputWithLabel label="Код валюты" name="currency_code" />
                  <SelectWithLabel
                    label="Категория"
                    name="currency_type"
                    placeholder="crypto"
                    options={currency_types_options}
                  />
                  <InputWithLabel label="Bestchange ID" name="best_id" type="number" />
                  <InputWithLabel label="Exnode ID" name="exnode_id" type="number" />
                </>
              )}
              <LocalizationInputWithLabel
                label="Название валюты"
                name="localizations"
                locales={locales}
                setLocales={setLocales}
              />
              <InputWithLabel
                label="Число знаков округления"
                name="decimal"
                type="number"
                placeholder="1 - 8"
              />
            </div>
            <Button
              type="submit"
              disabled={isCreatePending || isUpdatePending || isUpdateWebsitePending}>
              {!!selectedCurrency ? "Обновить" : "Добавить валюту"}
            </Button>
          </form>
        </Form>
      )}
    </Card>
  );
};
