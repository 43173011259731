import { FormControl, FormItem, FormLabel, FormMessage } from "./form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select";
import { TSelect } from "../../../src/shared/types/common";
import { cn } from "@/lib/utils";

export const BaseSelectWithLabel = ({
  label,
  placeholder,
  options,
  onSelect,
  variant = "default",
}: {
  label: string;
  name: string;
  placeholder: string;
  options: TSelect[];
  onSelect: (val: string) => void;
  variant?: "default" | "small";
}) => {
  return (
    <FormItem
      className={cn(
        "w-full",
        variant === "small" && "grid grid-cols-[100px_1fr] gap-2 items-baseline text-xs"
      )}>
      <FormLabel
        className={cn(
          "flex justify-between items-end min-h-[18px]",
          variant === "small" && "text-xs"
        )}>
        {label}
      </FormLabel>
      <Select
        onValueChange={(e) => {
          onSelect(e);
        }}>
        <FormControl>
          <SelectTrigger className={cn(variant === "small" && "text-xs h-7")}>
            <SelectValue placeholder={placeholder} />
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {options?.map((el) => (
            <SelectItem key={el.value} value={el.value}>
              {el.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <FormMessage />
    </FormItem>
  );
};
