import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { CircleCheck, CircleDashed, CircleX } from "lucide-react";

export const BooleanNullToggleGroup = ({
  label,
  value,
  setValue,
  disabled = false,
  nullTooltip,
  trueTooltip,
  falseTooltip,
}: {
  label: string;
  value: boolean | null;
  setValue: (value: boolean | null) => void;
  disabled?: boolean;
  nullTooltip: string;
  trueTooltip: string;
  falseTooltip: string;
}) => {
  return (
    <TooltipProvider>
      <div className="flex flex-row bg-background items-center justify-between text-xs border rounded-md p-0.5 pl-2">
        <div
          className={`leading-none ${
            disabled ? "opacity-30" : value !== null ? "opacity-100" : "opacity-50"
          }`}>
          {label}
        </div>
        <ToggleGroup
          type="single"
          value={value?.toString() || "null"}
          disabled={disabled}
          onValueChange={(value) => {
            if (value === "true") {
              setValue(true);
            } else if (value === "false") {
              setValue(false);
            } else {
              setValue(null);
            }
          }}>
          <Tooltip>
            <TooltipTrigger asChild>
              <ToggleGroupItem
                value="null"
                aria-label="Toggle null"
                size="sm"
                className={`hover:text-neutral-500 ${value === null ? "bg-secondary" : ""}`}>
                <CircleDashed className="h-4 w-4" />
              </ToggleGroupItem>
            </TooltipTrigger>
            <TooltipContent>{nullTooltip}</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <ToggleGroupItem
                value="true"
                aria-label="Toggle true"
                size="sm"
                className={`hover:text-green-500 ${value === true ? "bg-[#22c55e33]" : ""}`}>
                <CircleCheck className="h-4 w-4" />
              </ToggleGroupItem>
            </TooltipTrigger>
            <TooltipContent>{trueTooltip}</TooltipContent>
          </Tooltip>
          <Tooltip>
            <TooltipTrigger asChild>
              <ToggleGroupItem
                value="false"
                aria-label="Toggle false"
                size="sm"
                className={`hover:text-red-500 ${value === false ? "bg-[#ef444433]" : ""}`}>
                <CircleX className="h-4 w-4" />
              </ToggleGroupItem>
            </TooltipTrigger>
            <TooltipContent>{falseTooltip}</TooltipContent>
          </Tooltip>
        </ToggleGroup>
      </div>
    </TooltipProvider>
  );
};
