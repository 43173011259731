import { useFormContext } from "react-hook-form";
import { cn } from "@/lib/utils";
import { Input, InputProps } from "./input";
import { Label } from "./label";

export function InputWithLabel({
  label,
  name,
  variant = "default",
  description = null,
  ...props
}: {
  label: string;
  name: string;
  variant?: "default" | "small";
  description?: string | null;
} & InputProps) {
  const form = useFormContext();
  const error = form.formState.errors[name];

  return (
    <>
      <div
        className={cn(
          "w-full space-y-1.5",
          variant === "small" && "grid grid-cols-[110px_1fr] gap-2 items-baseline text-xs"
        )}>
        <Label
          htmlFor={name}
          className={cn(!!error && "text-red-500", variant === "small" && "text-xs")}>
          {label}
        </Label>
        <Input
          id={name}
          className={cn(variant === "small" && "h-7")}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          step="any"
          {...props}
          {...form.register(name)}
        />
        {!!error && (
          <p className="text-xs font-medium text-destructive">{error.message?.toString()}</p>
        )}
      </div>
      {!!description && (
        <div className="text-muted-foreground text-[10px] opacity-50 leading-[12px]">
          {description}
        </div>
      )}
    </>
  );
}
