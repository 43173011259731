import { useEffect, useState } from "react";
import { TCurrency } from "src/shared/types/admin";
import { CurrencyForm } from "./components/currency-form";
import { CurrencyList } from "src/entities/currency-list";
import { useCurrencies } from "src/shared/api/query/use-admin/use-currencies";
import { useAdminStore } from "src/shared/store/admin-store";

export const Currencies = () => {
  const [selectedCurrency, setSelectedCurrency] = useState<TCurrency | null>(null);

  const { data: currenciesData, isPending } = useCurrencies();

  const { currencies, setCurrencies } = useAdminStore();

  useEffect(() => {
    if (!!currenciesData) {
      setCurrencies(currenciesData);
    } else {
      setCurrencies(null);
    }
  }, [currenciesData]);

  return (
    <div className={"relative w-full flex flex-col gap-4 md:flex-row"}>
      <CurrencyForm selectedCurrency={selectedCurrency} setSelectedCurrency={setSelectedCurrency} />
      <CurrencyList
        currencies={currencies}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
        isPending={isPending}
      />
    </div>
  );
};
