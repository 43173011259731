import { Dispatch, SetStateAction } from "react";
import { TWithdrawal } from "src/shared/types/referral";
import { WithdrawalCard } from "./withdrawal-card";

export const WithdrawalColumn = ({
  title,
  items,
  selectedWithdrawal,
  setSelectedWithdrawal,
}: {
  title: string;
  items: TWithdrawal[];
  selectedWithdrawal: TWithdrawal | null;
  setSelectedWithdrawal: Dispatch<SetStateAction<TWithdrawal | null>>;
}) => {
  return (
    <div className="snap-center h-fit min-w-[calc(100vw-32px)] sm:min-w-fit bg-secondary/50 p-2 rounded-md border flex flex-col gap-1">
      <div className="text-neutral-500 p-2">{title.toUpperCase()}</div>
      {items?.map((withdrawal) => (
        <WithdrawalCard
          key={withdrawal.id}
          item={withdrawal}
          selectedWithdrawal={selectedWithdrawal}
          setSelectedWithdrawal={setSelectedWithdrawal}
        />
      ))}
    </div>
  );
};
