import { AxiosResponse } from "axios";
import { BASE_URL } from "src/shared/constants";
import api from "src/shared/api/api-instance";
import {
  TCreateSite,
  TCreateSiteCredentials,
  TCreateUser,
  TWebsite,
  TWebsiteCredentials,
  TUpdateSite,
  TUpdateUser,
  TUser,
  TCurrency,
  TCreateCurrency,
} from "src/shared/types/admin";
import { TCountry } from "src/shared/types/websites-settings";

export default class adminService {
  // users
  static async me(): Promise<AxiosResponse<TUser>> {
    return api.get(`${BASE_URL}/v1/user/me`);
  }
  static async getUsers(): Promise<AxiosResponse<TUser[]>> {
    return api.get(`${BASE_URL}/v1/user/`);
  }
  static async addUser(payload: TCreateUser): Promise<AxiosResponse<TUser>> {
    return api.post(`${BASE_URL}/v1/user/`, payload);
  }
  static async updateUser(payload: TUpdateUser): Promise<AxiosResponse<TUser>> {
    return api.patch(`${BASE_URL}/v1/user/`, payload);
  }
  static async deleteUser(user_username: string) {
    return api.delete(`${BASE_URL}/v1/user/`, { data: { user_username } });
  }
  // sites
  static async getSite(website_slug: string): Promise<AxiosResponse<TWebsite>> {
    // Пока не используется фронтом
    return api.get(`${BASE_URL}/v1/website/${website_slug}`);
  }
  static async getDeletedSites(): Promise<AxiosResponse<TWebsite[]>> {
    return api.get(`${BASE_URL}/v1/website/deleted`);
  }
  static async addSite(payload: TCreateSite): Promise<AxiosResponse<TWebsite>> {
    return api.post(`${BASE_URL}/v1/website/`, payload);
  }
  static async updateSite(payload: TUpdateSite): Promise<AxiosResponse<TWebsite>> {
    return api.patch(`${BASE_URL}/v1/website/`, payload);
  }
  // sites-credentials
  static async getSiteCredentials(
    website_slug: string
  ): Promise<AxiosResponse<TWebsiteCredentials[]>> {
    return api.get(`${BASE_URL}/v1/credentials/${website_slug}`);
  }
  static async addSiteCredentials(
    payload: TCreateSiteCredentials[]
  ): Promise<AxiosResponse<TWebsiteCredentials[]>> {
    return api.post(`${BASE_URL}/v1/credentials/`, payload);
  }
  // currencies
  static async getCurrencies(): Promise<AxiosResponse<TCurrency[]>> {
    return api.get(`${BASE_URL}/v1/currency/`);
  }
  static async addCurrency(payload: TCreateCurrency): Promise<AxiosResponse<TCurrency>> {
    return api.post(`${BASE_URL}/v1/currency/`, payload);
  }
  static async updateCurrency(payload: TCurrency[]): Promise<AxiosResponse<TCurrency[]>> {
    return api.patch(`${BASE_URL}/v1/currency/`, payload);
  }
  // cities
  static async getCities(): Promise<AxiosResponse<{ countries: TCountry[] }>> {
    return api.get(`${BASE_URL}/v1/city/`);
  }
}
