export default function Question() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
      fill="currentColor">
      <g>
        <rect x="42.6" y="72.3" width="14.9" height="14.8" />
        <polygon points="87.1,12.9 87.1,57.4 42.6,57.4 42.6,42.6 72.3,42.6 72.3,27.7 27.7,27.7 27.7,42.6 12.9,42.6 12.9,12.9  " />
      </g>
    </svg>
  );
}
