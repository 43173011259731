import { useState } from "react";
import { AdderCard } from "./adder-card";
import { TSelect } from "src/shared/types/common";
import { Minus, Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { TCurrency } from "src/shared/types/admin";

export const AdderGroup = ({
  group_label,
  group_value,
  items,
  savedItems,
  selectedArr,
  selectHandler,
  selectGroupHandler,
}: {
  group_label: string;
  group_value: string;
  items: TSelect[] | null;
  savedItems: TCurrency[] | null;
  selectedArr: string[];
  selectHandler: (item: string) => void;
  selectGroupHandler: (group_value: string, isSelectGroup: boolean) => void;
}) => {
  const [isSelectedGroup, setIsSelectedGroup] = useState(false);

  return (
    <div className="flex flex-col gap-3">
      <Button
        type="button"
        variant="link"
        className="flex justify-start items-center gap-2 text-neutral-500 px-2 py-1"
        onClick={() => {
          selectGroupHandler(group_value, !isSelectedGroup);
          setIsSelectedGroup(!isSelectedGroup);
        }}>
        {isSelectedGroup ? <Minus className="pb-px w-5 h-5" /> : <Plus className="pb-px w-5 h-5" />}
        {group_label}
      </Button>
      <div className="flex flex-col gap-1">
        {items?.map((el) => (
          <AdderCard
            key={el.value}
            label={el.label}
            value={el.value}
            isSelected={selectedArr?.includes(el.value)}
            selectHandler={selectHandler}
            disabled={!!savedItems?.find((saved_el) => saved_el.currency_code === el.value)}
          />
        ))}
      </div>
    </div>
  );
};
