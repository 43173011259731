import { AxiosResponse } from "axios";
import { BASE_URL } from "src/shared/constants";
import api from "src/shared/api/api-instance";
import { TBid, TBidsPagination, TUpdateBid } from "src/shared/types/bids";

export default class bidsService {
  static async getBids(
    website_slug: string,
    params: string
  ): Promise<AxiosResponse<TBidsPagination>> {
    return api.get(`${BASE_URL}/v1/bids/${website_slug}/filter?${params}`);
  }
  static async updateBid(website_slug: string, payload: TUpdateBid): Promise<AxiosResponse<TBid>> {
    return api.patch(`${BASE_URL}/v1/bids/${website_slug}/`, payload);
  }
}
