import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export function useBaseMutationWithoutPayload<R>(
  mutationKeys: string[],
  request: () => Promise<AxiosResponse<R, string>>
) {
  const { mutateAsync, isPending, error } = useMutation({
    mutationKey: mutationKeys,
    mutationFn: async () => {
      try {
        if (mutationKeys?.some((el) => el === "")) {
          return null;
        }
        const data = await request();
        if (!data) {
          throw new Error("500");
        }
        if (data.status < 200 || data.status >= 300) {
          throw new Error(data.status?.toString());
        }
        return data.data;
      } catch (e) {
        throw new Error(e as string);
      }
    },
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
}
