import { useEffect, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Button } from "./button";
import { Copy } from "lucide-react";
import { cn } from "@/lib/utils";

export const CopyButton = ({
  label,
  variant,
  value,
  disabled,
  className = "",
}: {
  label?: string;
  variant?: "default" | "outline" | "link";
  value: string;
  className?: string;
  disabled?: boolean;
}) => {
  const [isCopied, setCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [isCopied]);

  return (
    <Popover open={isCopied}>
      <PopoverTrigger asChild>
        <Button
          variant={variant || "outline"}
          className={cn("flex gap-3", className)}
          size={!!label ? "sm" : "icon"}
          disabled={disabled}
          onClick={async () => {
            setCopied(true);
            await navigator.clipboard.writeText(value);
          }}>
          {!!label && label}
          <Copy
            height={16}
            width={16}
            style={{ color: variant === "default" ? "#fff" : "#737373" }}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="text-xs p-2 w-fit">
        <p>Скопировано</p>
      </PopoverContent>
    </Popover>
  );
};
