import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Card, CardTitle } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { InputWithLabel } from "@/components/ui/input-with-label";
import { string_schema } from "src/shared/validation/field-schemas";
import { useManualCheating } from "src/shared/api/query/use-referral/use-manual-cheating";

const FormSchema = z.object({
  ref_id: string_schema,
  total_amount: string_schema,
  bids_count: string_schema,
});

export const ManualCheatingForm = ({ website_slug }: { website_slug: string | undefined | null }) => {
  const { toast } = useToast();
  const defaultValues = {
    ref_id: "",
    total_amount: "",
    bids_count: "",
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const { mutateAsync: manualCheating, isPending } = useManualCheating();

  const disabled =
    !website_slug?.length || !!Object.values(form.formState.errors)?.length || isPending;

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const payload = {
      ref_id: +e.ref_id,
      total_amount: +e.total_amount,
      bids_count: +e.bids_count,
      website_slug: website_slug || "",
    };
    const data = await manualCheating(payload);
    if (data) {
      toast({
        title: "Ручная накрутка",
        description: "Успешно",
      });
      form.reset(defaultValues);
    }
  };

  return (
    <Card className="min-w-72 min-h-64 p-4 h-fit">
      {!!website_slug ? (
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
            <CardTitle className="text-lg flex items-center justify-between h-10">
              Ручная накрутка
            </CardTitle>
            <div className="w-full flex flex-col gap-2">
              <InputWithLabel label="REF ID" name="ref_id" type="number" />
              <InputWithLabel label="Cумма накрутки" name="total_amount" type="number" />
              <InputWithLabel label="Количество заявок" name="bids_count" type="number" />
            </div>
            <Button type="submit" disabled={disabled}>
              Накрутить
            </Button>
          </form>
        </Form>
      ) : (
        <div className="w-full h-full min-h-64 flex items-center justify-center text-neutral-500">
          Выберите сайт
        </div>
      )}
    </Card>
  );
};
