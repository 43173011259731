import { useFormContext } from "react-hook-form";
import { Textarea, TextareaProps } from "./textarea";
import { Label } from "./label";

export function TextareaWithLabel({
  label,
  name,
  ...props
}: {
  label: string;
  name: string;
} & TextareaProps) {
  const form = useFormContext();

  return (
    <div className="w-full space-y-1.5">
      <Label htmlFor={name}>{label}</Label>
      <Textarea id={name} {...props} {...form.register(name)} />
    </div>
  );
}
