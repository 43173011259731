import { Dispatch, SetStateAction, useState } from "react";
import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { useCommonStore } from "src/shared/store/common-store";
import { Chevron } from "src/shared/image/chevron";

export const LanguageBar = ({
  selectedLanguage,
  setSelectedLanguage,
}: {
  selectedLanguage: string;
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
}) => {
  const { languages } = useCommonStore();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex gap-1 min-h-5">
      {!!languages &&
        (isOpen ? (
          <div className="w-full flex flex-wrap gap-1 pb-2">
            <Languages
              languages={languages}
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          </div>
        ) : (
          <ScrollArea className={`w-full flex-nowrap whitespace-nowrap`}>
            <div className="w-max flex flex-nowrap gap-1 pb-2.5">
              <Languages
                languages={languages}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
            </div>
            <ScrollBar orientation="horizontal" />
          </ScrollArea>
        ))}
      {!!languages && languages?.length > 7 && (
        <button
          type="button"
          className="flex justify-center shrink-0 items-center w-6 h-6 rounded-full bg-secondary p-1"
          onClick={() => {
            setIsOpen(!isOpen);
          }}>
          <Chevron className={`duration-300 w-5 h-5 ${isOpen ? "-rotate-90" : "rotate-90"}`} />
        </button>
      )}
    </div>
  );
};

const Languages = ({
  languages,
  selectedLanguage,
  setSelectedLanguage,
}: {
  languages: string[];
  selectedLanguage: string;
  setSelectedLanguage: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <>
      {languages?.map((lang) => {
        return (
          <Button
            key={lang}
            type="button"
            variant={lang === selectedLanguage ? "default" : "outline"}
            className="p-1.5 text-xs h-6"
            onClick={() => setSelectedLanguage(lang)}>
            {lang.toUpperCase()}
          </Button>
        );
      })}
    </>
  );
};
