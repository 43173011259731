import { EBidStatus, TBidStatusDict } from "../types/bids";

export const defineBidStatus = (status: EBidStatus): TBidStatusDict => {
  switch (status) {
    case EBidStatus.CREATED:
      return {
        bid_status: status,
        title: "Новая",
        color: "#a3a3a3",
      };
    case EBidStatus.CANCELED:
      return {
        bid_status: status,
        title: "Отменена",
        color: "#ef4444",
      };
    case EBidStatus.DELETED:
      return {
        bid_status: status,
        title: "Удалена",
        color: "#ef4444",
      };
    case EBidStatus.COMPLETED:
    case EBidStatus.DONE:
      return {
        bid_status: status,
        title: "Исполнена",
        color: "#22c55e",
      };
    default:
      return {
        bid_status: status,
        title: status,
        color: "#737373",
      };
  }
};
