import { ECurrencyType, TCurrency, TLocaleName } from "./admin";
import { TSelect } from "./common";

export interface TPairs {
  from: TCurrency[];
  to: TCurrency[];
}

export interface TSelectedPair {
  id: string;
  from: TCurrency;
  to: TCurrency;
  city: TCity | null;
}

export type TFilters = {
  cities: null | Array<TSelect>;
  from: null | string;
  to: null | string;
};

export type TCurrencyTypesFilter = {
  from: ECurrencyType[];
  to: ECurrencyType[];
};

export type TAvailableDirectionsTypes = {
  from_type: ECurrencyType;
  to_type: ECurrencyType;
  available: boolean;
};

export type TCountry = {
  country_id: number;
  localizations: TLocaleName[];
  cities: TCity[];
};

export type TCity = {
  city_id: number;
  city_code: string;
  city_is_region: boolean;
  country_id: number;
  localizations: TLocaleName[];
};

export const AUTO_CITY_NAME = "Auto City";
export const AUTO_CITY_CODE = "auto";
export const AUTO_COUNTRY_NAME = "AUTO";

export const autoCity: Partial<TCity> = {
  city_code: AUTO_CITY_CODE,
  city_is_region: true,
  country_id: 0,
  localizations: [
    {
      locale_code: "RU",
      name: AUTO_CITY_NAME,
    },
    {
      locale_code: "EN",
      name: AUTO_CITY_NAME,
    },
  ],
};

export enum E_XML_Param {
  MANUAL = "manual", // — the property must be specified for all non-automatic, non-instant exchange directions (for manual, operator-controlled, etc.);
  JURIDICAL = "juridical", // — this property means that the exchanger transfers money to the user bank account from a juridical account. This icon is applicable only for directions * —› Wire;
  VERIFYING = "verifying", // — this property means that the exchanger can require ID documents from their client in order to perform the exchange operation;
  CARDVERIFY = "cardverify", // — this property means that the exchanger can require verification of a bank card;
  AUTO = "auto",
  OTHERIN = "otherin", // — receiving customer's funds is being done on a side payment system, not on the one specified in the field from;
  OTHEROUT = "otherout", // — payment of fund to the client is being done from the side payment system, not the one specified in the field to;
  REG = "reg", // — to make an exchange the user must register on the site of the exchanger;
  CARD_TO_CARD = "card2card", // — exchanger accepts users' funds by means of Card2Card transfers (not via card merchant). This icon is applicable only for directions Visa/MasterCard —› *;
  DELIVERY = "delivery", // — the exchanger does not have an office in the selected city, payment for courier delivery is included in the exchange rate or displayed by the icon "Additional commission";
  ATM = "atm", // — the exchange direction is serviced through a cryptomat, a terminal installed in a given city. This icon can be used in exchange directions with cash.
}

export type T_XML_option = {
  label: string;
  value: E_XML_Param;
  description: string;
};

export const XML_options: T_XML_option[] = [
  {
    label: "Ручной",
    value: E_XML_Param.MANUAL,
    description:
      "свойство должно быть указано для всех неавтоматических, немгновенных направлений обмена (для ручного, управляемого оператором и т.п.)",
  },
  {
    label: "Доставка",
    value: E_XML_Param.DELIVERY,
    description:
      'у обменника нет офиса в выбранном городе, оплата курьерской доставки включена в курс обмена или отображается иконкой "Дополнительная комиссия"',
  },
  {
    label: "Юр.счет",
    value: E_XML_Param.JURIDICAL,
    description:
      "обменник переводит деньги на банковский счет пользователя с юридического счета. Этот значок применим только для направлений * —› Провод",
  },
  {
    label: "Верификация",
    value: E_XML_Param.VERIFYING,
    description:
      "обменник может запросить у своего клиента документы, удостоверяющие личность, для проведения операции обмена",
  },
  {
    label: "Верификация карт",
    value: E_XML_Param.CARDVERIFY,
    description: "обменник может потребовать верификацию банковской карты",
  },
  {
    label: "Авто",
    value: E_XML_Param.AUTO,
    description: "обмен проводится в автоматическом режиме",
  },
  {
    label: "Сторонний прием",
    value: E_XML_Param.OTHERIN,
    description:
      'прием средств клиента осуществляется на сторонней платежной системе, а не на той, которая указана в поле "отдаю"',
  },
  {
    label: "Сторонняя выплата",
    value: E_XML_Param.OTHEROUT,
    description:
      'выплата средств клиенту осуществляется со стороны платежной системы, а не той, которая указана в поле "получаю"',
  },
  {
    label: "Регистрация",
    value: E_XML_Param.REG,
    description:
      "для совершения обмена пользователю необходимо зарегистрироваться на сайте обменника",
  },
  {
    label: "Card2Card",
    value: E_XML_Param.CARD_TO_CARD,
    description:
      "обменник принимает средства пользователей посредством переводов Card2Card (не через карт-мерчант). Этот значок актуален только для направлений Visa/MasterCard —› *",
  },
  {
    label: "ATM",
    value: E_XML_Param.ATM,
    description:
      "направление обмена обслуживается через криптомат — терминал, установленный в данном городе. Этот значок можно использовать в направлениях обмена наличными",
  },
];

export type TDirectionsPagination = {
  total: number;
  page: number;
  data: TDirection[];
};

export type TDirection = {
  direction_id: number;
  city: TCity;
  currency_from: TCurrency;
  currency_to: TCurrency;
  enabled: boolean;
  bestchange_enabled: boolean;
  min_from: number;
  max_from: number;
  reserve: number;
  // direction_course_is_manual: boolean;
  // course_from: number | null;
  // course_to: number | null;
  xml_value: E_XML_Param[];
};

export type TCreateDirection = {
  city_code: string;
  currency_from_code: string;
  currency_to_code: string;
  min_from: number;
  max_from: number;
  reserve: number;
  enabled?: boolean;
  bestchange_enabled?: boolean;
  // direction_course_is_manual?: boolean;
  // direction_manual_course_from?: number | null;
  // direction_manual_course_to?: number | null;
  xml_value?: E_XML_Param[];
};

export type TDirectionFilterFields = {
  city_code?: boolean;
  currency_from_code?: boolean;
  currency_to_code?: boolean;
  xml_value?: boolean;
  enabled?: boolean;
  bestchange_enabled?: boolean;
  // direction_course_is_manual?: boolean;
};
