import { useFormContext } from "react-hook-form";
import { InputWithLabel } from "@/components/ui/input-with-label";

export const ServiceCard = ({ service_name }: { service_name: string }) => {
  const form = useFormContext();
  const isDirty = Object.keys(form.formState.dirtyFields)?.includes(service_name);

  return (
    <div
      className={`w-full space-y-2 p-2 rounded-md border duration-300 ${
        isDirty ? "bg-[#10b98133]" : "bg-transparent"
      }`}>
      <div className="text-base text-neutral-500 font-extralight">{service_name}</div>
      <InputWithLabel label="url" name={`${service_name}.webcred_api_url`} />
      <InputWithLabel label="token" name={`${service_name}.webcred_api_token`} />
    </div>
  );
};
