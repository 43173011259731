import { Power, PowerOff } from "lucide-react";
import { cn } from "@/lib/utils";
import { Card } from "@/components/ui/card";
import { TDirection, T_XML_option, XML_options } from "src/shared/types/websites-settings";
import { StringBadges } from "src/entities/string-badges";
import { fixMinimalExponent } from "src/shared/utils/charfix";

export const DirectionCard = ({
  item,
  selectedDirection,
  setSelectedDirection,
}: {
  item: TDirection;
  selectedDirection: TDirection | null;
  setSelectedDirection: () => void;
}) => {
  const lang_attr = "RU";
  const city_name = item.city.localizations.find((el) => el.locale_code === lang_attr)?.name;

  const XML_badges = item.xml_value?.map((el) =>
    XML_options.find((option) => option.value === el)
  ) as T_XML_option[];

  return (
    <Card
      className={cn(
        "w-full flex flex-col gap-2 p-2 pl-4 text-xs sm:text-sm cursor-pointer durarion-300 hover:opacity-90 hover:scale-[102%]",
        selectedDirection?.direction_id === item.direction_id && "bg-foreground text-background"
      )}
      onClick={setSelectedDirection}>
      <div className={cn("flex w-full items-center gap-3 min-h-10")}>
        <div className="w-full flex flex-col">
          <div className="flex flex-col lg:flex-row gap-2 flex-wrap lg:justify-between lg:gap-x-10 items-start">
            <div className="grid sm:grid-cols-[180px_1fr] gap-2 items-center">
              <div className="grid grid-cols-[44px_1fr] gap-2 items-center">
                <div className="flex items-center gap-1">
                  <div
                    className={`border rounded-full h-5 w-5 flex items-center justify-center ${
                      item.enabled ? "bg-[#4ade8055]" : "bg-secondary"
                    }`}>
                    {item.enabled ? <Power width={12} /> : <PowerOff width={12} />}
                  </div>
                  {item.bestchange_enabled && (
                    <div
                      className={`text-[10px] text-white rounded-full h-5 w-5 flex items-center justify-center bg-[#10b981]`}>
                      B
                    </div>
                  )}
                </div>
                <p>{city_name}</p>
              </div>
              <div className="flex gap-2 justify-between max-w-[200px] font-semibold items-center min-w-[180px]">
                <p>{item.currency_from.currency_code}</p>
                <p className="w-full border-b-4 border-dotted" />
                <p>{item.currency_to.currency_code}</p>
              </div>
            </div>
            <div
              className={cn(
                "text-[10px] leading-[12px] py-1 px-2 min-w-[220px] -ml-1 rounded-md",
                selectedDirection?.direction_id === item.direction_id
                  ? "bg-secondary/20"
                  : "bg-secondary"
              )}>
              {/* {!!item.direction_course_is_manual && (
                <div className="grid grid-cols-[70px_1fr] gap-2">
                  Ручной курс
                  <b className={(!item.course_from || !item.course_to) ? "text-destructive" : ""}>
                    {item.course_from || "не выставлен"} - {item.course_to || "не выставлен"}
                  </b>
                </div>
              )} */}
              {!!item.min_from ? (
                <div className="grid grid-cols-[70px_1fr] gap-2">
                  Min
                  <b>{fixMinimalExponent(item.min_from)}</b>
                </div>
              ) : (
                item.enabled && <p className="text-destructive">не выставлена min сумма</p>
              )}
              {!!item.max_from ? (
                <div className="grid grid-cols-[70px_1fr] gap-2">
                  Max
                  <b>{item.max_from}</b>
                </div>
              ) : (
                item.enabled && <p className="text-destructive">не выставлена max сумма</p>
              )}
              {!!item.reserve ? (
                <div className="grid grid-cols-[70px_1fr] gap-2">
                  Резерв
                  <b>{item.reserve}</b>
                </div>
              ) : (
                item.enabled && <p className="text-destructive">не выставлен резерв</p>
              )}
            </div>
          </div>
          <StringBadges baseKey="xml" items={XML_badges} />
        </div>
      </div>
    </Card>
  );
};
