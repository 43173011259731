export default function Tick() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 2"
      viewBox="0 0 100 100"
      width={12}
      height={12}
      x="0px"
      y="0px">
      <rect
        x="4.84968"
        y="55.06487"
        width="10"
        height="9.97152"
        transform="translate(-50.20095 69.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="14.84968"
        y="45.06487"
        width="10"
        height="9.97152"
        transform="translate(-30.20095 69.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="24.84968"
        y="55.06487"
        width="10"
        height="9.97152"
        transform="translate(-30.20095 89.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="34.84968"
        y="65.06487"
        width="10"
        height="9.97152"
        transform="translate(-30.20095 109.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="44.84968"
        y="55.06487"
        width="10"
        height="9.97152"
        transform="translate(-10.20095 109.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="54.84968"
        y="45.06487"
        width="10"
        height="9.97152"
        transform="translate(9.79905 109.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="64.8212"
        y="35.06487"
        width="10"
        height="9.97152"
        transform="translate(29.77057 109.87184) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="74.84968"
        y="25.06487"
        width="10"
        height="9.97152"
        transform="translate(49.79905 109.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="14.84968"
        y="65.06487"
        width="10"
        height="9.97152"
        transform="translate(-50.20095 89.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="24.84968"
        y="75.06487"
        width="10"
        height="9.97152"
        transform="translate(-50.20095 109.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="34.84968"
        y="85.06487"
        width="10"
        height="9.97152"
        transform="translate(-50.20095 129.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="44.84968"
        y="75.06487"
        width="10"
        height="9.97152"
        transform="translate(-30.20095 129.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="54.84968"
        y="65.06487"
        width="10"
        height="9.97152"
        transform="translate(-10.20095 129.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="64.84968"
        y="55.06487"
        width="10"
        height="9.97152"
        transform="translate(9.79905 129.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="74.84968"
        y="45.06487"
        width="10"
        height="9.97152"
        transform="translate(29.79905 129.90032) rotate(-90)"
        fill="currentColor"
      />
      <rect
        x="84.84968"
        y="35.06487"
        width="10"
        height="9.97152"
        transform="translate(49.79905 129.90032) rotate(-90)"
        fill="currentColor"
      />
    </svg>
  );
}
