import { ReactNode } from "react";
import { Dialog, DialogContent, DialogTrigger } from "./dialog";

export function ModalButton({
  triggerButton,
  disabled,
  children,
  open,
  setOpen,
}: {
  triggerButton: ReactNode;
  disabled?: boolean;
  children: ReactNode;
  open: boolean;
  setOpen: (e: boolean) => void;
}) {
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={disabled}>
        {triggerButton}
      </DialogTrigger>
      <DialogContent className="max-w-[90vw] rounded-md sm:max-w-[425px]">{children}</DialogContent>
    </Dialog>
  );
}
