import { Dispatch, SetStateAction, useState } from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { DeleteButton } from "@/components/ui/delete-button";
import { ModalButton } from "@/components/ui/modal-button";
import { Card } from "@/components/ui/card";
import { DialogClose } from "@/components/ui/dialog";
import { TWebsite } from "src/shared/types/admin";
import Update from "src/shared/image/update";
import { useSearchParams } from "react-router-dom";

export const WebsiteCard = ({
  item,
  selectedWebsite,
  setSelectedWebsite,
  deleteWebsite,
  isDeleted = false,
  isHidden = false,
}: {
  item: TWebsite;
  selectedWebsite: TWebsite | null;
  setSelectedWebsite: Dispatch<SetStateAction<TWebsite | null>>;
  deleteWebsite?: (website_slug: string, isRecovery: boolean) => Promise<void>;
  isDeleted?: boolean;
  isHidden?: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Card
      className={cn(
        `items-center gap-7 py-2`,
        isHidden
          ? "min-w-0 max-w-0 px-0 opacity-0"
          : cn(
              "grid max-w-[320px] min-w-60 pr-2 pl-4 opacity-100",
              (!!deleteWebsite || isDeleted) && "grid-cols-[1fr_40px]"
            ),
        selectedWebsite?.website_id === item.website_id
          ? "bg-foreground text-background"
          : isDeleted
          ? "bg-secondary"
          : ""
      )}>
      <div
        className={cn(
          "flex flex-col",
          !isDeleted && "cursor-pointer durarion-300 hover:opacity-90 hover:scale-[102%]"
        )}
        onClick={() => {
          if (!isDeleted) {
            if (selectedWebsite?.website_id === item.website_id) {
              setSelectedWebsite(null);
              searchParams.delete("website");
            } else {
              setSelectedWebsite(item);
              searchParams.set("website", item.website_slug);
            }
            setSearchParams(searchParams);
          }
        }}>
        <p>{item.website_name}</p>
        <small className="text-neutral-500">{item.website_url}</small>
      </div>
      {!!deleteWebsite && (
        <ModalButton
          open={isModalOpen}
          setOpen={setIsModalOpen}
          triggerButton={
            isDeleted ? (
              <Button
                type="button"
                size="icon"
                variant="outline"
                className="p-2.5 min-w-10 text-emerald-500"
                onClick={() => setIsModalOpen(true)}>
                <Update />
              </Button>
            ) : (
              <DeleteButton />
            )
          }>
          <p>
            Вы уверены, что хотите {isDeleted ? "восстановить" : "удалить"} сайт{" "}
            <b>{item.website_name}</b>?
          </p>
          <DialogClose asChild>
            <Button
              type="button"
              variant={isDeleted ? "default" : "destructive"}
              className="justify-self-end"
              onClick={async () => {
                await deleteWebsite(item.website_slug, isDeleted);
                setIsModalOpen(false);
              }}>
              {isDeleted ? "Восстановить" : "Удалить"}
            </Button>
          </DialogClose>
        </ModalButton>
      )}
    </Card>
  );
};
