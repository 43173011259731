import { Button } from "@/components/ui/button";

export const AdderCard = ({
  label,
  value,
  isSelected,
  selectHandler,
  disabled = false,
}: {
  label: string;
  value: string;
  isSelected: boolean;
  selectHandler: (item: string) => void;
  disabled?: boolean;
}) => {
  return (
    <Button
      type="button"
      disabled={disabled}
      variant={isSelected ? "default" : "outline"}
      className="grid grid-cols-[1fr_60px_12px] gap-2 px-4 py-2"
      onClick={() => selectHandler(value)}>
      <div className="text-left">{label}</div>
      {disabled ? <small>добавлено</small> : <div />}
      <div
        className={`w-3 h-3 rounded-full ${
          isSelected ? "bg-emerald-500" : "bg-neutral-400 opacity-50"
        }`}
      />
    </Button>
  );
};
