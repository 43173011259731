import { Dispatch, SetStateAction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import qs from "query-string";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Card } from "@/components/ui/card";
import { MultiSelect } from "@/components/ui/multi-select";
import { string_schema } from "src/shared/validation/field-schemas";
import { useAdminStore } from "src/shared/store/admin-store";
import { useCommonStore } from "src/shared/store/common-store";
import { useWebsiteStore } from "src/shared/store/website-store";
import { useWebsiteCurrencies } from "src/shared/api/query/use-website-settings/use-currencies";
import { StringBadges } from "src/entities/string-badges";
import { TSelect } from "src/shared/types/common";
import { EBidStatus, TBidFilterFields } from "src/shared/types/bids";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { DatePicker } from "@/components/ui/date-picker";
import { arrayGuard } from "src/shared/utils/common";

const FormSchema = z.object({
  bid_status: z.array(z.custom(() => EBidStatus)).nullable(),

  bid_id: z.array(string_schema).nullable(),
  user_username: z.array(string_schema).nullable(),
  user_email: z.array(string_schema).nullable(),
  currency_from_code: z.array(string_schema).nullable(),
  currency_to_code: z.array(string_schema).nullable(),
  bid_rid: z.array(string_schema).nullable(),
  city_code: z.array(string_schema).nullable(),
  utm_medium: z.array(string_schema).nullable(),
  utm_source: z.array(string_schema).nullable(),
  roistat_visit: z.array(string_schema).nullable(),
  user_telegram: z.array(string_schema).nullable(),

  bid_search_field: string_schema.nullable(),
  bid_created_at_start: string_schema.nullable(),
  bid_created_at_end: string_schema.nullable(),
  address_site: string_schema.nullable(),
  address_user: string_schema.nullable(),
  ip_address: string_schema.nullable(),
});

export const BidFilters = ({
  website_slug,
  setParams,
  fields,
}: {
  website_slug: string;
  setParams: Dispatch<SetStateAction<string>>;
  fields: TBidFilterFields;
}) => {
  const { limit } = useCommonStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const defaultParamsFirstPage = `limit=${limit}&offset=0`;
  const defaultParams = `limit=${limit}&offset=${(+page - 1) * limit}`;

  const defaultValues = {
    bid_search_field: null,
    bid_id: null,
    bid_status: null,
    user_username: null,
    user_email: null,
    currency_from_code: null,
    currency_to_code: null,
    bid_created_at_start: null,
    bid_created_at_end: null,
    bid_rid: null,
    city_code: null,
    utm_medium: null,
    utm_source: null,
    roistat_visit: null,
    user_telegram: null,
    address_site: null,
    address_user: null,
    ip_address: null,
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const isDirty = Object.values(form.watch())?.some((el) => el !== null);

  const cities_codes = arrayGuard(form.watch("city_code"));
  const from = arrayGuard(form.watch("currency_from_code"));
  const to = arrayGuard(form.watch("currency_to_code"));
  const bid_status = arrayGuard(form.watch("bid_status") as EBidStatus[]);

  const lang_attr = "RU";

  const { data: websiteCurrenciesData, isPending: isWebsiteCurrenciesPending } =
    useWebsiteCurrencies(website_slug || "");

  const { cities } = useAdminStore();
  const { websiteCurrencies, setWebsiteCurrencies } = useWebsiteStore();

  const cities_options =
    cities?.map((el) => ({
      label: el.localizations.find((el) => el.locale_code === lang_attr)?.name || "",
      value: el.city_code,
    })) || [];

  const currencies_options =
    websiteCurrencies?.map((el) => ({
      label: el.currency_code,
      value: el.currency_code,
    })) || [];

  const status_options: TSelect[] = Object.values(EBidStatus).map((code) => ({
    label: code as string,
    value: code as string,
  }));

  const handleSelect = (
    attr: "city_code" | "currency_from_code" | "currency_to_code" | "bid_status",
    e: string
  ) => {
    const value = form.watch(attr) || [];
    if (value?.includes(e)) {
      form.setValue(
        attr,
        value?.filter((el) => el !== e)
      );
    } else {
      form.setValue(attr, value.concat([e]));
    }
  };

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    let updParams = defaultParamsFirstPage;
    searchParams.set("page", "1");
    Object.entries(e)?.forEach((el) => {
      if (
        el[1] !== null &&
        el[1] !== undefined &&
        (Array.isArray(el[1]) ? !!el[1]?.length : true)
      ) {
        if (Array.isArray(el[1]) && !!el[1]?.length) {
          searchParams.set(el[0], el[1]?.join());
          el[1]?.forEach((element) => (updParams += `&${el[0]}=${element}`));
        } else {
          searchParams.set(el[0], el[1]?.toString());
          updParams += `&${el[0]}=${el[1]}`;
        }
      } else {
        searchParams.delete(el[0]);
      }
    });
    setParams(updParams);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!isWebsiteCurrenciesPending) {
      if (!!websiteCurrenciesData) {
        setWebsiteCurrencies(websiteCurrenciesData);
      } else {
        setWebsiteCurrencies(null);
      }
    }
  }, [websiteCurrenciesData]);

  useEffect(() => {
    if (!!searchParams) {
      const parsedParams = qs.parse(defaultParams + "&" + searchParams.toString(), {
        arrayFormat: "comma",
      });
      Object.keys(defaultValues).forEach((el) => {
        const value = parsedParams[el];
        if (!!value) {
          form.setValue(el as keyof TBidFilterFields, value as unknown as string[]);
        }
      });
      const updParams = qs.stringify(parsedParams, {
        skipNull: true,
        skipEmptyString: true,
      });
      if (updParams !== defaultParamsFirstPage) {
        setParams(updParams);
      }
    }
  }, [page]);

  return (
    <Card className="p-4 bg-secondary/50">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col gap-2">
          <div className="flex flex-col md:flex-row justify-between gap-1.5">
            <div className="relative w-full">
              <Search className="absolute top-1/2 -translate-y-1/2 left-2 w-5 h-5 opacity-50" />
              <Input
                placeholder="Найти..."
                className="pl-9"
                {...form.register("bid_search_field")}
              />
            </div>
            <div className="rounded-md border bg-background w-fit">
              <DatePicker start_key="bid_created_at_start" end_key="bid_created_at_end" />
            </div>
          </div>
          <div className="flex flex-col xl:grid grid-cols-2 gap-x-4 gap-y-1">
            {!!fields.currency_from_code && (
              <div className="w-full">
                <MultiSelect
                  label="Валюта from"
                  options={currencies_options}
                  placeholder="Клиент отдает..."
                  selected={from?.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  setFn={(e) => handleSelect("currency_from_code", e)}
                  variant="small"
                />
                {!!from && !!from?.length && (
                  <div className="flex gap-1">
                    <StringBadges
                      baseKey="from"
                      items={from}
                      onDelete={(e) => handleSelect("currency_from_code", e)}
                    />
                  </div>
                )}
              </div>
            )}
            {!!fields.currency_to_code && (
              <div className="w-full">
                <MultiSelect
                  label="Валюта to"
                  options={currencies_options}
                  placeholder="Клиент получает..."
                  selected={to?.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  setFn={(e) => handleSelect("currency_to_code", e)}
                  variant="small"
                />
                {!!to && !!to?.length && (
                  <div className="flex gap-1">
                    <StringBadges
                      baseKey="to"
                      items={to}
                      onDelete={(e) => handleSelect("currency_to_code", e)}
                    />
                  </div>
                )}
              </div>
            )}
            {!!fields.city_code && (
              <div className="w-full">
                <MultiSelect
                  label="Город"
                  options={cities_options}
                  placeholder="Город..."
                  selected={cities_codes?.map((code) => ({
                    label: code,
                    value: code,
                  }))}
                  setFn={(e) => handleSelect("city_code", e)}
                  variant="small"
                />
                {!!cities_codes && !!cities_codes?.length && (
                  <div className="flex gap-1">
                    <StringBadges
                      baseKey="city"
                      items={cities_codes?.map((code) => ({
                        label: cities_options?.find((el) => el.value === code)?.label || code,
                        value: code,
                      }))}
                      onDelete={(e) => handleSelect("city_code", e)}
                    />
                  </div>
                )}
              </div>
            )}
            {!!fields.bid_status && (
              <>
                <div className="w-full">
                  <MultiSelect
                    label="Статус"
                    options={status_options}
                    className="w-full"
                    placeholder="Статус..."
                    selected={bid_status?.map((code) => ({
                      label: code,
                      value: code,
                    }))}
                    setFn={(e) => handleSelect("bid_status", e)}
                    variant="small"
                  />
                  {!!bid_status && !!bid_status?.length && (
                    <div className="flex gap-1">
                      <StringBadges
                        baseKey="bid_status"
                        items={bid_status?.map((code) => ({
                          label: code,
                          value: code,
                        }))}
                        onDelete={(e) => handleSelect("bid_status", e)}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="flex gap-2 col-span-2 justify-self-end pt-4 xl:pt-2">
              {isDirty && (
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    form.reset(defaultValues);
                    setParams(defaultParams);
                    Object.keys(defaultValues).forEach((el) => searchParams.delete(el));
                    setSearchParams(searchParams);
                  }}>
                  Сбросить фильтры
                </Button>
              )}
              <Button type="submit" size="sm">
                Получить данные
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </Card>
  );
};
