import { TUpdateWithdrawal, TWithdrawal, TWithdrawalPagination } from "src/shared/types/referral";
import referralService from "../../services/referral-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";

export const useWithdrawals = (website_slug: string, params: string) =>
  useBaseQuery<null, TWithdrawalPagination>(
    ["get-withdrawals", website_slug, params],
    () => referralService.getWithdrawals(website_slug, params),
    !!website_slug?.length
  );

export const useUpdateWithdrawal = (website_slug: string) =>
  useBaseMutation<TUpdateWithdrawal, TWithdrawal>(
    ["update-withdrawal"],
    (payload: TUpdateWithdrawal) => referralService.updateWithdrawal(website_slug, payload),
    {
      403: "Недостаточно прав",
      404: "Заявка не найдена",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );
