import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
// import { Minus, Plus } from "lucide-react";
// import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
// import { Card } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { Loader } from "@/components/ui/loader";
import { PaginationButtons } from "@/components/ui/pagination-buttons";
import { Emptiness } from "@/components/ui/emptiness";
import {
  useDirections,
  useUpdateDirection,
} from "src/shared/api/query/use-website-settings/use-directions";
import { useCommonStore } from "src/shared/store/common-store";
import { useWebsiteStore } from "src/shared/store/website-store";
import { TCreateDirection } from "src/shared/types/websites-settings";
import { string_schema } from "src/shared/validation/field-schemas";
import { fixMinimalExponent } from "src/shared/utils/charfix";
import { DirectionFilters } from "../website-directions/components/direction-filters";
import { DirectionReserveCard } from "./components/direction-reserve-card";

const filterFields = {
  city_code: true,
  currency_from_code: true,
  currency_to_code: true,
  enabled: true,
  bestchange_enabled: true,
  // direction_course_is_manual: true,
};

const FormSchema = z.record(
  z.object({
    city_code: string_schema,
    currency_from_code: string_schema,
    currency_to_code: string_schema,
    // direction_course_is_manual: z.boolean(),
    // direction_manual_course_from: string_schema.nullable(),
    // direction_manual_course_to: string_schema.nullable(),
    min_from: string_schema,
    max_from: string_schema,
    reserve: string_schema,
  })
);

export const WebsiteReserves = () => {
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");
  // const [isEditManualCourse, setEditManualCourse] = useState(false);

  const defaultValues = {
    "1": {
      city_code: "",
      currency_from_code: "",
      currency_to_code: "",
      min_from: "0",
      max_from: "0",
      reserve: "0",
      // direction_course_is_manual: false,
      // direction_manual_course_from: null,
      // direction_manual_course_to: null,
    },
  };

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const { mutateAsync: updateDirection, isPending } = useUpdateDirection(website_slug || "");
  const { directions, setDirections, directionsTotal, setDirectionsTotal } = useWebsiteStore();
  const { limit } = useCommonStore();
  const totalPages = Math.ceil((directionsTotal || 1) / limit);
  const [params, setParams] = useState(`limit=${limit}&offset=0`);

  const { data: directionsData, isPending: isDirectionsPending } = useDirections(
    website_slug || "",
    params
  );

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    const dirtyKeys = Object.keys(form.formState.dirtyFields);
    if (!dirtyKeys?.length) {
      return;
    }
    const payload: TCreateDirection[] = Object.entries(e)
      ?.filter((el) => dirtyKeys.includes(el[0]?.toString()))
      ?.map((el) => {
        // const isCompletedCourse =
        //   Number(el[1].direction_manual_course_from) > 0 &&
        //   Number(el[1].direction_manual_course_to) > 0;
        // if (isEditManualCourse && !isCompletedCourse) {
        //   toast({
        //     variant: "destructive",
        //     title: "Ручные курсы",
        //     description: "Не все ручные курсы применились - для активации необходимо заполить поля from и to",
        //   });
        // }
        return {
          direction_id: el[0],
          ...el[1],
          // direction_course_is_manual: !isCompletedCourse ? false : isEditManualCourse ? true : el[1].direction_course_is_manual,
          // direction_manual_course_from: isCompletedCourse ? Number(el[1].direction_manual_course_from) : null,
          // direction_manual_course_to: isCompletedCourse ? Number(el[1].direction_manual_course_to) : null,
          min_from: +el[1].min_from,
          max_from: +el[1].max_from,
          reserve: +el[1].reserve,
        };
      });
    const data = await updateDirection(payload);
    if (data && !!directions) {
      toast({
        title: "Резервы",
        description: "Данные успешно обновлены",
      });
      const updatedDirections = directions?.map((el) => {
        const currentEl = data?.find((data_el) => data_el.direction_id === el.direction_id);
        if (!!currentEl) {
          return currentEl;
        } else {
          return el;
        }
      });
      setDirections(updatedDirections);
    }
  };

  useEffect(() => {
    if (!!directionsData) {
      setDirectionsTotal(directionsData.total);
      setDirections(directionsData.data);
      const obj = {};
      directionsData.data.forEach((el) => {
        obj[el.direction_id] = {
          city_code: el.city.city_code,
          currency_from_code: el.currency_from.currency_code,
          currency_to_code: el.currency_to.currency_code,
          // direction_course_is_manual: el.direction_course_is_manual,
          // direction_manual_course_from: el.course_from,
          // direction_manual_course_to: el.course_to,
          min_from: fixMinimalExponent(el.min_from),
          max_from: el.max_from.toString(),
          reserve: el.reserve.toString(),
        };
      });
      form.reset(obj);
    } else {
      setDirectionsTotal(null);
      setDirections(null);
      form.reset(defaultValues);
    }
  }, [directionsData]);

  return (
    <div className="flex flex-col gap-3 w-full">
      {website_slug && (
        <div className={`flex flex-col gap-1 w-full`}>
          <div className="flex flex-col gap-2">
            <DirectionFilters
              website_slug={website_slug}
              setParams={setParams}
              fields={filterFields}
            />
            {/* <Card className={cn(
              "flex flex-col gap-2 px-4 py-2",
              isEditManualCourse && "bg-green-400/50"
            )}>
              <div
                onClick={() => {
                  setEditManualCourse(!isEditManualCourse);
                }}
                className="flex gap-3 cursor-pointer"
              >
                {isEditManualCourse
                  ? <Minus className="pb-px w-5 h-5" />
                  : <Plus className="pb-px w-5 h-5" />
                }
                Выставить ручные курсы
              </div>
            </Card> */}
            {isDirectionsPending ? (
              <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
                <Loader />
              </div>
            ) : !!directions?.length ? (
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="w-full flex flex-col space-y-1">
                  {directions?.map((direction) => (
                    <DirectionReserveCard
                      key={direction.direction_id}
                      item={direction}
                      // isEditManualCourse={isEditManualCourse}
                    />
                  ))}
                  <div className="sticky bottom-0 flex flex-col gap-2">
                    <Button disabled={isPending} className="self-end" type="submit">
                      Сохранить
                    </Button>
                    {totalPages > 1 && <PaginationButtons totalPages={totalPages} />}
                  </div>
                </form>
              </Form>
            ) : (
              <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
                <Emptiness message="Направления не найдены" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
