import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { TReferralTaskHistoryItem } from "src/shared/types/referral";
import Chart from "./task-history-chart";
import { TaskHistoryCard } from "./task-history-card";
import { Card } from "@/components/ui/card";

export const TaskHistory = ({ history }: { history: TReferralTaskHistoryItem[] }) => {
  const stock = history
    ?.map((el) => ({
      date: el.task_finished_at,
      close: el.task_amount_day,
    }))
    ?.sort((a, b) => (a?.date < b?.date ? -1 : 1));

  return (
    <div className="w-full h-full min-w-full min-h-64 flex flex-col gap-4 lg:grid grid-cols-2">
      <ParentSize className="max-h-[200px]">
        {({ width, height }) => <Chart width={width} height={height} stock={stock} />}
      </ParentSize>
      <Card className="bg-secondary p-2 space-y-2">
        {history?.map((el) => (
          <TaskHistoryCard key={el.task_history_id} item={el} />
        ))}
      </Card>
    </div>
  );
};
