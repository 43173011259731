export const Pacman = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      x="0px"
      y="0px"
      fill="currentColor">
      <path d="M76,16V12H68V8H36v4H32v4H24v4H20v4H16v4H12v8H8V68h4v8h4v4h4v4h4v4h4v4h4v4H64V92h8V88h4V80H72V76H68V72H64V68H60V64H56V60H52V56H48V52h4V48h4V44h4V40h8V36h4V32h4V28h4V16ZM48,28V20h8v8H48Z" />
    </svg>
  );
};
