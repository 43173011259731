import { AxiosResponse } from "axios";
import { TLanguageResponse } from "src/shared/types/common";
import { BASE_URL } from "../../constants";
import api from "../api-instance";

export default class commonService {
  static async getLanguages(): Promise<AxiosResponse<TLanguageResponse[]>> {
    return api.get(`${BASE_URL}/v1/common/languages`);
  }
}
