import { TCurrency } from "src/shared/types/admin";
import websiteSettingsService from "../../services/website-settings-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";

export const useWebsiteCurrencies = (website_slug: string) =>
  useBaseQuery<null, TCurrency[]>(
    ["get-website-currencies", website_slug],
    () => websiteSettingsService.getWebsiteCurrencies(website_slug),
    !!website_slug?.length
  );

export const useCreateWebsiteCurrency = (website_slug: string) =>
  useBaseMutation<TCurrency[], TCurrency[]>(
    ["create-website-currency"],
    (payload: TCurrency[]) => websiteSettingsService.addWebsiteCurrency(website_slug, payload),
    {
      403: "Недостаточно прав",
      404: "Валюта не найдена",
      409: "Валюта с таким названием или ID уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useUpdateWebsiteCurrency = (website_slug: string) =>
  useBaseMutation<TCurrency[], TCurrency[]>(
    ["update-website-currency"],
    (payload: TCurrency[]) => websiteSettingsService.updateWebsiteCurrency(website_slug, payload),
    {
      403: "Недостаточно прав",
      404: "Валюта не найдена",
      409: "Валюта с таким названием или ID уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );
