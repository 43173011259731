import { Badge } from "@/components/ui/badge";
import { TLocaleName } from "src/shared/types/admin";
import { LocaleCode } from "./locale-code";

export const LocalizationNames = ({
  locales,
  localeHandler,
}: {
  locales: TLocaleName[];
  localeHandler: (locale: TLocaleName) => void;
}) => {
  return (
    <div className="flex flex-wrap gap-1">
      {locales?.map((el) => {
        return (
          <Badge
            key={el.locale_code}
            variant="outline"
            className="flex items-baseline gap-1.5 w-fit p-px pr-2 cursor-pointer"
            onClick={() => localeHandler(el)}>
            <LocaleCode code={el.locale_code} />
            {el.name}
          </Badge>
        );
      })}
    </div>
  );
};
