import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { Emptiness } from "@/components/ui/emptiness";
import { Loader } from "@/components/ui/loader";
import { WebsitesList } from "src/entities/websites-list";
import { TReferralTask } from "src/shared/types/referral";
import { TaskCard } from "./components/task-card";
import { TaskHistory } from "./components/task-history";
import {
  useReferralTasks,
  useReferralTasksHistory,
} from "src/shared/api/query/use-referral/use-task-manager";
import { checkStatusCode } from "src/shared/utils/common";
import { useReferralStore } from "src/shared/store/referral-store";
import { TaskForm } from "./components/task-form";

export const TaskManager = () => {
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const website_slug = searchParams.get("website");
  const [selectedTask, setSelectedTask] = useState<TReferralTask | null>(null);

  const {
    data: tasksData,
    isPending: isTasksPending,
    error: tasksError,
  } = useReferralTasks(website_slug || "");
  const {
    data: historyData,
    isPending: isHistoryPending,
    error: historyError,
  } = useReferralTasksHistory();

  const { tasks, setTasks, history, setHistory } = useReferralStore();

  useEffect(() => {
    if (!!tasksData) {
      setTasks(tasksData);
    } else {
      setTasks(null);
    }
  }, [tasksData]);

  useEffect(() => {
    if (!!historyData) {
      setHistory(historyData);
    } else {
      setHistory(null);
    }
  }, [historyData]);

  useEffect(() => {
    if (!!tasksError || !!historyError) {
      const baseToast = {
        variant: "destructive" as const,
        title: "TASK менеджер",
      };
      if (checkStatusCode([tasksError, historyError], 403)) {
        toast({ ...baseToast, description: "Недостаточно прав" });
      } else if (checkStatusCode([tasksError, historyError], 500)) {
        toast({ ...baseToast, description: "Не удалось совершить действие" });
      } else {
        toast({ ...baseToast, description: "Неизвестная ошибка" });
      }
    }
  }, [tasksError, historyError]);

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex flex-col gap-4 md:flex-row">
        <WebsitesList />
        <TaskForm
          website_slug={website_slug}
          selectedTask={selectedTask}
          setSelectedTask={setSelectedTask}
        />
        <div className="w-full min-h-72 h-fit bg-secondary rounded-md p-2 space-y-2">
          {!website_slug ? (
            <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
              Выберите сайт
            </div>
          ) : isTasksPending ? (
            <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
              <Loader />
            </div>
          ) : !!tasks?.length ? (
            <>
              {tasks?.map((el) => (
                <TaskCard
                  key={el?.task_id}
                  item={el}
                  selectedTask={selectedTask}
                  setSelectedTask={setSelectedTask}
                />
              ))}
            </>
          ) : (
            <Emptiness message="У сайта нет добавленных задач" />
          )}
        </div>
      </div>
      <div className="w-full h-full min-h-64 min-w-64">
        {isHistoryPending ? (
          <div className="w-full h-full min-h-64 flex items-center justify-center text-neutral-500">
            <Loader />
          </div>
        ) : !!history && !historyError ? (
          <TaskHistory history={history} />
        ) : (
          <Emptiness message="Нет истории исполненных задач" />
        )}
      </div>
    </div>
  );
};
