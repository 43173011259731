import { create } from "zustand";
import { persist } from "zustand/middleware";
import { TReferralTask, TReferralTaskHistoryItem, TWithdrawal } from "../types/referral";

interface ReferralStoreState {
  tasks: TReferralTask[] | null;
  setTasks: (tasks: TReferralTask[] | null) => void;
  history: TReferralTaskHistoryItem[] | null;
  setHistory: (history: TReferralTaskHistoryItem[] | null) => void;
  uncheckedCounts: null;
  withdrawals: TWithdrawal[] | null;
  setWithdrawals: (withdrawals: TWithdrawal[] | null) => void;
  withdrawalsTotal: number | null;
  setWithdrawalsTotal: (withdrawalsTotal: number | null) => void;
}

const initialState = {
  tasks: null,
  history: null,
  uncheckedCounts: null,
  withdrawals: null,
  withdrawalsTotal: null,
};

export const useReferralStore = create<ReferralStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setTasks: (tasks) => set({ tasks }),
      setHistory: (history) => set({ history }),
      setWithdrawals: (withdrawals) => set({ withdrawals }),
      setWithdrawalsTotal: (withdrawalsTotal) => set({ withdrawalsTotal }),
    }),
    {
      name: "referralStore",
    }
  )
);
