import Dino from "../../../src/shared/image/dino";

export const Emptiness = ({ message }) => {
  return (
    <div className="w-full h-full flex flex-col gap-[20px] items-center justify-center my-3 text-sm text-foreground text-center whitespace-pre">
      <div className="w-full max-w-56 opacity-10 px-5">
        <Dino />
      </div>
      <div className="opacity-30">{message}</div>
    </div>
  );
};
