import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { useBaseMutationWithoutPayload } from "../base-mutation-without-payload";
import referralService from "../../services/referral-service";
import {
  TCreateReferralTask,
  TReferralTask,
  TReferralTaskHistoryItem,
  TUpdateReferralTask,
} from "src/shared/types/referral";

export const useReferralTasks = (website_slug: string) =>
  useBaseQuery<null, TReferralTask[]>(["get-referral-task", website_slug], () =>
    referralService.getReferralTasks(website_slug)
  );

export const useCreateReferralTask = () =>
  useBaseMutation<TCreateReferralTask, TReferralTask>(
    ["create-referral-task"],
    (payload: TCreateReferralTask) => referralService.addReferralTask(payload),
    {
      403: "Недостаточно прав",
      409: "Задача с такими уникальными полями уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useUpdateReferralTask = (task_id: string) =>
  useBaseMutation<TUpdateReferralTask, TReferralTask>(
    ["update-referral-task", task_id],
    (payload: TUpdateReferralTask) => referralService.updateReferralTask(task_id, payload),
    {
      403: "Недостаточно прав",
      404: "Задача не найдена",
      409: "Задача с такими уникальными полями уже существует",
      422: "Ошибка валидации - проверьте правильность заполнения полей",
      500: "Не удалось совершить действие",
    }
  );

export const useDeleteReferralTask = (task_id: string) =>
  useBaseMutationWithoutPayload<TReferralTask>(["delete-referral-task"], () =>
    referralService.deleteReferralTask(task_id)
  );

export const useReferralTasksHistory = () =>
  useBaseQuery<null, TReferralTaskHistoryItem[]>(["get-referral-task-history"], () =>
    referralService.getReferralTasksHistory()
  );
