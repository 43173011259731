export const LocaleCode = ({ code }: { code: string }) => {
  return (
    <div
      className={`text-[10px] text-neutral-500 rounded-full p-1 bg-secondary h-6 flex whitespace-pre items-center justify-center ${
        code?.length > 2 ? "w-fit" : "w-6"
      }`}>
      {code?.toUpperCase()}
    </div>
  );
};
