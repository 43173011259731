import { Dispatch, SetStateAction, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import qs from "query-string";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Card } from "@/components/ui/card";
import { MultiSelect } from "@/components/ui/multi-select";
import { TDirectionFilterFields, XML_options } from "src/shared/types/websites-settings";
import { string_schema } from "src/shared/validation/field-schemas";
import { BooleanNullToggleGroup } from "src/entities/boolean-null-toggle-group";
import { useAdminStore } from "src/shared/store/admin-store";
import { useCommonStore } from "src/shared/store/common-store";
import { useWebsiteStore } from "src/shared/store/website-store";
import { useWebsiteCurrencies } from "src/shared/api/query/use-website-settings/use-currencies";
import { StringBadges } from "src/entities/string-badges";
import { XMLModal } from "src/entities/xml-modal";
import { arrayGuard } from "src/shared/utils/common";

const FormSchema = z.object({
  city_code: z.array(string_schema).nullable(),
  currency_from_code: z.array(string_schema).nullable(),
  currency_to_code: z.array(string_schema).nullable(),
  xml_value: z.array(string_schema).nullable(),
  enabled: z.boolean().nullable(),
  bestchange_enabled: z.boolean().nullable(),
  // direction_course_is_manual: z.boolean().nullable(),
});

export const DirectionFilters = ({
  website_slug,
  setParams,
  fields,
}: {
  website_slug: string;
  setParams: Dispatch<SetStateAction<string>>;
  fields: TDirectionFilterFields;
}) => {
  const { limit } = useCommonStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const defaultParamsFirstPage = `limit=${limit}&offset=0`;
  const defaultParams = `limit=${limit}&offset=${(+page - 1) * limit}`;

  const defaultValues = {
    city_code: null,
    currency_from_code: null,
    currency_to_code: null,
    xml_value: null,
    enabled: null,
    bestchange_enabled: null,
    // direction_course_is_manual: null,
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const isDirty = Object.values(form.watch())?.some((el) => el !== null);

  const cities_codes = arrayGuard(form.watch("city_code"));
  const from = arrayGuard(form.watch("currency_from_code"));
  const to = arrayGuard(form.watch("currency_to_code"));
  const xml_value = arrayGuard(form.watch("xml_value"));
  const enabled = form.watch("enabled");
  const bestchange_enabled = form.watch("bestchange_enabled");
  // const direction_course_is_manual = form.watch("direction_course_is_manual");
  const lang_attr = "RU";

  const { data: websiteCurrenciesData, isPending: isWebsiteCurrenciesPending } =
    useWebsiteCurrencies(website_slug || "");

  const { cities } = useAdminStore();
  const { websiteCurrencies, setWebsiteCurrencies } = useWebsiteStore();

  const cities_options =
    cities?.map((el) => ({
      label: el.localizations.find((el) => el.locale_code === lang_attr)?.name || "",
      value: el.city_code,
    })) || [];

  const currencies_options =
    websiteCurrencies?.map((el) => ({
      label: el.currency_code,
      value: el.currency_code,
    })) || [];

  const handleSelect = (
    attr: "city_code" | "currency_from_code" | "currency_to_code" | "xml_value",
    e: string
  ) => {
    const value = form.watch(attr) || [];
    if (value?.includes(e)) {
      form.setValue(
        attr,
        value?.filter((el) => el !== e)
      );
    } else {
      form.setValue(attr, value.concat([e]));
    }
  };

  const onSubmit = async (e: z.infer<typeof FormSchema>) => {
    let updParams = defaultParamsFirstPage;
    searchParams.set("page", "1");
    Object.entries(e)?.forEach((el) => {
      if (
        el[1] !== null &&
        el[1] !== undefined &&
        (Array.isArray(el[1]) ? !!el[1]?.length : true)
      ) {
        if (Array.isArray(el[1]) && !!el[1]?.length) {
          searchParams.set(el[0], el[1]?.join());
          el[1]?.forEach((element) => (updParams += `&${el[0]}=${element}`));
        } else {
          searchParams.set(el[0], el[1]?.toString());
          updParams += `&${el[0]}=${el[1]}`;
        }
      } else {
        searchParams.delete(el[0]);
      }
    });
    setParams(updParams);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (!isWebsiteCurrenciesPending) {
      if (!!websiteCurrenciesData) {
        setWebsiteCurrencies(websiteCurrenciesData);
      } else {
        setWebsiteCurrencies(null);
      }
    }
  }, [websiteCurrenciesData]);

  useEffect(() => {
    if (!!searchParams) {
      const parsedParams = qs.parse(defaultParams + "&" + searchParams.toString(), {
        arrayFormat: "comma",
      });
      Object.keys(defaultValues).forEach((el) => {
        const value = parsedParams[el];
        if (!!value) {
          if (
            el.includes("enabled")
            //|| el === "direction_course_is_manual"
          ) {
            form.setValue(el as keyof TDirectionFilterFields, value === "true");
          } else {
            form.setValue(el as keyof TDirectionFilterFields, value as unknown as string[]);
          }
        }
      });
      const updParams = qs.stringify(parsedParams, {
        skipNull: true,
        skipEmptyString: true,
      });
      if (updParams !== defaultParamsFirstPage) {
        setParams(updParams);
      }
    }
  }, [page]);

  return (
    <Card className="p-4 bg-secondary/50">
      <Form {...form}>
        <p className="text-lg font-semibold">Фильтры</p>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col gap-2">
          <div className="flex flex-col xl:grid grid-cols-3 gap-1 -ml-1">
            {!!fields.enabled && (
              <BooleanNullToggleGroup
                label="Активен на сайте"
                value={enabled}
                setValue={(value) => form.setValue("enabled", value)}
                nullTooltip="Не применять фильтр"
                trueTooltip="Показать только активные"
                falseTooltip="Показать только неактивные"
              />
            )}
            {!!fields.bestchange_enabled && (
              <BooleanNullToggleGroup
                label="Активен для мониторингов"
                value={bestchange_enabled}
                setValue={(value) => form.setValue("bestchange_enabled", value)}
                nullTooltip="Не применять фильтр"
                trueTooltip="Показать только активные"
                falseTooltip="Показать только неактивные"
              />
            )}
            {/* {!!fields.direction_course_is_manual && (
              <BooleanNullToggleGroup
                label="Ручной курс"
                value={direction_course_is_manual}
                setValue={(value) => form.setValue("direction_course_is_manual", value)}
                nullTooltip="Не применять фильтр"
                trueTooltip="Показать только активные"
                falseTooltip="Показать только неактивные"
              />
            )} */}
          </div>
          <div className="flex flex-col xl:grid grid-cols-2 gap-x-4 gap-y-1">
            {!!fields.currency_from_code && (
              <div className="w-full">
                <MultiSelect
                  label="Валюта from"
                  options={currencies_options}
                  placeholder="Клиент отдает..."
                  selected={from?.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  setFn={(e) => handleSelect("currency_from_code", e)}
                  variant="small"
                />
                {!!from && !!from?.length && (
                  <div className="flex gap-1">
                    <StringBadges
                      baseKey="from"
                      items={from}
                      onDelete={(e) => handleSelect("currency_from_code", e)}
                    />
                  </div>
                )}
              </div>
            )}
            {!!fields.currency_to_code && (
              <div className="w-full">
                <MultiSelect
                  label="Валюта to"
                  options={currencies_options}
                  placeholder="Клиент получает..."
                  selected={to?.map((el) => ({
                    label: el,
                    value: el,
                  }))}
                  setFn={(e) => handleSelect("currency_to_code", e)}
                  variant="small"
                />
                {!!to && !!to?.length && (
                  <div className="flex gap-1">
                    <StringBadges
                      baseKey="to"
                      items={to}
                      onDelete={(e) => handleSelect("currency_to_code", e)}
                    />
                  </div>
                )}
              </div>
            )}
            {!!fields.city_code && (
              <div className="w-full">
                <MultiSelect
                  label="Город"
                  options={cities_options}
                  placeholder="Город..."
                  selected={cities_codes?.map((code) => ({
                    label: code,
                    value: code,
                  }))}
                  setFn={(e) => handleSelect("city_code", e)}
                  variant="small"
                />
                {!!cities_codes && !!cities_codes?.length && (
                  <div className="flex gap-1">
                    <StringBadges
                      baseKey="city"
                      items={cities_codes?.map((code) => ({
                        label: cities_options?.find((el) => el.value === code)?.label || code,
                        value: code,
                      }))}
                      onDelete={(e) => handleSelect("city_code", e)}
                    />
                  </div>
                )}
              </div>
            )}
            {!!fields.xml_value && (
              <>
                <div className="w-full">
                  <div className="w-full flex gap-1.5 items-end">
                    <MultiSelect
                      label="Значения XML"
                      options={XML_options}
                      className="w-full"
                      placeholder="Специальные условия..."
                      selected={xml_value?.map((code) => ({
                        label: code,
                        value: code,
                      }))}
                      setFn={(e) => handleSelect("xml_value", e)}
                      variant="small"
                    />
                    <XMLModal variant="small" />
                  </div>
                  {!!xml_value && !!xml_value?.length && (
                    <div className="flex gap-1">
                      <StringBadges
                        baseKey="xml_value"
                        items={xml_value?.map((code) => ({
                          label: XML_options?.find((el) => el.value === code)?.label || code,
                          value: code,
                        }))}
                        onDelete={(e) => handleSelect("xml_value", e)}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="flex gap-2 col-span-2 justify-self-end pt-4 xl:pt-2">
              {isDirty && (
                <Button
                  type="button"
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    form.reset(defaultValues);
                    setParams(defaultParams);
                    Object.keys(defaultValues).forEach((el) => searchParams.delete(el));
                    setSearchParams(searchParams);
                  }}>
                  Сбросить фильтры
                </Button>
              )}
              <Button type="submit" size="sm">
                Получить данные
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </Card>
  );
};
