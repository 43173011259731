import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { cn } from "@/lib/utils";
import { useCommonStore } from "src/shared/store/common-store";
import { LS } from "src/shared/constants";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "./pagination";
import { Input } from "./input";

export const PaginationButtons = ({ totalPages }: { totalPages: number }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchPage = searchParams?.get("page");
  const currentPage = !!searchPage ? +searchPage : 1;

  const { limit, setLimit } = useCommonStore();
  const [_limit, _setLimit] = useState(limit.toString());

  const minCount = 1;
  const pagesArr = Array.from(Array(totalPages).keys());
  const start = currentPage > minCount ? currentPage - minCount : 0;
  const end = currentPage < minCount ? minCount + minCount : +currentPage + minCount + 1;

  const setPage = (page: number) => {
    searchParams.set("page", page?.toString());
    setSearchParams(searchParams);
  };

  useLayoutEffect(() => {
    const savedLimit = localStorage.getItem(LS.LIMIT);
    if (!!savedLimit && !Number.isNaN(+savedLimit)) {
      setLimit(+savedLimit);
    }
  }, []);

  useEffect(() => {
    _setLimit(limit.toString());
  }, [limit]);

  return (
    <div className="-mx-4 px-4 flex flex-col flex-wrap md:flex-row md:justify-between gap-2 bg-background/30 backdrop-blur pb-4 pt-1 text-foreground">
      <div className="flex items-baseline gap-3 whitespace-pre">
        <Input
          type="number"
          className={cn("w-14 text-center", +_limit !== limit && "bg-green-400/50")}
          value={_limit}
          onChange={(e) => _setLimit(e.target.value)}
          onKeyDown={async (e) => {
            if (e.keyCode === 13 && Number(e.currentTarget?.value) > 0) {
              e.preventDefault();
              setLimit(+e.currentTarget.value);
              searchParams.set("page", "1");
              setSearchParams(searchParams);
            }
          }}
        />
        на странице
      </div>
      <Pagination className="md:w-fit md:mx-0">
        <PaginationContent>
          {currentPage > 1 && (
            <PaginationItem className="hidden sm:flex">
              <PaginationPrevious size="sm" onClick={() => setPage(currentPage - 1)} />
            </PaginationItem>
          )}
          {start > 1 && (
            <>
              <PaginationItem>
                <PaginationLink size="icon" onClick={() => setPage(1)}>
                  1
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
            </>
          )}
          {pagesArr.slice(start, end).map((item) => {
            return (
              <PaginationItem key={`${item}_page`}>
                <PaginationLink
                  size="icon"
                  isActive={currentPage === item + 1}
                  onClick={() => setPage(item + 1)}>
                  {item + 1}
                </PaginationLink>
              </PaginationItem>
            );
          })}
          {totalPages > end && (
            <>
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
              <PaginationItem>
                <PaginationLink size="icon" onClick={() => setPage(totalPages)}>
                  {totalPages}
                </PaginationLink>
              </PaginationItem>
            </>
          )}
          {currentPage < totalPages && (
            <PaginationItem className="hidden sm:flex">
              <PaginationNext size="sm" onClick={() => setPage(currentPage + 1)} />
            </PaginationItem>
          )}
        </PaginationContent>
      </Pagination>
    </div>
  );
};
