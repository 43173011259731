import { Dispatch, SetStateAction, useEffect, useState } from "react";
import dayjs from "dayjs";
import { cn } from "@/lib/utils";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { DeleteButton } from "@/components/ui/delete-button";
import { ModalButton } from "@/components/ui/modal-button";
import { Card } from "@/components/ui/card";
import { DialogClose } from "@/components/ui/dialog";
import { useDeleteReferralTask } from "src/shared/api/query/use-referral/use-task-manager";
import { useReferralStore } from "src/shared/store/referral-store";
import { checkStatusCode } from "src/shared/utils/common";
import { TReferralTask } from "src/shared/types/referral";
import Close from "src/shared/image/close";
import Tick from "src/shared/image/tick";

export const TaskCard = ({
  item,
  selectedTask,
  setSelectedTask,
}: {
  item: TReferralTask;
  selectedTask: TReferralTask | null;
  setSelectedTask: Dispatch<SetStateAction<TReferralTask | null>>;
}) => {
  const { toast } = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { tasks, setTasks } = useReferralStore();

  const { mutateAsync: deleteTask, isPending, error } = useDeleteReferralTask(item?.task_id);

  const onDeleteTask = async () => {
    const data = await deleteTask();
    if (data && !!tasks) {
      toast({
        title: "TASK менеджер: удаление задачи",
        description: "Задача успешно удалена",
      });
      setTasks(tasks?.filter((el) => el.task_id !== item.task_id));
    }
  };

  useEffect(() => {
    if (!!error) {
      const baseToast = {
        variant: "destructive" as const,
        title: "TASK менеджер: удаление задачи",
      };
      if (checkStatusCode([error], 403)) {
        toast({ ...baseToast, description: "Недостаточно прав" });
      } else if (checkStatusCode([error], 500)) {
        toast({ ...baseToast, description: "Не удалось удалить задачу" });
      } else {
        toast({ ...baseToast, description: "Неизвестная ошибка" });
      }
    }
  }, [error]);

  return (
    <Card
      className={cn(
        "flex flex-col gap-3 py-2 pr-3 pl-4 text-sm",
        selectedTask?.task_id === item.task_id
          ? "bg-foreground text-background"
          : !item.task_is_enabled
          ? "text-neutral-500"
          : ""
      )}>
      <div className="grid grid-cols-[1fr_40px] gap-2 items-start xl:items-center">
        <div
          className="cursor-pointer durarion-300 hover:opacity-90 hover:scale-[102%] xl:grid grid-cols-[2fr_3fr_40px]"
          onClick={() => {
            if (selectedTask?.task_id === item.task_id) {
              setSelectedTask(null);
            } else {
              setSelectedTask(item);
            }
          }}>
          <div className="flex gap-2 items-center py-2">
            <div
              className={`h-5 w-5 rounded-md p-1 ${
                item.task_is_enabled ? "text-white bg-purple-400" : "bg-secondary"
              }`}>
              {item.task_is_enabled ? <Tick /> : <Close />}
            </div>
            <p className="flex items-baseline gap-2">
              <span className="pixel">REF ID:</span>
              <i className="text-lg">{item.task_ref_id}</i>
            </p>
          </div>
          <div
            className={`w-full md:w-fit flex divide-x whitespace-pre order-last col-span-2 xl:order-none xl:col-span-1 ${
              item.task_is_enabled ? "text-purple-500" : ""
            }`}>
            <div className="pr-2">
              <div className="flex gap-1 items-baseline text-lg font-semibold">
                <span
                  className={`font-bold drop-shadow-[1px_3px_3px_rgba(0,0,0,0.2)] ${
                    selectedTask?.task_id === item.task_id ? "text-background" : "text-foreground"
                  }`}>
                  {item.task_min_amount_day}
                </span>
                <i className="drop-shadow-[1px_3px_3px_rgba(0,0,0,0.2)]">
                  {" "}
                  ~ {item.task_max_amount_day}
                </i>
              </div>
              <small className="leading-3 text-neutral-500">Сумма накрутки</small>
            </div>
            <div className="pl-2">
              <div className="flex gap-1 items-baseline text-lg font-semibold">
                <span
                  className={`font-bold drop-shadow-[1px_3px_3px_rgba(0,0,0,0.2)] ${
                    selectedTask?.task_id === item.task_id ? "text-background" : "text-foreground"
                  }`}>
                  {item.task_min_bid_amount}
                </span>
                <i className="drop-shadow-[1px_3px_3px_rgba(0,0,0,0.2)]">
                  {" "}
                  ~ {item.task_max_bid_amount}
                </i>
              </div>
              <small className="leading-3 text-neutral-500">Сумма 1 заявки</small>
            </div>
          </div>
        </div>
        <ModalButton open={isModalOpen} setOpen={setIsModalOpen} triggerButton={<DeleteButton />}>
          <p>
            Вы уверены, что хотите удалить задачу для <b> ref_id: {item.task_ref_id}</b>?
          </p>
          <DialogClose asChild>
            <Button
              type="button"
              variant="destructive"
              className="justify-self-end"
              disabled={isPending}
              onClick={onDeleteTask}>
              Удалить
            </Button>
          </DialogClose>
        </ModalButton>
      </div>
      <p>{item.task_comment}</p>
      <div className="flex gap-2 text-xs text-neutral-500 items-end justify-end opacity-70">
        <p>Последнее выполнение:</p>
        <p>{dayjs(item?.task_last_executed_at)?.format("DD.MM.YYYY HH:mm")}</p>
      </div>
    </Card>
  );
};
