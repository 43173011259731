import { useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { toast } from "@/components/ui/use-toast";
import { NestedObjects } from "src/shared/types/common";

export function useBaseMutation<P, R>(
  mutationKeys: string[],
  request: (payload: P) => Promise<AxiosResponse<R, string>>,
  errors?: NestedObjects<string>
) {
  const { mutateAsync, isPending, error } = useMutation({
    mutationKey: mutationKeys,
    mutationFn: async (payload: P) => {
      try {
        if (mutationKeys?.some((el) => el === "")) {
          return null;
        }
        const data = await request(payload);
        if (!data) {
          return null;
        }
        if (data.status < 200 || data.status >= 300) {
          if (errors) {
            const baseToast = {
              variant: "destructive" as const,
            };
            Object.entries(errors)?.forEach((error_el) => {
              if (data.status === +error_el[0]) {
                toast({ ...baseToast, description: error_el[1] as string });
                throw new Error(data.status?.toString());
              }
            });
            toast({ ...baseToast, description: "Неизвестная ошибка" });
          }
          throw new Error(data.status?.toString());
        }
        return data.data;
      } catch (e) {
        throw new Error(e as string);
      }
    },
  });

  return {
    mutateAsync,
    isPending,
    error,
  };
}
