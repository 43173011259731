import { Dispatch, SetStateAction, useState } from "react";
import { Emptiness } from "@/components/ui/emptiness";
import { Loader } from "@/components/ui/loader";
import { ECurrencyType, TCurrency } from "src/shared/types/admin";
import { CurrencyCard } from "./currency-card";
import { CurrencyTypesFilter } from "./adder/components/currency-types-filter";

export const CurrencyList = ({
  currencies,
  selectedCurrency,
  setSelectedCurrency,
  isPending,
}: {
  currencies: TCurrency[] | null;
  selectedCurrency: TCurrency | null;
  setSelectedCurrency: Dispatch<SetStateAction<TCurrency | null>>;
  isPending: boolean;
}) => {
  const [currencyFilters, setCurrencyFilters] = useState<ECurrencyType[]>([]);

  const onCurrencyFilterClick = (type: ECurrencyType) => {
    setCurrencyFilters((prev) =>
      prev.includes(type) ? prev.filter((item) => item !== type) : [...prev, type]
    );
  };

  return (
    <div className={`flex flex-col gap-1 min-w-[280px] md:max-w-[340px]`}>
      {isPending ? (
        <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
          <Loader />
        </div>
      ) : !!currencies?.length ? (
        <>
          <div className="flex gap-1">
            <CurrencyTypesFilter
              currencyFilters={currencyFilters}
              onSelect={onCurrencyFilterClick}
            />
          </div>
          {currencies
            ?.filter((item) => {
              if (currencyFilters.length === 0) {
                return true;
              }

              return currencyFilters.includes(item.currency_type);
            })
            ?.map((currency) => (
              <CurrencyCard
                key={currency.currency_code}
                item={currency}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
              />
            ))}
        </>
      ) : (
        <div className="w-full h-full min-h-64 my-2 flex items-center justify-center text-neutral-500">
          <Emptiness message="Валюты не найдены" />
        </div>
      )}
    </div>
  );
};
